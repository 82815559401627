import { Icon } from '@audi/audi-ui-react';
import React, { useContext, type FC } from 'react';
import styled from 'styled-components';
import { UniversalStateContext } from '../context/UniversalStateContext';

type Direction = 'back' | 'forward';

const StyledNavigationButton = styled.button<{ visible: boolean; direction: Direction }>`
  appearance: none;
  background-color: var(
    ${({ theme, visible }) => (visible ? theme.colors.base.brand.white : theme.colors.base.grey[5])}
  );

  border: none;
  padding: 8px;
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 16px;
  }

  transform: ${({ direction }) => direction === 'back' && 'rotate(-180deg)'};
  pointer-events: auto;

  &:not(:disabled):hover {
    cursor: pointer;
  }

  & > svg {
    color: var(
      ${({ theme, disabled }) =>
        disabled ? theme.colors.interaction.secondary.disabled : theme.colors.base.brand.black}
    );
    display: block;
  }
`;

export interface NavigationButtonProps {
  readonly onClick: () => void;
  readonly direction: Direction;
  readonly disabled?: boolean;
}

export const NavigationButton: FC<NavigationButtonProps> = ({
  onClick,
  direction,
  disabled = false,
}) => {
  const { i18nTexts } = useContext(UniversalStateContext)!;

  return (
    <StyledNavigationButton
      onClick={onClick}
      visible={!disabled}
      disabled={disabled}
      direction={direction}
      aria-label={direction === 'back' ? i18nTexts.previousButtonText : i18nTexts.nextButtonText}
    >
      <Icon name="forward" size="xs" variant="secondary" />
    </StyledNavigationButton>
  );
};
