import { Icon } from '@audi/audi-ui-react';
import React, { useContext, type FC } from 'react';
import styled from 'styled-components';
import { UniversalStateContext } from '../../context/UniversalStateContext';

type Direction = 'back' | 'forward';

const StyledArrowButton = styled.button<{ direction: Direction }>`
  position: absolute;
  top: -2px;
  background: ${(props) => props.theme.colors.base.grey[5]};
  backdrop-filter: blur(5px);
  border: none;
  border-radius: 50%;
  padding: 10px;
  margin: 8px 8px;
  transform: ${({ direction }) => (direction === 'back' ? 'rotate(90deg)' : 'rotate(-90deg)')};
  pointer-events: auto;
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: block;
  }
  &:not(:disabled):hover {
    cursor: pointer;
  }

  & > svg {
    color: var(
      ${({ theme, disabled }) =>
        disabled ? theme.colors.interaction.tertiary.disabled : theme.colors.base.brand.black}
    );
    display: block;
  }
`;

export interface ArrowButtonProps {
  readonly onClick: () => void;
  readonly direction: Direction;
  readonly disabled?: boolean;
}

export const ArrowButton: FC<ArrowButtonProps> = ({ onClick, direction, disabled = false }) => {
  const { i18nTexts } = useContext(UniversalStateContext)!;

  return (
    <StyledArrowButton
      onClick={onClick}
      disabled={disabled}
      direction={direction}
      aria-label={direction === 'back' ? i18nTexts.previousButtonText : i18nTexts.nextButtonText}
    >
      <Icon name="arrow-down" size="xs" variant="secondary" />
    </StyledArrowButton>
  );
};
