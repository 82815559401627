import { Text } from '@audi/audi-ui-react';
import { FootnoteReferenceV2 } from '@oneaudi/feature-app-utils';
import type { FC } from 'react';
import React, { useContext } from 'react';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import { UniversalStateContext } from '../context/UniversalStateContext';
import { ContentContext } from '../context/ContentContext';
import { FAStateContext } from '../context/FAStateContext';

export const PriceText: FC = () => {
  const numberFormatterService =
    useFeatureAppEnv().featureServices['audi-number-formatter-service'];
  const content = useContext(ContentContext);
  const {
    priceDisplayOption: { showBasePrice },
  } = content;
  const { i18nTexts } = useContext(UniversalStateContext)!;
  const {
    carline: { price },
  } = useContext(FAStateContext);
  if (price === undefined || price === null || !showBasePrice) {
    return null;
  }

  return (
    <Text spaceStackEnd="m" tint="secondary">
      {i18nTexts.minPriceText.replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${value}',
        numberFormatterService.formatCurrency(price),
      )}
      {content.priceFootnotes?.map((footnote: string, i: number) => (
        <React.Fragment key={footnote}>
          {i > 0 && <sup>{', '}</sup>}
          <FootnoteReferenceV2 footnoteId={footnote} data-testid={`price-footnote_${i}`} />
        </React.Fragment>
      ))}{' '}
      <Text variant="copy3" as="span" tint="secondary">
        {i18nTexts.vat}
      </Text>
    </Text>
  );
};
