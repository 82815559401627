import { concatPrString } from './concatPrString';
import type { MappedCarIdentifier } from './mapCarline';

export interface CacheIdentifier {
  readonly color: string;
  readonly rim: string;
}

export interface CacheItem {
  readonly images: string[];
  readonly mappedCarIdentifier: MappedCarIdentifier;
  readonly idString: string;
}

export const createImageCacheHelper = ({ model, interiorColor }: MappedCarIdentifier) => {
  const getFullCacheId = (cacheId: CacheIdentifier) =>
    concatPrString({ model, interiorColor, ...cacheId });

  return {
    getCacheItem: (cacheId: CacheIdentifier) => {
      const cacheHit = sessionStorage.getItem(getFullCacheId(cacheId));
      if (cacheHit === null) {
        return null;
      }
      return JSON.parse(cacheHit) as CacheItem;
    },
    storeCacheItem: (cacheId: CacheIdentifier, item: CacheItem) => {
      sessionStorage.setItem(getFullCacheId(cacheId), JSON.stringify(item));
    },
  };
};

export const preloadImage = (url: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve();
    img.onerror = () => reject();
  });

export const preloadRemainingImages = (imageUrls: string[], currentIndex: number): void => {
  imageUrls.forEach((url, index) => {
    if (index !== currentIndex) {
      preloadImage(url).catch((err) => console.error(`Failed to preload image: ${url}`, err));
    }
  });
};
