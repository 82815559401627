import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import type { ContentServiceV1 } from '@oneaudi/content-service';

interface PriceDisplayOption {
  showBasePrice: boolean;
}

interface CaeDisplayOption {
  showCae: boolean;
}
export interface Content {
  readonly carlineId: string;
  readonly priceFootnotes: string[];
  readonly caeDisplayOption: CaeDisplayOption;
  readonly priceDisplayOption: PriceDisplayOption;
}

interface FalconContent {
  readonly __type: 'aem-headless';
  readonly fields: {
    readonly carlineId: string;
    readonly priceDisplayOption_showBasePrice: boolean;
    readonly caeDisplayOption_showCae: boolean;
    readonly priceFootnotes: string[];
  };
}

export const ContentContext = createContext<Content>(null as unknown as Content);

export interface ContentContextProviderProps {
  readonly contentService: ContentServiceV1;
  readonly children?: ReactNode;
}

const normalizeContent = (contentService: ContentServiceV1): Content | undefined => {
  const cmsContent = contentService.getContent() as Content | FalconContent | undefined;
  if (cmsContent === undefined) {
    return cmsContent;
  }

  if ('__type' in cmsContent && cmsContent.__type === 'aem-headless') {
    const normalizedContent: Content = {
      carlineId: cmsContent.fields.carlineId,
      priceFootnotes: cmsContent.fields.priceFootnotes,
      priceDisplayOption: {
        showBasePrice: cmsContent.fields.priceDisplayOption_showBasePrice,
      },
      caeDisplayOption: {
        showCae: cmsContent.fields.caeDisplayOption_showCae,
      },
    };
    return normalizedContent;
  }

  return cmsContent as Content;
};

export const ContentContextProvider: FC<ContentContextProviderProps> = ({
  contentService,
  children,
}) => {
  const [content, setContent] = useState<Content | undefined>(() =>
    normalizeContent(contentService),
  );

  useEffect(() => {
    const contentChangeHandler = () => {
      setContent(normalizeContent(contentService));
    };

    contentService.onContentChange(contentChangeHandler);

    return () => {
      contentService.removeOnContentChange(contentChangeHandler);
    };
  }, [contentService]);

  if (!content) {
    return null;
  }

  return <ContentContext.Provider value={content}>{children}</ContentContext.Provider>;
};
