import { Loader } from '@audi/audi-ui-react';
import React, { memo } from 'react';
import styled from 'styled-components';

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 11px;
  left: 11px;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    scale: 1.2;
    top: 13px;
    left: 13px;
  }
`;

export const LoadingIndicator = memo(() => <StyledLoader size="small" />);
