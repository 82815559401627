import type { FC } from 'react';
import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { FAStateContext } from '../context/FAStateContext';
import { NavigationButton } from './NavigationButton';
import { FeatureSelector } from './selector/FeatureSelector';
import { Image } from './slider/Image';

export const ASPECT_RATIO_SMALL = '1 / 1';
export const ASPECT_RATIO_MEDIUM = '4 / 3';
export const ASPECT_RATIO_LARGE = '19 / 9';

const Container = styled.div`
  position: relative;
  aspect-ratio: ${ASPECT_RATIO_SMALL};
  width: 100%;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    aspect-ratio: ${ASPECT_RATIO_MEDIUM};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    aspect-ratio: ${ASPECT_RATIO_LARGE};
  }
`;

const Slides = styled.div<{ currentSlide: number }>`
  display: flex;
  transition: transform 0.5s ease;
  transform: ${({ currentSlide }) => `translateX(-${currentSlide * 100}%)`};
`;

const NavigationContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  justify-content: space-between;
  transform: translateY(50%);

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    left: auto;
    right: 16px;
    bottom: 32px;
    transform: none;
    display: grid;
    gap: 8px;
    grid-auto-flow: columns;
    grid-template-columns: 1fr auto auto;
    align-items: end;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    bottom: 36px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    bottom: 48px;
  }
`;

export const Slider: FC = () => {
  const { images } = useContext(FAStateContext);
  const [imageIndex, setImageIndex] = useContext(FAStateContext).imageIndexState;
  const slideCount = images.length;
  const touchStartX = useRef<number>(0);
  const touchEndX = useRef<number>(0);

  const nextSlide = () => {
    setImageIndex((prev) => (prev + 1) % slideCount);
  };

  const prevSlide = () => {
    setImageIndex((prev) => (prev - 1 + slideCount) % slideCount);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.targetTouches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.targetTouches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      nextSlide();
    }

    if (touchStartX.current - touchEndX.current < -50) {
      prevSlide();
    }
  };
  return (
    <Container>
      <Slides
        currentSlide={imageIndex}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {images.map((url, index) => (
          <Image key={url} url={url} index={index} />
        ))}
      </Slides>
      <FeatureSelector />
      <NavigationContainer>
        <NavigationButton onClick={prevSlide} disabled={imageIndex === 0} direction="back" />
        <NavigationButton
          onClick={nextSlide}
          disabled={imageIndex + 1 === slideCount}
          direction="forward"
        />
      </NavigationContainer>
    </Container>
  );
};
