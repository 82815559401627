import styled from 'styled-components';

export const StyledArrowButtonContainer = styled.div<{
  visible: boolean;
  direction: 'back' | 'forward';
}>`
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    justify-content: center;
    overflow: hidden;
    position: absolute;
    background: white;
    width: 50px;
    height: 50px;
    z-index: 100;
    padding: 0 3px;
    left: ${({ direction }) => (direction === 'back' ? '0' : 'auto')};
    right: ${({ direction }) => (direction === 'forward' ? '0' : 'auto')};
  }
`;

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: var(${({ theme }) => theme.responsive.spacing.s});
  padding-block-end: var(${({ theme }) => theme.responsive.spacing.m});
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding-block-end: var(${({ theme }) => theme.responsive.spacing.l});
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-block-end: var(${({ theme }) => theme.responsive.spacing.xl});
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding-block-end: 32px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    padding-block-end: 44px;
  }
`;

export const InnerSelectorContainer = styled.div`
  background-color: white;
  display: flex;
  gap: 0;
  padding: 0 3px;
  scroll-behavior: smooth;
  /* transition: transform 1s cubic-bezier(0.47, 1.64, 0.41, 0.8); */
  scrollbar-width: 0;
  scrollbar-width: none;
  overflow-x: auto;
  z-index: 0;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 0 5px;
  }
`;
