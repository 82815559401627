import type { FC } from 'react';
import React, { useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { createAddUrlParams } from '../../utils/addUrlParams';
import { FAStateContext } from '../../context/FAStateContext';
import { ASPECT_RATIO_LARGE, ASPECT_RATIO_MEDIUM, ASPECT_RATIO_SMALL } from '../Slider';

const Slide = styled.div`
  aspect-ratio: ${() => ASPECT_RATIO_SMALL};
  position: relative;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    aspect-ratio: ${() => ASPECT_RATIO_MEDIUM};
    width: 100%;
    overflow: hidden;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    aspect-ratio: ${() => ASPECT_RATIO_LARGE};
  }
`;

interface StyledImageProps {
  readonly active?: boolean;
}

const StyledImage = styled.img<StyledImageProps>`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    transform: translateY(-10%);
    height: auto;
  }
`;

export interface ImageProps {
  readonly url: string;
  readonly index: number;
}

export const Image: FC<ImageProps> = ({ url, index }) => {
  const {
    carline: { name },
    imageIndexState: [imageIndex],
  } = useContext(FAStateContext);
  const theme = useTheme();
  const addUrlParams = createAddUrlParams(url);

  return (
    <Slide>
      <StyledImage
        src={url}
        srcSet={[483, 555, 768, 858, 966, 1110, 1280, 1440, 1536, 1920]
          .map((width) => `${addUrlParams(width)} ${width}w`)
          .join(', ')}
        sizes={`(min-width: ${theme.breakpoints.xxl}px) ${theme.breakpoints.xxl}px, (min-width: ${theme.breakpoints.m}px) 100vw, 134vw`}
        active={index === imageIndex}
        alt={`${name} ${index + 1}`}
        loading="lazy"
        width="400"
        height="300"
      />
    </Slide>
  );
};
