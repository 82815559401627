import { Button } from '@audi/audi-ui-react';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { addSearchParams } from '@oneaudi/feature-app-utils';
import { FAStateContext } from '../context/FAStateContext';
import { UniversalStateContext } from '../context/UniversalStateContext';

const Container = styled.div`
  margin-block-end: var(${({ theme }) => theme.responsive.spacing.l});
`;

export const ConfigureButton: FC<{ className?: string }> = ({ className }) => {
  const { i18nTexts } = useContext(UniversalStateContext)!;
  const {
    carline: { configuratorLink },
    idString,
  } = useContext(FAStateContext);

  const linkWithParams = useMemo(() => {
    if (!configuratorLink) {
      return null;
    }
    //  only for testing
    // const BASE_URL = 'https://www.audi.de';
    // let testLink = `${BASE_URL}${configuratorLink}`;
    // testLink = testLink.replace('shop-the-look', 'konfigurator');

    if (!idString) {
      return configuratorLink;
    }
    return addSearchParams(configuratorLink, new Map([['pr', idString]]));
  }, [idString]);

  if (!linkWithParams) {
    return null;
  }

  return (
    <Container className={className}>
      <Button variant="primary" href={linkWithParams} size="small">
        {i18nTexts.configureLinkText}
      </Button>
    </Container>
  );
};
