import type { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import type {
  FeatureAppDefinition,
  FeatureAppEnvironment,
  FeatureServices,
} from '@feature-hub/core';
import type { Logger } from '@feature-hub/logger';
import type { ReactFeatureApp } from '@feature-hub/react';
import type { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import type { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import type { I18NServiceV1 } from '@oneaudi/i18n-service';
import type { NumberFormatterServiceV1 } from '@oneaudi/number-formatter-service/dist/v1';
import type { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import type { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import React from 'react';
import { FeatureAppWrapper } from './FeatureAppWrapper';
import { UniversalStateContext } from './context/UniversalStateContext';
import type { UniversalState } from './utils/createUniversalState';
import { createUniversalState } from './utils/createUniversalState';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-number-formatter-service': NumberFormatterServiceV1;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'onegraph-service': OneGraphServiceV1;
  readonly 's2:logger': Logger;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-number-formatter-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'onegraph-service': '^1.0.0',
      's2:logger': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    baseUrl,
    featureServices,
    config,
    featureAppId,
  }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    if (!baseUrl) {
      throw new Error(`The feature app's baseUrl is undefined.`);
    }

    const asyncSsrManager = featureServices['s2:async-ssr-manager'];
    const logger = featureServices['s2:logger'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    const oneGraphService = featureServices['onegraph-service'];
    const i18nService = featureServices['dbad:audi-i18n-service'];
    const trackingService = featureServices['audi-tracking-service'];
    const serializedState = serializedStateManager?.getSerializedState();

    if (trackingService) {
      trackingService.featureAppName = 'customizer';
    }

    let universalState: UniversalState | undefined = serializedState
      ? (JSON.parse(serializedState) as UniversalState)
      : undefined;

    serializedStateManager?.register(() => JSON.stringify(universalState));

    const loadingPromise =
      universalState === undefined
        ? createUniversalState({ i18nService, logger }).then((state) => {
            universalState = state;
          })
        : undefined;

    asyncSsrManager?.scheduleRerender(loadingPromise);

    const featureAppElement = (
      <FeatureAppWrapper env={{ baseUrl, config, featureAppId, featureServices }} />
    );

    return {
      loadingPromise,
      render: () => (
        <UniversalStateContext.Provider value={universalState}>
          {asyncSsrManager
            ? oneGraphService.prepareRender(() => featureAppElement)
            : featureAppElement}
        </UniversalStateContext.Provider>
      ),
    };
  },
};

export default featureAppDefinition;
