export const createAddUrlParams = (url: string, extension?: 'webp' | 'jpg' | 'png') => {
  const urlObj = new URL(url);
  const IMG_EXTENSION = extension || 'webp';
  return (width?: number) => {
    const pathParts = urlObj.pathname.split('.');
    if (pathParts.length > 1) {
      pathParts[pathParts.length - 1] = IMG_EXTENSION;
      urlObj.pathname = pathParts.join('.');
    }
    if (width !== undefined) {
      urlObj.searchParams.set('wid', String(width));
    }
    return urlObj.toString();
  };
};
