export const ITEM_WIDTH = 50;
export const CONTAINER_MAX_WIDTH = {
  XS: 216,
  S: 262,
  M: 384,
  L: 408,
};

/**
 * Calculates the maximum width for the container based on the viewport size.
 * @param viewportWidth The width of the viewport.
 * @returns The maximum width for the container.
 */
export const getMaxWidth = (viewportWidth: number): number => {
  if (viewportWidth >= 1024) return CONTAINER_MAX_WIDTH.L;
  if (viewportWidth >= 768) return CONTAINER_MAX_WIDTH.M;
  if (viewportWidth >= 375) return CONTAINER_MAX_WIDTH.S;
  return CONTAINER_MAX_WIDTH.XS;
};
