import type { FC, MouseEventHandler } from 'react';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Icon } from '@audi/audi-ui-react';
import { FAStateContext } from '../../context/FAStateContext';
import { createAddUrlParams } from '../../utils/addUrlParams';
import { LoadingIndicator } from './LoadingIndicator';

const Button = styled.button`
  display: block;
  appearance: none;
  padding: 5px;
  background-color: transparent;
  border: none;
  position: relative;
`;

interface ImageProps {
  readonly active?: boolean;
  readonly loadingState?: boolean;
  readonly equipmentType: 'color' | 'rim';
}

const Image = styled.img<ImageProps>`
  display: block;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid ${({ active }) => (active ? 'black' : 'transparent')};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ loadingState }) => (!loadingState ? '' : 'opacity: .5')}
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}px) {
    width: 36px;
    height: 36px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    width: 36px;
    height: 36px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: 40px;
    height: 40px;
  }
`;

const StyledEditIcon = styled(Icon)`
  position: absolute;
  bottom: 4px;
  right: 4px;
  background-color: #fff;
  border-radius: 50%;
`;

export interface SelectorItemProps {
  readonly name: string;
  readonly imageUrl: string;
  readonly active?: boolean;
  readonly showEditIcon?: boolean;
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly equipmentType?: 'color' | 'rim';
}

export const SelectorItem: FC<SelectorItemProps> = ({
  name,
  imageUrl,
  onClick,
  active = false,
  showEditIcon = false,
  equipmentType = 'color',
}) => {
  const { loading } = useContext(FAStateContext);
  const clickHandler = useMemo((): MouseEventHandler<HTMLButtonElement> | undefined => {
    if (onClick) {
      return (e) => {
        e.currentTarget.scrollIntoView({
          inline: 'nearest',
          block: 'nearest',
          behavior: 'smooth',
        });
        onClick(e);
      };
    }
    return undefined;
  }, [onClick]);
  const [url40, url80] = useMemo(() => {
    const addUrlParams = createAddUrlParams(imageUrl, 'webp');
    return [addUrlParams(40), addUrlParams(80)];
  }, [imageUrl]);

  return (
    <Button type="button" onClick={clickHandler} disabled={loading}>
      {loading && active && <LoadingIndicator />}
      <Image
        active={active}
        loadingState={loading}
        alt={name}
        src={url40}
        srcSet={`${url40} 40w, ${url80} 80w`}
        sizes="40px"
        width="30"
        height="30"
        loading="lazy"
        equipmentType={equipmentType}
      />
      {showEditIcon && <StyledEditIcon name="edit" size="xs" variant="primary" />}{' '}
      {/* Conditionally render the edit icon */}
    </Button>
  );
};
