import { Icon, Text } from '@audi/audi-ui-react';
import type { FC } from 'react';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { UniversalStateContext } from '../context/UniversalStateContext';

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: top;
  grid-template-columns: auto 1fr;
  margin-block-end: var(${({ theme }) => theme.responsive.spacing.l});
  gap: var(${({ theme }) => theme.responsive.spacing.xxs});
  svg {
    padding-top: 2px;
  }
`;

export const MoreOptionsDisclaimer: FC = () => {
  const { i18nTexts } = useContext(UniversalStateContext)!;

  return (
    <Container>
      <Icon name="info-circle" size="xs" variant="secondary" />{' '}
      <Text variant="copy2" as="span" tint="secondary">
        {i18nTexts.moreColorsAndRims}
      </Text>
    </Container>
  );
};
