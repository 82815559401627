import { useState, useEffect, useCallback } from 'react';
import { getMaxWidth, ITEM_WIDTH } from '../utils/featureSelectorUtils';

const isScrollable = (
  containerRef: React.RefObject<HTMLDivElement>,
  itemCount: number,
): boolean => {
  if (!containerRef.current || typeof window === 'undefined') return false;
  const maxWidth = getMaxWidth(window.innerWidth);
  const totalItemWidth = ITEM_WIDTH * itemCount;
  return totalItemWidth > maxWidth; // Scroll is possible only if items exceed container width
};

const calculateScrollWidth = (
  containerRef: React.RefObject<HTMLDivElement>,
  isScrollForward: boolean,
) => {
  const container = containerRef.current;
  if (!container || typeof window === 'undefined') return 0;
  const maxScrollLeft = container.scrollWidth - container.clientWidth;
  const currentScrollLeft = container.scrollLeft;
  const maxWidth = getMaxWidth(window.innerWidth);
  // Calculate the remaining scrollable width
  const remainingScrollWidth = isScrollForward
    ? maxScrollLeft - currentScrollLeft
    : currentScrollLeft;
  return Math.min(remainingScrollWidth, maxWidth - ITEM_WIDTH - 6); // ITEM_WIDTH = button overlap, 6 = padding
};

export const useScrollButtons = (
  containerRef: React.RefObject<HTMLDivElement>,
  itemCount: number,
) => {
  const [showBackButton, setShowBackButton] = useState(false);
  const [showForwardButton, setShowForwardButton] = useState(false);

  useEffect(() => {
    setShowBackButton(false);
    if (isScrollable(containerRef, itemCount)) {
      setShowForwardButton(true);
    } else {
      setShowBackButton(false);
      setShowForwardButton(false);
    }
  }, [containerRef, itemCount]);

  const handleScroll = useCallback(
    (isScrollForward: boolean) => {
      const container = containerRef.current;
      if (!container) return;

      const scrollWidth = calculateScrollWidth(containerRef, isScrollForward);
      const newScrollLeft = isScrollForward
        ? container.scrollLeft + scrollWidth
        : container.scrollLeft - scrollWidth;
      // ToDo: if smoother animation or easing is necessary use requestAnimationFrame
      container.scrollTo({ left: newScrollLeft, behavior: 'smooth' });

      setShowBackButton(newScrollLeft > 0);
      setShowForwardButton(newScrollLeft < container.scrollWidth - container.clientWidth);
    },
    [containerRef],
  );

  return {
    showBackButton,
    showForwardButton,
    handleScroll,
  };
};
