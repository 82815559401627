import React, { useContext } from 'react';
import { Text } from '@audi/audi-ui-react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
} from '@oneaudi/feature-app-utils';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';
import { FAStateContext } from '../context/FAStateContext';
import { ContentContext } from '../context/ContentContext';

const ConsumptionAndEmission: React.FC = () => {
  const logger = useFeatureAppEnv().featureServices['s2:logger'];
  const {
    carline: {
      consumption: {
        consumption,
        emissionCO2,
        co2Class,
        dischargedCO2Class,
        dischargedConsumption,
      },
      name,
    },
  } = useContext(FAStateContext);
  const content = useContext(ContentContext);
  const {
    caeDisplayOption: { showCae },
  } = content;
  if (!showCae) {
    return null;
  }
  if (!consumption || !emissionCO2) {
    logger.warn('No emission and consumption data for', name);
    return null;
  }

  return (
    <Text variant="copy2" tint="secondary">
      {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(consumption))}
      {'; '}
      {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(emissionCO2))}
      {co2Class && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(co2Class))}
        </>
      )}
      {dischargedConsumption && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(dischargedConsumption),
          )}
        </>
      )}
      {dischargedCO2Class && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(dischargedCO2Class),
          )}
        </>
      )}
    </Text>
  );
};

export default ConsumptionAndEmission;
