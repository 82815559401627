import type { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import type {
  ChangeConfiguredCarFeatureQuery,
  ChangeConfiguredCarFeatureQueryVariables,
} from '../../graphql/documents/change-configured-car.generated';
import {
  ChangeConfiguredCarFeatureDocument,
  FeatureChangeAction,
} from '../../graphql/documents/change-configured-car.generated';
import type { MappedCarIdentifier } from './mapCarline';

export interface UpdateCarlineOptions {
  readonly oneGraphService: OneGraphServiceV1;
  readonly locale: GfaLocaleServiceV1;
  readonly changedFeature: string;
  readonly id: MappedCarIdentifier;
}

export const updateCarline = ({
  oneGraphService,
  locale,
  changedFeature,
  id,
}: UpdateCarlineOptions) =>
  oneGraphService
    .getApolloClient()
    .query<ChangeConfiguredCarFeatureQuery, ChangeConfiguredCarFeatureQueryVariables>({
      query: ChangeConfiguredCarFeatureDocument,
      variables: {
        changeConfiguredCarFeatureInput: {
          action: FeatureChangeAction.Add,
          autoResolveConflicts: true,
          feature: changedFeature,
          id: {
            brand: 'A',
            country: locale.countryCode,
            language: locale.language,
            ...id,
          },
        },
      },
      context: {
        headers: {
          'x-feature-flags': 'pr7-extensions#on',
        },
      },
    });
