import type { MouseEventHandler, ReactNode } from 'react';
import React, { memo } from 'react';
import styled from 'styled-components';
import { CONTAINER_MAX_WIDTH } from '../../utils/featureSelectorUtils';

interface ContainerProps {
  readonly open: boolean;
  readonly hasArrow: boolean;
  readonly containerWidth: number;
}

const Container = styled.div<ContainerProps>`
  --selector-height: 48px;

  background-color: white;
  position: relative;
  border-radius: calc(var(--selector-height) / 2);
  display: flex;
  height: var(--selector-height);
  transition: width 0.6s ease-in-out;
  width: ${({ open, containerWidth }) => (open ? `${containerWidth}px` : 'var(--selector-height)')};
  overflow: ${({ open }) => (open ? 'auto' : 'hidden')};
  align-items: center;
  justify-content: center;
  /*  hide scrollbar */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
  width: ${({ open, containerWidth }) =>
    open ? `${Math.min(containerWidth, CONTAINER_MAX_WIDTH.XS)}px` : 'var(--selector-height)'};

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    width: ${({ open, containerWidth }) =>
      open ? `${Math.min(containerWidth, CONTAINER_MAX_WIDTH.S)}px` : 'var(--selector-height)'};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    --selector-height: 58px;
    width: ${({ open, containerWidth }) =>
      open ? `${Math.min(containerWidth, CONTAINER_MAX_WIDTH.M)}px` : 'var(--selector-height)'};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    width: ${({ open, containerWidth }) =>
      open
        ? `${Math.min(containerWidth, CONTAINER_MAX_WIDTH.M)}px`
        : 'var(--selector-height)'}; /* Width for large screens */
  }
  height: var(--selector-height);

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    width: ${({ open, containerWidth }) =>
      open
        ? `${Math.min(containerWidth, CONTAINER_MAX_WIDTH.L)}px`
        : 'var(--selector-height)'}; /* Width for large screens */
  }
`;

export interface SelectorContainerProps extends ContainerProps {
  readonly onClick?: MouseEventHandler<HTMLDivElement>;
  readonly children?: ReactNode;
  readonly hasArrow: boolean;
}

export const SelectorContainer = memo(
  ({ children, open, hasArrow, ...props }: SelectorContainerProps) => (
    <Container open={open} hasArrow={hasArrow} {...props}>
      {children}
    </Container>
  ),
);
