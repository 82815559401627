import type * as Types from '../types.generated';

import { gql } from '@oneaudi/onegraph-client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DealerSearchSortId: { input: any; output: any };
  JSONObject: { input: any; output: any };
  StockCarSearchCriterionId: { input: any; output: any };
  StockCarSearchCriterionItemId: { input: any; output: any };
  StockCarSearchSortId: { input: any; output: any };
  StockCarSearchSpecialCriterionId: { input: any; output: any };
};

/** The input type for the acceptConflictSolutions query */
export type AcceptConflictSolutionsInput = {
  conflictTriggerId: Scalars['ID']['input'];
  /** Intermediate configured car identifier that is still in conflict. It is obtained from ConfiguredCarConflict.conflict.id */
  id: ConfiguredCarInput;
  /** Last conflict free configuration. This value should be taken from ConfiguredCarConflict.conflict.lastGoodConfiguredCarIdentifier */
  lastGoodConfiguredCarIdentifier?: InputMaybe<ConfiguredCarInput>;
  /** An array of PR numbers choosing a pr number of all of the solution groups to solve and accept the conflict. */
  solutions: Array<Scalars['String']['input']>;
};

/** new type */
export type AcceptConflictSolutionsPayload = {
  __typename?: 'AcceptConflictSolutionsPayload';
  configuredCar: ConfiguredCar;
};

export type AudiCodeCar = AudiCodeError | ConfiguredCar | StockCar;

export type AudiCodeError = {
  __typename?: 'AudiCodeError';
  code?: Maybe<AudiCodeErrorCarCode>;
  message?: Maybe<Scalars['String']['output']>;
};

export type AudiCodeErrorCarCode = CarCode & {
  __typename?: 'AudiCodeErrorCarCode';
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
};

/** A specific price corresponding to the ConfiguredCar of and AudiCode */
export type AudiCodePrice = CarPrice & {
  __typename?: 'AudiCodePrice';
  /** Market specific currency for the price */
  currencyDetails?: Maybe<Currency>;
  /** Formatted price with currency (e.g. 52.450,00 EUR) */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** Raw value of the price (e.g. 52.450) */
  value: Scalars['Float']['output'];
  /** Formatted price without currency (e.g. 52.450,00) */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

export type AudicodeGraphqlInfo = OneGraphSubServiceInfo & {
  __typename?: 'AudicodeGraphqlInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

/** information about stocks of a market */
export type AvailableStock = {
  __typename?: 'AvailableStock';
  /** information about the new car stock */
  newCars?: Maybe<Stock>;
  /** information about the used car stock */
  usedCars?: Maybe<Stock>;
};

export type AveRequest = {
  mimeType?: InputMaybe<Scalars['String']['input']>;
  prString: Scalars['String']['input'];
};

/** Body type of a CarlineStructureCarline, has an ID and a name. */
export type BodyType = {
  __typename?: 'BodyType';
  /** Machine readable body type id, e.g. 'limo'. */
  id: Scalars['String']['output'];
  /** Localized human readable body type name, e.g. 'Limousine'. */
  name: Scalars['String']['output'];
};

/** Cache Control scope */
export enum CacheControlScope {
  /**
   * If a cached response has a PRIVATE scope, its value is accessible by only a single user.
   * To enforce this restriction, the cache needs to know how to identify that user.
   * @see: https://www.apollographql.com/docs/apollo-server/performance/caching/#identifying-users-for-private-responses
   */
  Private = 'PRIVATE',
  /**
   * By default, PUBLIC cached responses are accessible by all users.
   * @see https://www.apollographql.com/docs/apollo-server/performance/caching/#separating-responses-for-logged-in-and-logged-out-users
   */
  Public = 'PUBLIC',
}

/** Generic Car, implemented as ConfiguredCar or StockCar */
export type Car = {
  /** Carline information of a car. */
  carline?: Maybe<CarlineBase>;
  /**
   * Unique identifier for a car.
   * Might contain AudiCode, MarketingCode etc.
   */
  code?: Maybe<CarCode>;
  /** Model information of a car. */
  model?: Maybe<CarModelBase>;
  /** Price information of a car. */
  prices?: Maybe<CarPrices>;
};

/**
 * Unique identifier for a car.
 * Might contain AudiCode, MarketingCode etc.
 */
export type CarCode = {
  /** Country that the `id` is valid for. */
  country?: Maybe<Scalars['String']['output']>;
  /**
   * Unique identifier for the stored car.
   * Might be AudiCode, MarketingCode etc.
   */
  id: Scalars['String']['output'];
  /** Language that the `id` is valid for. */
  language?: Maybe<Scalars['String']['output']>;
};

export type CarCodeRepairInfo = {
  __typename?: 'CarCodeRepairInfo';
  status: Scalars['Boolean']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type CarFeature = {
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media?: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars['String']['output'];
  pr3: Scalars['ID']['output'];
  pr7: Scalars['ID']['output'];
  price?: Maybe<Price>;
};

/** Data object of the family of a feature */
export type CarFeatureFamily = {
  __typename?: 'CarFeatureFamily';
  /**
   * The family id is non translated and will be the same for every market
   * Use this to identify certain families
   * E.g. "Komfort.Spiegel"
   */
  id: Scalars['String']['output'];
  /**
   * The family name is market translated
   * This field is not to be used to identify families
   * E.g. "Mirrors"
   */
  name: Scalars['String']['output'];
};

/** Data object of the feature group of a feature */
export type CarFeatureGroup = {
  __typename?: 'CarFeatureGroup';
  /**
   * The group id is non translated and will be the same for every market
   * Use this to identify certain groups
   * E.g. 'Komfort'
   */
  id: Scalars['String']['output'];
  /**
   * The group name is market translated
   * This field is not to be used to identify groups
   * E.g. 'Comfort'
   */
  name: Scalars['String']['output'];
};

/**
 * Defines image types.
 *
 * Currently, all fields correspond to VSAPI image types. It is planned to replace them with more generic fields like
 * `xl` instead of `bigImage`.
 */
export type CarFeatureImage = {
  /** Size - width: 140, height: 63 */
  ak4DetailsL?: Maybe<ImageSource>;
  /** Size - width: 79, height: 35 */
  ak4DetailsM?: Maybe<ImageSource>;
  /** Size - width: 59, height: 26 */
  ak4DetailsS?: Maybe<ImageSource>;
  /** Size - width: 159, height: 71 */
  ak4DetailsXL?: Maybe<ImageSource>;
  /** Size - width: 20, height: 24 */
  ak4FoldingTile?: Maybe<ImageSource>;
  /** Size - width: 40, height: 36 */
  ak4InnerTile?: Maybe<ImageSource>;
  /** Size - width: 39, height: 24 */
  ak4OuterTile?: Maybe<ImageSource>;
  /** Size - width: 259, height: 120 */
  akMotivL?: Maybe<ImageSource>;
  /** Size - width: 119, height: 47 */
  akMotivM?: Maybe<ImageSource>;
  /** Size - width: 59, height: 23 */
  akMotivS?: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  bigImage?: Maybe<ImageSource>;
  /** Size - width: 145, height: 80 */
  contentS?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  decoTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  decoTileHD?: Maybe<ImageSource>;
  /** Size - width: 720, height: 1280 */
  decoTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  decoTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageFrontHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageFrontL?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageSideHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageSideL?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageTopHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageTopL?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  foldingTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  foldingTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  foldingTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  foldingTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImage34HD?: Maybe<ImageSource>;
  /** Size - width: 601, height: 1400 */
  frontViewImage34L?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  frontViewImage34M?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImage34S?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImage34XS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  frontViewImageL?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImageXS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  headlightImage?: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  headlightImageS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageSequenceEntryHD?: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  imageSequenceEntryS?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  inlayTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  inlayTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  inlayTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  inlayTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  innerTileHD?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  interiorTileButton?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  interiorTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  interiorTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 2048, height: 2048 */
  interiorTileSquareL?: Maybe<ImageSource>;
  /** Size - width: 159, height: 74 */
  nwbamMotiv?: Maybe<ImageSource>;
  /** Size - width: 79, height: 47 */
  nwbsMotiv?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  outerTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  outerTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  outerTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  outerTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  seatImage?: Maybe<ImageSource>;
  /** Size - width: 190, height: 250 */
  seatImageS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  smallImageHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  steeringWheelImage?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  steeringWheelImageS?: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  teaserImage?: Maybe<ImageSource>;
  /** Size - width: 249, height: 140 */
  thumbnailHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  wheelImage?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  wheelImageS?: Maybe<ImageSource>;
};

/**
 * Defines text types.
 *
 * Currently, all fields correspond to VSAPI text types. It is planned to replace them with more generic fields like
 * `headline` instead of `akHeadline`.
 */
export type CarFeatureText = {
  akHeadline?: Maybe<Scalars['String']['output']>;
  akRemarks?: Maybe<Scalars['String']['output']>;
  akText?: Maybe<Scalars['String']['output']>;
  benefits?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  carpetText?: Maybe<Scalars['String']['output']>;
  carpetTitle?: Maybe<Scalars['String']['output']>;
  dashboardText?: Maybe<Scalars['String']['output']>;
  dashboardTitle?: Maybe<Scalars['String']['output']>;
  engineDescription?: Maybe<Scalars['String']['output']>;
  engineGear?: Maybe<Scalars['String']['output']>;
  engineName?: Maybe<Scalars['String']['output']>;
  engineNamesText?: Maybe<Scalars['String']['output']>;
  exteriorColorText?: Maybe<Scalars['String']['output']>;
  exteriorColorTitle?: Maybe<Scalars['String']['output']>;
  exteriorContrastColorText?: Maybe<Scalars['String']['output']>;
  exteriorContrastColorTitle?: Maybe<Scalars['String']['output']>;
  headliningText?: Maybe<Scalars['String']['output']>;
  headliningTitle?: Maybe<Scalars['String']['output']>;
  intro?: Maybe<Scalars['String']['output']>;
  seatsText?: Maybe<Scalars['String']['output']>;
  seatsTitle?: Maybe<Scalars['String']['output']>;
  teaserText?: Maybe<Scalars['String']['output']>;
};

/**
 * Defines video types.
 *
 * Currently, all fields correspond to VSAPI video types. It is planned to replace them with more generic fields like
 * `mp4XL` instead of `bigVideoMp4`.
 */
export type CarFeatureVideo = {
  /** Size - width: 888, height: 500 */
  bigVideoMp4?: Maybe<VideoSource>;
  /** Size - width: 704, height: 396 */
  videoMp4?: Maybe<VideoSource>;
  /** Size - width: 888, height: 500 */
  videoThumb?: Maybe<VideoSource>;
};

/** Identifier of a car. Can be used as Input value for specific queries. */
export type CarIdentifier = {
  /**
   * KVPS ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /**
   * User selected features/options contained in the ConfiguredCar
   * 7-digit or 3-digit pr numbers
   */
  equipmentOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: Maybe<Scalars['String']['output']>;
  /** 2-digit pr number of the interior color */
  interiorColor?: Maybe<Scalars['String']['output']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  /** The model input separated in an own input */
  model: CarModelIdentifier;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
};

/** Images for a car. */
export type CarImages = {
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS?: Maybe<ImageSource>;
};

/** Model information */
export type CarModelBase = {
  /** Identifier of the model */
  id?: Maybe<CarModelIdentifier>;
  /** Name of the model */
  name?: Maybe<Scalars['String']['output']>;
};

/**
 * Interface for a model of a car.
 * The values has to be handled as 'one' - they can't be modified or changed in an authoring environment.
 * The fo
 */
export type CarModelIdentifier = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars['String']['output'];
  /**
   * 3-digit pr numbers.
   * The extensions are PrNumbers that are part of the model code.
   * These PrNumbers are treated as standard options for this model (e.g. set a specific package or equipment).
   * This extensions can't be deselected in the configuration process. Sometimes this PrNumbers are more like a marker. For example mark the trimline of the model.
   * The handling of extensions can differ in different markets.
   */
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  /** 1-digit model version */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release */
  year: Scalars['Int']['output'];
};

/** A specific price. */
export type CarPrice = {
  /** Market specific currency for the price */
  currencyDetails?: Maybe<Currency>;
  /** Formatted price with currency (e.g. 1.321,50 EUR) */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** Raw value of the price (e.g. 1321.5) */
  value: Scalars['Float']['output'];
  /** Formatted price without currency (e.g. 1.321,50) */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

/** All available prices for a car. */
export type CarPrices = {
  /** Total price of the car */
  total?: Maybe<CarPrice>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type Carline = CarlineBase & {
  __typename?: 'Carline';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Carline custom attributes. */
export type CarlineAttribute = {
  __typename?: 'CarlineAttribute';
  /** Machine readable unique identifier for a single attribute, e.g. 'umweltbonus'. */
  id: Scalars['String']['output'];
  /** Localized human readable display name, e.g. 'Elektro Förderung'. */
  name: Scalars['String']['output'];
};

/** information about a carline in the stocks of a market */
export type CarlineAvailableStock = {
  __typename?: 'CarlineAvailableStock';
  /** information about the new car stock */
  newCars?: Maybe<Stock>;
  /** information about the used car stock */
  usedCars?: Maybe<Stock>;
};

/** Carline information */
export type CarlineBase = {
  /** Id of the carline (a1sb) */
  id: Scalars['String']['output'];
  /** Localized name of the carline (A1 Sportback) */
  name: Scalars['String']['output'];
};

/** Consumption and emission strings for this carline. */
export type CarlineConsumptionAndEmission = {
  __typename?: 'CarlineConsumptionAndEmission';
  /** Raw consumption and emission ranges per fuel. */
  consumptionAndEmissionPerFuel: Array<CarlineConsumptionAndEmissionRanges>;
  /** Mesaurements data splited by fuel type with min and max values */
  measurements?: Maybe<CarlineMeasurementsEec>;
  /**
   * Raw consumption and emission ranges.
   * @deprecated Please use consumptionAndEmissionPerFuel
   */
  ranges?: Maybe<CarlineConsumptionAndEmissionRanges>;
  /** Summary of formatted consumption and emission strings. */
  summary?: Maybe<CarlineConsumptionAndEmissionSummary>;
};

/** Consumption and emission range data for a carline. */
export type CarlineConsumptionAndEmissionData = {
  __typename?: 'CarlineConsumptionAndEmissionData';
  /** Emission range data. */
  co2Emission?: Maybe<CarlineMinMaxValue>;
  /** Consumption range data. */
  consumption?: Maybe<CarlineMinMaxValue>;
  /** Power consumption range data. */
  powerConsumption?: Maybe<CarlineMinMaxValue>;
};

/** Contains raw values for consumption and emission data ranges. */
export type CarlineConsumptionAndEmissionRanges = {
  __typename?: 'CarlineConsumptionAndEmissionRanges';
  /** Unit used for displaying consumption values, e.g. 'l/100km' */
  consumptionUnit: Scalars['String']['output'];
  /**
   * Emissions and efficiency class range.
   * @deprecated No longer supported
   */
  eec?: Maybe<EecRange>;
  /** Unit used for displaying emission values, e.g. 'g/100km' */
  emissionUnit: Scalars['String']['output'];
  /** Uniquely identifies a fuel type, e.g. 'diesel' */
  fuelTypeId: Scalars['String']['output'];
  /** NEDC emission and consumption data. */
  nedc?: Maybe<CarlineConsumptionAndEmissionData>;
  /** WLTP emission and consumption data. */
  wltp?: Maybe<CarlineConsumptionAndEmissionData>;
};

/**
 * Collection of formatted consumption and emission strings for this carline.
 * Ths values will contain references to footnotes that are listed in a separate field.
 * This is a convenient type for handling consumption & emission in the front end. No formatting is required.
 */
export type CarlineConsumptionAndEmissionSummary = {
  __typename?: 'CarlineConsumptionAndEmissionSummary';
  /** Short description of CO2 class */
  co2Class?: Maybe<Scalars['String']['output']>;
  /**
   * Consumption in the correct formatted values in respect to country, language.
   * eg. "Stromverbrauch kombiniert<sup>{{footnote.reference.cae_global_disclaimer}}</sup>: 26.1-21.7 kWh&#x2F;100 km"
   */
  consumption: Scalars['String']['output'];
  /** Short description of discharged CO2 consumption */
  dischargedCO2Class?: Maybe<Scalars['String']['output']>;
  /** Short description of discharged consumption */
  dischargedConsumption?: Maybe<Scalars['String']['output']>;
  /**
   * Emission in the correct formatted values in respect to country, language.
   * eg.  "CO<sub>2</sub>-Emissionen<sup>{{footnote.reference.cae_global_disclaimer}}</sup>: 0-0 g&#x2F;km"
   */
  emissionCO2: Scalars['String']['output'];
  /** Summary Nitrogen Dioxide text */
  emissionN02?: Maybe<Scalars['String']['output']>;
  /**
   * List of footnote reference within the fields "consumption" and "emission"
   * eg.["footnote.reference.cae_global_disclaimer"]
   * }
   */
  footnoteReference?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CarlineConsumptionsItem = {
  __typename?: 'CarlineConsumptionsItem';
  fuelTypeId?: Maybe<Scalars['String']['output']>;
  fuelTypeName?: Maybe<Scalars['String']['output']>;
  isWltp?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  maxWithUnit?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  minWithUnit?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type CarlineEecItem = {
  __typename?: 'CarlineEecItem';
  eecCo2Value?: Maybe<Scalars['String']['output']>;
  eecFuelValue?: Maybe<Scalars['String']['output']>;
  isPureCNG?: Maybe<Scalars['Boolean']['output']>;
  isPureElectric?: Maybe<Scalars['Boolean']['output']>;
};

export type CarlineEmissionItem = {
  __typename?: 'CarlineEmissionItem';
  max?: Maybe<Scalars['Float']['output']>;
  maxWithUnit?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  minWithUnit?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type CarlineEmissionSummary = {
  __typename?: 'CarlineEmissionSummary';
  consumption?: Maybe<Scalars['String']['output']>;
  emissionCO2?: Maybe<Scalars['String']['output']>;
  footnoteReferences?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carline groups. */
export type CarlineGroup = {
  __typename?: 'CarlineGroup';
  /** Ordered list of carlines. The exact order is determined in the carline management UI. */
  carlines: Array<CarlineStructureCarline>;
  /** Optional exterior views. */
  exteriorViews?: Maybe<ExteriorViews>;
  /** @deprecated Use identifier.id */
  id: Scalars['String']['output'];
  /** Uniquely identifies a carline. */
  identifier: CarlineIdentifier;
  /** @deprecated Carline group will not be mocked in the future */
  isMocked: Scalars['Boolean']['output'];
  /**
   * Carline editor metadata.
   * @deprecated Not used anymore
   */
  metadata: CarlineGroupMetadata;
  /** Localized, human readable display name, e.g. 'A3' */
  name: Scalars['String']['output'];
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carline groups. */
export type CarlineGroupCarlinesArgs = {
  carlinesInput?: InputMaybe<CarlinesInput>;
};

/** Input type to uniquely identify a carline group. */
export type CarlineGroupIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Machine readable carline group id, e.g. 'a1' */
  id: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  targetGroup?: InputMaybe<Scalars['String']['input']>;
};

/** Describes carline group metadata for the carline editor. */
export type CarlineGroupMetadata = {
  __typename?: 'CarlineGroupMetadata';
  /**
   * Visibility flag of this carline group.
   * @deprecated Not used anymore
   */
  isActive: Scalars['Boolean']['output'];
  /**
   * List of previous carline group IDs.
   * @deprecated Not used anymore
   */
  previousIds: Array<Maybe<Scalars['String']['output']>>;
  /**
   * The carline group sort order.
   * @deprecated Not used anymore
   */
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type CarlineGroupsIdentifierInput = {
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  /** @deprecated No longer supported */
  targetGroup?: InputMaybe<Scalars['String']['input']>;
};

/** Contains Health Status */
export type CarlineHealth = {
  __typename?: 'CarlineHealth';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** Uniquely identifies a carline. */
export type CarlineIdentifier = {
  __typename?: 'CarlineIdentifier';
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Carline ID */
  carlineId?: Maybe<Scalars['String']['output']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id: Scalars['String']['output'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  /** VTP ID used on fake carlines */
  vtpId?: Maybe<Scalars['String']['output']>;
};

/** Input type to uniquely identify a carline. */
export type CarlineIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  /** Unique, Target group identifier (currently not used) */
  targetGroup?: InputMaybe<Scalars['String']['input']>;
};

/** Contains status for carline service */
export type CarlineInfoServiceInfo = OneGraphSubServiceInfo & {
  __typename?: 'CarlineInfoServiceInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

export type CarlineLink = {
  __typename?: 'CarlineLink';
  /** Link url */
  url?: Maybe<Scalars['String']['output']>;
};

export type CarlineMeasurementsEec = {
  __typename?: 'CarlineMeasurementsEEC';
  consumptions?: Maybe<Array<Maybe<CarlineConsumptionsItem>>>;
  eecModel?: Maybe<CarlineEecItem>;
  efficiencyClassRanges?: Maybe<EecRange>;
  efficiencyClassSustainingRanges?: Maybe<EecRange>;
  emission?: Maybe<CarlineEmissionItem>;
  summary?: Maybe<CarlineEmissionSummary>;
};

/** Describes carline metadata for the carline editor. */
export type CarlineMetadata = {
  __typename?: 'CarlineMetadata';
  /**
   * Visibility flag of this carline.
   * @deprecated Not used anymore
   */
  isActive: Scalars['Boolean']['output'];
  /**
   * List of previous carline IDs.
   * @deprecated Not used anymore
   */
  previousIds: Array<Scalars['String']['output']>;
  /** @deprecated Not used anymore */
  releaseDate?: Maybe<Scalars['String']['output']>;
  /**
   * The carline sort order.
   * @deprecated Not used anymore
   */
  sortOrder: Scalars['Int']['output'];
};

/** Upper and lower boundary of a value range. */
export type CarlineMinMaxValue = {
  __typename?: 'CarlineMinMaxValue';
  /** Upper range boundary. */
  max?: Maybe<Scalars['Float']['output']>;
  /** Lower range boundary. */
  min?: Maybe<Scalars['Float']['output']>;
};

/** Describes a generic carline price. */
export type CarlinePrice = {
  __typename?: 'CarlinePrice';
  /** Currency code according to ISO 4217. */
  code: Scalars['String']['output'];
  /** Currency symbol as string. */
  symbol?: Maybe<Scalars['String']['output']>;
  /** Raw value. */
  value: Scalars['Float']['output'];
  /** Formatted value according to country / language. */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

/** Contains status for carline service */
export type CarlineStatus = {
  __typename?: 'CarlineStatus';
  endpoint: Scalars['String']['output'];
  health: CarlineHealth;
  id: Scalars['String']['output'];
  meta: CarlineStatusMeta;
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Carline status meta information */
export type CarlineStatusMeta = {
  __typename?: 'CarlineStatusMeta';
  channel: Scalars['String']['output'];
  homepage: Scalars['String']['output'];
  issueTracker: Scalars['String']['output'];
  team: Scalars['String']['output'];
};

/** Describes the carline structure for a specific brand, country, language and target group. */
export type CarlineStructure = {
  __typename?: 'CarlineStructure';
  /** Ordered list of carline groups. The exact order is determined in the carline management UI. */
  carlineGroups: Array<CarlineGroup>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carlines. */
export type CarlineStructureCarline = {
  __typename?: 'CarlineStructureCarline';
  /**
   * information about the carline in the stocks of the market.
   * the market is selected by the information in the `CarlineIdentifier`
   */
  availableStock?: Maybe<CarlineAvailableStock>;
  /** Body type of this carline. */
  bodyType?: Maybe<BodyType>;
  /** Array of Body types of this carline. */
  bodyTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Renderman delivers usageTypes this data is a mapped here. */
  categories: Array<Scalars['String']['output']>;
  /** Summary of consumption and emission ranges formatted as string according to country and language. */
  consumptionAndEmission: CarlineConsumptionAndEmission;
  /**
   * Default configuration of the carline
   *
   * Disclaimer:
   * This doesn't financingInput, all financing products and parameters used will be the market's defaults
   */
  defaultConfiguredCar?: Maybe<ConfiguredCar>;
  /** Optional exterior views. */
  exteriorViews?: Maybe<ExteriorViews>;
  fuelTypes: Array<FuelType>;
  /** Carline further carline group ids */
  furtherCarlineGroupIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Please use identifier.id */
  id: Scalars['String']['output'];
  /** Unique carline identifier */
  identifier: CarlineIdentifier;
  /** Fake carlines are maintained by content managers and are not configurable. Ex: end of secrecy (eos), promoted carlines */
  isFake: Scalars['Boolean']['output'];
  /** @deprecated Use isFake instead */
  isMocked: Scalars['Boolean']['output'];
  /** Carline links to specific pages */
  links?: Maybe<CarlineStructureLink>;
  /**
   * Carline editor metadata.
   * @deprecated Not used anymore
   */
  metadata: CarlineMetadata;
  /**
   * Lowest possible price of this carline.
   * @deprecated use prices.minPrice instead
   */
  minPrice?: Maybe<CarlinePrice>;
  /** The year of the catalogue release, e.g. '2021' */
  modelYear: Scalars['Int']['output'];
  /** Localized, human readable display name, e.g. 'A3 Sportback' */
  name: Scalars['String']['output'];
  /** Contains Financing Parameters and also the minPrice. */
  prices: CarlineTrimlineFinanceablePrices;
  releaseDate?: Maybe<Scalars['String']['output']>;
  /** Trimlines of this carline. */
  trimlines: Array<Trimline>;
  vehicleType?: Maybe<VehicleType>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carlines. */
export type CarlineStructureCarlineAvailableStockArgs = {
  partnerInfo?: InputMaybe<PartnerInfo>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carlines. */
export type CarlineStructureCarlinePricesArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** Input type to uniquely identify a carline for carline structure requests. */
export type CarlineStructureCarlineIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  carlineId?: InputMaybe<Scalars['String']['input']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  /** @deprecated No longer supported */
  targetGroup?: InputMaybe<Scalars['String']['input']>;
};

/** options for filter (reduce number of) carlines */
export type CarlineStructureForStockCarsCarlineFilterInput = {
  /**
   * hide all carlines, which are currently not available in selected stock
   *
   * NEW = "carlineGroups[].carlines[].availableStock.newCars.amount" is zero
   * USED = "carlineGroups[].carlines[].availableStock.usedCars.amount" is zero
   * NEW_AND_USED = "carlineGroups[].carlines[].availableStock.newCars.amount" and "carlineGroups[].carlines[].availableStock.usedCars.amount" are zero
   * NEW_OR_USED = "carlineGroups[].carlines[].availableStock.newCars.amount" or "carlineGroups[].carlines[].availableStock.usedCars.amount" are zero
   */
  hideNotAvailableInCarsStock?: InputMaybe<StockCarsTypeCombinationInput>;
};

/** options for filter (reduce number of) carline groups */
export type CarlineStructureForStockCarsCarlineGroupsFilterInput = {
  /** hide all carline groups without carlines (e.g. all are filtered by other options) */
  hideWithNoCarlines?: InputMaybe<Scalars['Boolean']['input']>;
};

/** options for filter (reduce number of) carline groups and/or carlines */
export type CarlineStructureForStockCarsFilterInput = {
  /** options for filter (reduce number of) carline groups */
  carlineGroups?: InputMaybe<CarlineStructureForStockCarsCarlineGroupsFilterInput>;
  /** options for filter (reduce number of) carlines */
  carlines?: InputMaybe<CarlineStructureForStockCarsCarlineFilterInput>;
};

export type CarlineStructureIdentifierInput = {
  /** KVPS brand id. e.g. A for Audi */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  /** @deprecated Not required anymore */
  targetGroup?: InputMaybe<Scalars['String']['input']>;
};

export type CarlineStructureLink = {
  __typename?: 'CarlineStructureLink';
  /** Link to configurator page */
  carlineStartPage?: Maybe<CarlineLink>;
  /** Link to configurator page */
  configuratorPage?: Maybe<CarlineLink>;
  /** Link to shop the look page */
  shopTheLookPage?: Maybe<CarlineLink>;
};

/** Determines the order of fetch and merge operations between API and database. */
export enum CarlineStructureMode {
  /** Fetch carline structure from the API and merge preview carlines and carline groups from the database. */
  Automatic = 'AUTOMATIC',
  /** Fetch carline structure from the database and fill in details from the API. */
  Manual = 'MANUAL',
}

export type CarlineTrimlineFinanceablePrices = {
  __typename?: 'CarlineTrimlineFinanceablePrices';
  financingParameters?: Maybe<FinancingParameters>;
  /** Price of the cheapest model that belongs to the carline or trimline */
  minPrice?: Maybe<Price>;
  monthlyRate?: Maybe<MonthlyRateResult>;
};

/** Carlines configuration input if non configurable carlines should be included for this request */
export type CarlinesInput = {
  includeNonConfigurables?: InputMaybe<Scalars['Boolean']['input']>;
};

/** @deprecated change mutation not implemented */
export type ChangeAction = {
  itemId: Scalars['String']['input'];
  resolveConflicts?: InputMaybe<Scalars['Boolean']['input']>;
  type: ChangeType;
};

/** The input type for the query changeConfiguredCarFeature. */
export type ChangeConfiguredCarFeatureInput = {
  /** An action that defines if you want to remove or to add the given feature. */
  action: FeatureChangeAction;
  /**
   * Set to `true` if you want to automatically resolve all occuring conflicts by
   * their default solutions. In this case you can rely on that the returned payload
   * will always contain a `configuredCar`.
   * Set to `false`(the default) if you want to manually handle possible conflicts.
   */
  autoResolveConflicts?: InputMaybe<Scalars['Boolean']['input']>;
  /** The feature id (pr3) that you want to add/remove to the configuration. */
  feature: Scalars['ID']['input'];
  /** The configured car you want to change */
  id: ConfiguredCarInput;
};

/**
 * The input type for the query changeConfiguredCarModel.
 *
 * In contrast to a feature change you can only set a new model and not remove a model in the configuration.
 */
export type ChangeConfiguredCarModelInput = {
  /**
   * Set to `true` if you want to automatically resolve all occuring conflicts by
   * their default solutions. In this case you can rely on that the returned payload
   * will always contain a `configuredCar`.
   * Set to `false`(the default) if you want to manually handle possible conflicts.
   */
  autoResolveConflicts?: InputMaybe<Scalars['Boolean']['input']>;
  /** The configured car you want to change */
  id: ConfiguredCarInput;
  /** The model that you want to set to the configuration. */
  model: ConfiguredCarModelInput;
};

/** The return type for the query changeConfiguredCarModel and changeConfiguredCarFeature. */
export type ChangeConfiguredCarPayload = {
  __typename?: 'ChangeConfiguredCarPayload';
  /**
   * A configured car, if the configuration could be changed successfully. `null` if a
   * conflict occured while changing the configuration and `autoResolveConflict` was set to `false`.
   */
  configuredCar?: Maybe<ConfiguredCar>;
  /**
   * Contains a conflict, if a conflict occured while changing the configuration. If `autoResolveConflict` was set to
   * `true` this field contains that conflict that was automatically resolved.
   */
  conflict?: Maybe<ConfiguredCarConflict>;
  /**
   * A list of side effects (without user choice) that
   * 1. occured while changing the configuration, if configuredCar is not null.
   * 2. will occur, if configuredCar is null and when accepting the conflict in `conflict`.
   */
  sideEffects: Array<SolutionSideEffect>;
};

/** The input type for the query changeConfiguredCarTrimline. */
export type ChangeConfiguredCarTrimlineInput = {
  /** Set to true if you want to automatically resolve all occuring conflicts by their default solutions. In this case you can rely on that the returned payload will always contain a configuredCar. Set to false (the default) if you want to manually handle possible conflicts. */
  autoResolveConflicts?: InputMaybe<Scalars['Boolean']['input']>;
  /** The configured car you want to change */
  id: ConfiguredCarInput;
  /** The trimline id that you want to set to the configuration. */
  trimline: Scalars['ID']['input'];
};

/** The input type for the changeConflictSolutions query */
export type ChangeConflictSolutionsInput = {
  /** Represents the feature/model/trimeline Id that originated the conflict. Take this value from `ConfiguredCarConflict.triggerId`. */
  conflictTriggerId: Scalars['ID']['input'];
  /** Intermediate configured car identifier that is still in conflict. It is obtained from ConfiguredCarConflict.conflict.id */
  id: ConfiguredCarInput;
  /** Last conflict free configuration. This value should be taken from ConfiguredCarConflict.conflict.lastGoodConfiguredCarIdentifier */
  lastGoodConfiguredCarIdentifier?: InputMaybe<ConfiguredCarInput>;
  /**
   * The PR number of an alternative feature replacing one of the currently selected options in the solution groups.
   *
   * This parameter is required! It is only optional for backwardscompatibility reasons with existing queries. The parameter will be made required in the schema as soon as all current FEs have adopted this input field.
   *
   * Example (extending the example from field "solutions"):
   * Assuming that the from the solution group X -> *a*, b, c the solution "b" needs to be selected. The set parameter should be set to "b" while keeping the "solutions" untouched as described in "solutions".
   */
  set?: InputMaybe<Scalars['String']['input']>;
  /**
   * The PR numbers of the selected solutions as provided by the conflict. Take these values from `Solution.feature.pr3` or `Solution.feature.pr7` of the conflict.
   *
   * Example:
   * The conflict contains 3 solution groups X, Y, Z.
   *
   * X -> *a*, b, c
   * Y -> *d*, e, f
   * Z -> *g*, h, i
   *
   * By default the first solution is selected for every solution group.
   *
   * The requester must populate the solutions field with ["a", "d", "g"]
   */
  solutions: Array<Scalars['String']['input']>;
};

/** The return type of the changeConflictSolutions query */
export type ChangeConflictSolutionsPayload = {
  __typename?: 'ChangeConflictSolutionsPayload';
  conflict: ConfiguredCarConflict;
};

/** @deprecated change mutation not implemented */
export enum ChangeType {
  Add = 'ADD',
  Remove = 'REMOVE',
}

/** Returned payload containing ConfiguredCar and conflict resolution information as a result of a configuration change. */
export type ChangedConfiguredCar = {
  __typename?: 'ChangedConfiguredCar';
  autoResolvedConflict: Scalars['Boolean']['output'];
  configuredCar: ConfiguredCar;
};

/** Implementation of the subgraph service info object. Returns status info for the cola subgraph */
export type ColaServiceInfo = OneGraphSubServiceInfo & {
  __typename?: 'ColaServiceInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

export type Config = {
  __typename?: 'Config';
  backColor?: Maybe<Scalars['String']['output']>;
  environment?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  triggers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ConfigInput = {
  backColor?: InputMaybe<Scalars['String']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  triggers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type Configuration = {
  __typename?: 'Configuration';
  availableOptions?: Maybe<Array<Option>>;
  ave: Scalars['String']['output'];
  carline: Carline;
  emissionAndConsumption?: Maybe<EmissionAndConsumption>;
  engine: Engine;
  prString: Scalars['String']['output'];
  priceDate: PriceDate;
  prices: Prices;
  renderImages?: Maybe<RenderImages>;
  selectedOptions?: Maybe<Array<Option>>;
  /** @deprecated No longer supported */
  techData: TechData;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationAvailableOptionsArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationPricesArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationSelectedOptionsArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar.catalog.features` */
export type ConfigurationFeature = CarFeature & {
  __typename?: 'ConfigurationFeature';
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media?: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars['String']['output'];
  pr3: Scalars['ID']['output'];
  pr7: Scalars['ID']['output'];
  price?: Maybe<Price>;
};

/** @deprecated queries deprecated */
export type ConfigurationIdentifier = {
  identifier: Scalars['String']['input'];
  type: IdentifierType;
};

/** @deprecated data moved into `ConfiguredCar.catalog.packages` */
export type ConfigurationPackage = CarFeature & {
  __typename?: 'ConfigurationPackage';
  content: Array<ConfigurationFeature>;
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media?: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars['String']['output'];
  pr3: Scalars['ID']['output'];
  pr7: Scalars['ID']['output'];
  price?: Maybe<Price>;
};

/** A fully configured car */
export type ConfiguredCar = Car & {
  __typename?: 'ConfiguredCar';
  /**
   * The id and name of the carline of the configured car
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  carline?: Maybe<ConfiguredCarCarline>;
  /** The carline object of the configured car */
  carlineStructureCarline?: Maybe<CarlineStructureCarline>;
  /** The complete catalog with all available features, model entries and package details. */
  catalog?: Maybe<ConfiguredCarCatalog>;
  /** configured cars are not saved cars, so the code is always empty. */
  code: ConfiguredCarCarCode;
  /**
   * Dynamic feature catalog.
   * Static parts can be retrieved by the "modelCatalog" query.
   *
   * If no filter is sent full catalog will be sent.
   */
  dynamicFeatureCatalog?: Maybe<Array<Maybe<ConfiguredCarDynamicCatalogFeature>>>;
  /**
   * Contains data about the outside color of the car and hints for the brightness of overlapping text
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  exteriorColor: ConfiguredCarColor;
  /** ConfiguredCar Identifier */
  id: ConfiguredCarIdentifier;
  /**
   * Stringified ConfiguredCar Identifier
   * e.g.: "FU5AUG0_2025|Z9Z9|MI|GPQBPQB|MLSE9VD|MPIN98R"
   */
  idString?: Maybe<Scalars['String']['output']>;
  images: ModelResult;
  /**
   * A list of incentives
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  incentives: Array<Incentive>;
  /** Contains EEC labels and other media resources of the configured car */
  media?: Maybe<ConfiguredCarMediaResources>;
  /**
   *   Object which describes the car
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  model?: Maybe<ConfiguredCarModelBase>;
  prices?: Maybe<ConfiguredCarPrices>;
  /** similar cars in new car stock and used car stock with this configured car as reference */
  similarCars?: Maybe<SimilarCarsStock>;
  technicalData?: Maybe<ConfiguredCarTechnicalData>;
};

/** A fully configured car */
export type ConfiguredCarDynamicFeatureCatalogArgs = {
  filter?: InputMaybe<DynamicCatalogFilterInput>;
};

/** A fully configured car */
export type ConfiguredCarImagesArgs = {
  client?: Scalars['String']['input'];
  configs?: InputMaybe<Array<ConfigInput>>;
  indexed?: InputMaybe<Scalars['Boolean']['input']>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A fully configured car */
export type ConfiguredCarPricesArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** media type Audi Visualization Engine (AVE 3d renderengine) */
export type ConfiguredCarAve = {
  __typename?: 'ConfiguredCarAve';
  /** special AVE string containig all prStrings in a special form */
  renderString?: Maybe<Scalars['String']['output']>;
};

/** Input type to uniquely identify a carline. */
export type ConfiguredCarByCarlineInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /** Unique, machine readable carline id, e.g. `a3sb` */
  carlineId: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
};

export type ConfiguredCarCarCode = CarCode & {
  __typename?: 'ConfiguredCarCarCode';
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `rescueInfo` instead. */
  repairInfo?: Maybe<CarCodeRepairInfo>;
  /**
   * the audicode rescue info, if present.
   * This field will only be populated if the ConfiguredCar was retrieved by the carByCode query.
   */
  rescueInfo: RescueInfo;
  urls?: Maybe<ConfiguredCarCarCodeUrls>;
};

export type ConfiguredCarCarCodeUrls = {
  __typename?: 'ConfiguredCarCarCodeUrls';
  entry?: Maybe<Scalars['String']['output']>;
  pdf?: Maybe<Scalars['String']['output']>;
};

/** The carline of the configured car */
export type ConfiguredCarCarline = CarlineBase & {
  __typename?: 'ConfiguredCarCarline';
  /** e.g. 'a4limo' */
  id: Scalars['String']['output'];
  /** e.g. 'A4 Limousine' */
  name: Scalars['String']['output'];
};

export type ConfiguredCarCatalog = {
  __typename?: 'ConfiguredCarCatalog';
  /** All features available for the configured car. */
  features: ConfiguredCarFeatures;
  /** All engine specific model entries of the catalog. */
  models?: Maybe<ConfiguredCarCatalogModels>;
  /** @deprecated ADR: prefer object return types over arrays. */
  packages?: Maybe<Array<ConfigurationPackage>>;
  /** All packages available for the configured car. A package may contain several features. */
  packagesDetails: ConfiguredCarPackages;
};

/** Image of a equipment option or package */
export type ConfiguredCarCatalogImage = {
  __typename?: 'ConfiguredCarCatalogImage';
  /** The height of the image */
  height?: Maybe<Scalars['Int']['output']>;
  /** The mimeType of the image */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** The sequence which has been provided by the backend systems for the image */
  sequence?: Maybe<Scalars['Int']['output']>;
  /** The url to the image */
  url?: Maybe<Scalars['String']['output']>;
  /** The width of the image */
  width?: Maybe<Scalars['Int']['output']>;
};

export type ConfiguredCarCatalogModel = {
  __typename?: 'ConfiguredCarCatalogModel';
  /** The driveName of this model. e.g. "Front-wheel drive" */
  driveName?: Maybe<Scalars['String']['output']>;
  /** EcoLabel available for BEVs in select markets like ES and IT, e.g. "ZERO" */
  ecoLabel?: Maybe<EcoLabel>;
  /** The engineName of this model. e.g. "35 TFSI" */
  engineName?: Maybe<Scalars['String']['output']>;
  /** The fullName of this model. e.g. "Sport 35 TFSI 6-speed" */
  fullName?: Maybe<Scalars['String']['output']>;
  /** The gearName of this model. e.g. "6-speed" */
  gearName?: Maybe<Scalars['String']['output']>;
  /** The gearType of this model. e.g. "Manual" */
  gearType?: Maybe<Scalars['String']['output']>;
  /** id of the model in the format "${audicode - 6 digits}${version - 1 digit}_${modelyear}". e.G. "8WCA7G0_2023" */
  id?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<ConfiguredCarModelIdentifier>;
  /** The price for the selected model */
  price?: Maybe<ConfiguredCarCatalogModelPrice>;
  /** The price for the selected model with additional fees. May not be available in every market */
  priceWithFees?: Maybe<ConfiguredCarCatalogModelPrice>;
  /** The status flags for this model */
  status?: Maybe<Status>;
  /** The trimline id of the model. e.g. "trimline_default" */
  trimlineId?: Maybe<Scalars['String']['output']>;
};

export type ConfiguredCarCatalogModelPrice = CarPrice & {
  __typename?: 'ConfiguredCarCatalogModelPrice';
  /** Details of the currency of the price */
  currencyDetails?: Maybe<Currency>;
  /** The formatted value as text. e.g. '39.100,00 EUR' */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** The value. e.g. 39100 */
  value: Scalars['Float']['output'];
  /** The value as text. e.g. '39.100,00' */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

/** Wrapper class for ConfiguredCarCatalogModel */
export type ConfiguredCarCatalogModels = {
  __typename?: 'ConfiguredCarCatalogModels';
  data?: Maybe<Array<Maybe<ConfiguredCarCatalogModel>>>;
};

/**
 * Contains data about a color of the car and hints about the brightness of overlapping texts
 * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
 */
export type ConfiguredCarColor = {
  __typename?: 'ConfiguredCarColor';
  /** The audi code of the color */
  code: Scalars['String']['output'];
  /** A hint about which color texts must have if they overlap the cars color. (possible values: 'LIGHT', 'DARK') */
  contrastThemeHint: ContrastThemeHint;
  /** The hex representation of the color. */
  hexColor: Scalars['String']['output'];
};

/** The type to present a conflict while changinging the configuration. */
export type ConfiguredCarConflict = {
  __typename?: 'ConfiguredCarConflict';
  /** Identifier to be used as input of the acceptConflictSolution query to resolve the conflict */
  id: ConflictConfiguredCarIdentifier;
  /** Identifier that represents the last valid configuration before the conflict occurred. It must be used as input of the acceptConflictSolution query to resolve the conflict */
  lastGoodConfiguredCarIdentifier: ConflictConfiguredCarIdentifier;
  /** how much does it cost to solve the conflict with the pre-selected solutions */
  price: Price;
  /** groups of solutions containing alternative features that should be added or removed to solve the conflict */
  solutionGroups: Array<SolutionGroups>;
  /** A reference to a feature or model that triggered the configuration conflict. It can be used to show the name and the prices of the item that triggered this conflict. */
  trigger: ConfiguredCarFeature;
  /** A reference to the original trigger. This value has to be supplied to conflict change and accept queries. */
  triggerId: Scalars['ID']['output'];
};

/** (PDF-) documents related to a configured car. */
export type ConfiguredCarDocuments = {
  __typename?: 'ConfiguredCarDocuments';
  /** Link to PDF-document with ENVKV Label */
  envkvLabelPdfUrl?: Maybe<Scalars['String']['output']>;
  /** Link to PDF-document with car-configuration-summary. */
  summaryPdfUrl?: Maybe<Scalars['String']['output']>;
};

/** Dyanmic part of a feature/package of a ConfiguredCar Catalog */
export type ConfiguredCarDynamicCatalogFeature = {
  __typename?: 'ConfiguredCarDynamicCatalogFeature';
  /**
   * Identifier
   * May have a counterpart in ConfiguredCarStaticFeature
   * e.g. 'MIRS4L2' (format may change, but is guaranteed to match the format in ConfiguredCarStaticFeature)
   */
  id: Scalars['ID']['output'];
  /** Price of the item */
  price?: Maybe<Price>;
  /** Status of the item */
  status: Status;
};

/** Contains media urls related to EEC (Energy Efficiency Certificate) labels. */
export type ConfiguredCarEecLabelImageUrls = {
  __typename?: 'ConfiguredCarEECLabelImageUrls';
  /** An url pointing to the original sized EEC label for this configuration. */
  eecLabelImageUrl?: Maybe<Scalars['String']['output']>;
  /**
   * An url pointing to a smaller sized EEC label for this configuration, if available.
   * E.g. SVG typed images do not need a smaller scaled version.
   */
  eecLabelImageUrlSmall?: Maybe<Scalars['String']['output']>;
};

export type ConfiguredCarFeature = ConfiguredCarFeatureInterface & {
  __typename?: 'ConfiguredCarFeature';
  /** List of attributes, with key and value */
  attributes?: Maybe<Array<ConfiguredCarFeatureAttribute>>;
  /**
   * Family of the package
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  family: CarFeatureFamily;
  /**
   * Feature group mapping
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  featureGroup?: Maybe<ConfiguredCarFeatureGroup>;
  /**
   * The type of the feature
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  featureType: ConfiguredCarFeatureType;
  financingParameters?: Maybe<FinancingParameters>;
  /**
   * The feature group of the package
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  group: CarFeatureGroup;
  /** All available images for this feature */
  imageSets?: Maybe<Array<TypedConfiguredCarCatalogImages>>;
  /** @deprecated Use imageSets instead */
  images?: Maybe<ConfiguredCarFeatureImage>;
  /**
   * List of feature ids in which the current feature is contained
   * @deprecated A feature can only be contained in a package. Use the ConfiguredCarCatalog.packagesDetails resolver if you would like to acccess package's features.
   */
  isContainedInFeatures: Array<Scalars['String']['output']>;
  /** Will be true if it is a stealth item and should be hidden from the user and only visable in debugg mode. */
  isStealth?: Maybe<Scalars['Boolean']['output']>;
  /** e.g. 'Innenspiegel abblendbar' */
  name: Scalars['String']['output'];
  /** e.g. '4L2' */
  pr3: Scalars['ID']['output'];
  /** e.g. 'MIRS4L2' */
  pr7: Scalars['ID']['output'];
  /**
   * The price of the feature
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  price?: Maybe<Price>;
  rate?: Maybe<PartRateResult>;
  /** The status of the feature */
  status: Status;
  /**
   * Additional categorization. Optional. (e.g. family: 'Sitze.Sitze', subFamily: 'sport')
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  subFamily?: Maybe<CarFeatureFamily>;
  /** Marketing text blocks */
  texts?: Maybe<ConfiguredCarFeatureText>;
  /**
   * Links to Labels and PDF infos with data if the feature is from type tyre. Feature group 'RIMS'
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  tyreLabels?: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  /**
   * This indicates the vendor of the feature. This field is only used for rims and will be null for other feature types.
   * For rims this option will always be set to AUDI except for when the rim is a Audi Sport rim, then it will be set to AUDI_SPORT.
   */
  vendor?: Maybe<ConfiguredCarFeatureVendor>;
  videos?: Maybe<ConfiguredCarFeatureVideo>;
};

export type ConfiguredCarFeatureImageSetsArgs = {
  filter?: InputMaybe<TypedConfiguredCarCatalogImageFilterInput>;
};

/** Feature attribute */
export type ConfiguredCarFeatureAttribute = {
  __typename?: 'ConfiguredCarFeatureAttribute';
  /** Feature attribute key */
  key: Scalars['String']['output'];
  /** Feature attribute value */
  value: Scalars['String']['output'];
};

/** Possible groups or categories to which a catalog feature could belong to */
export enum ConfiguredCarFeatureGroup {
  Accessories = 'ACCESSORIES',
  ExclusiveExteriorColors = 'EXCLUSIVE_EXTERIOR_COLORS',
  ExteriorColors = 'EXTERIOR_COLORS',
  InteriorTiles = 'INTERIOR_TILES',
  Lights = 'LIGHTS',
  Rims = 'RIMS',
  Seats = 'SEATS',
  SteeringWheels = 'STEERING_WHEELS',
}

/**
 * Defines image types.
 *
 * Currently, all fields correspond to VSAPI image types. It is planned to replace them with more generic fields like
 * `xl` instead of `bigImage`.
 */
export type ConfiguredCarFeatureImage = CarFeatureImage & {
  __typename?: 'ConfiguredCarFeatureImage';
  /** Size - width: 140, height: 63 */
  ak4DetailsL?: Maybe<ImageSource>;
  /** Size - width: 79, height: 35 */
  ak4DetailsM?: Maybe<ImageSource>;
  /** Size - width: 59, height: 26 */
  ak4DetailsS?: Maybe<ImageSource>;
  /** Size - width: 159, height: 71 */
  ak4DetailsXL?: Maybe<ImageSource>;
  /** Size - width: 20, height: 24 */
  ak4FoldingTile?: Maybe<ImageSource>;
  /** Size - width: 40, height: 36 */
  ak4InnerTile?: Maybe<ImageSource>;
  /** Size - width: 39, height: 24 */
  ak4OuterTile?: Maybe<ImageSource>;
  /** Size - width: 259, height: 120 */
  akMotivL?: Maybe<ImageSource>;
  /** Size - width: 119, height: 47 */
  akMotivM?: Maybe<ImageSource>;
  /** Size - width: 59, height: 23 */
  akMotivS?: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  bigImage?: Maybe<ImageSource>;
  /** Size - width: 145, height: 80 */
  contentS?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  decoTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  decoTileHD?: Maybe<ImageSource>;
  /** Size - width: 720, height: 1280 */
  decoTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  decoTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageFrontHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageFrontL?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageSideHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageSideL?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageTopHD?: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageTopL?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  foldingTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  foldingTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  foldingTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  foldingTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImage34HD?: Maybe<ImageSource>;
  /** Size - width: 601, height: 1400 */
  frontViewImage34L?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  frontViewImage34M?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImage34S?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImage34XS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  frontViewImageL?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImageXS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  headlightImage?: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  headlightImageS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageSequenceEntryHD?: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  imageSequenceEntryS?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  inlayTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  inlayTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  inlayTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  inlayTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  innerTileHD?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  interiorTileButton?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  interiorTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  interiorTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 2048, height: 2048 */
  interiorTileSquareL?: Maybe<ImageSource>;
  /** Size - width: 159, height: 74 */
  nwbamMotiv?: Maybe<ImageSource>;
  /** Size - width: 79, height: 47 */
  nwbsMotiv?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  outerTileButton?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  outerTileHD?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  outerTileSmallHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  outerTileSquareHD?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  seatImage?: Maybe<ImageSource>;
  /** Size - width: 190, height: 250 */
  seatImageS?: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD?: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL?: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM?: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS?: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS?: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  smallImageHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  steeringWheelImage?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  steeringWheelImageS?: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  teaserImage?: Maybe<ImageSource>;
  /** Size - width: 249, height: 140 */
  thumbnailHD?: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  wheelImage?: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  wheelImageS?: Maybe<ImageSource>;
};

/** Data of a single feature of a configured car */
export type ConfiguredCarFeatureInterface = {
  /** List of attributes, with key and value */
  attributes?: Maybe<Array<ConfiguredCarFeatureAttribute>>;
  /** The family of a feature */
  family: CarFeatureFamily;
  /** Feature group mapping */
  featureGroup?: Maybe<ConfiguredCarFeatureGroup>;
  /** The type of the feature */
  featureType: ConfiguredCarFeatureType;
  financingParameters?: Maybe<FinancingParameters>;
  /** The feature group of the feature */
  group: CarFeatureGroup;
  images?: Maybe<ConfiguredCarFeatureImage>;
  /**
   * List of feature ids in which the current feature is contained
   * @deprecated A feature can only be contained in a package. Use the ConfiguredCarCatalog.packagesDetails resolver if you would like to acccess package's features.
   */
  isContainedInFeatures: Array<Scalars['String']['output']>;
  /** E.g. 'Auto-dimming rearview mirror' */
  name: Scalars['String']['output'];
  /** E.g. '4L2' */
  pr3: Scalars['ID']['output'];
  /** E.g. 'MIRS4L2' */
  pr7: Scalars['ID']['output'];
  /** The price of the feature */
  price?: Maybe<Price>;
  /** The status of the feature */
  status: Status;
  /** Additional categorization. Optional. (e.g. family: 'Sitze.Sitze', subFamily: 'sport') */
  subFamily?: Maybe<CarFeatureFamily>;
  texts?: Maybe<ConfiguredCarFeatureText>;
  /** Links to Labels and PDF infos with data if the feature is from type tyre. Only available for feature group 'RIMS'. */
  tyreLabels?: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  videos?: Maybe<ConfiguredCarFeatureVideo>;
};

/** configuration states to the corresponding bitflags from the DPU (00000=> */
export enum ConfiguredCarFeatureState {
  Available = 'available',
  Changeable = 'changeable',
  InConflict = 'inConflict',
  Selected = 'selected',
  Standard = 'standard',
}

/**
 * Defines text types.
 *
 * Currently, all fields correspond to VSAPI text types. It is planned to replace them with more generic fields like
 * `headline` instead of `akHeadline`.
 */
export type ConfiguredCarFeatureText = CarFeatureText & {
  __typename?: 'ConfiguredCarFeatureText';
  akHeadline?: Maybe<Scalars['String']['output']>;
  akRemarks?: Maybe<Scalars['String']['output']>;
  akText?: Maybe<Scalars['String']['output']>;
  benefits?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  carpetText?: Maybe<Scalars['String']['output']>;
  carpetTitle?: Maybe<Scalars['String']['output']>;
  dashboardText?: Maybe<Scalars['String']['output']>;
  dashboardTitle?: Maybe<Scalars['String']['output']>;
  engineDescription?: Maybe<Scalars['String']['output']>;
  engineGear?: Maybe<Scalars['String']['output']>;
  engineName?: Maybe<Scalars['String']['output']>;
  engineNamesText?: Maybe<Scalars['String']['output']>;
  exteriorColorText?: Maybe<Scalars['String']['output']>;
  exteriorColorTitle?: Maybe<Scalars['String']['output']>;
  exteriorContrastColorText?: Maybe<Scalars['String']['output']>;
  exteriorContrastColorTitle?: Maybe<Scalars['String']['output']>;
  headliningText?: Maybe<Scalars['String']['output']>;
  headliningTitle?: Maybe<Scalars['String']['output']>;
  intro?: Maybe<Scalars['String']['output']>;
  seatsText?: Maybe<Scalars['String']['output']>;
  seatsTitle?: Maybe<Scalars['String']['output']>;
  teaserText?: Maybe<Scalars['String']['output']>;
};

/** Any possible kind type which a feature can get */
export enum ConfiguredCarFeatureType {
  Accessory = 'ACCESSORY',
  BodyColor = 'BODY_COLOR',
  Equipment = 'EQUIPMENT',
  EquipmentContent = 'EQUIPMENT_CONTENT',
  ExclusiveExteriorColor = 'EXCLUSIVE_EXTERIOR_COLOR',
  ExteriorColor = 'EXTERIOR_COLOR',
  InteriorTile = 'INTERIOR_TILE',
  Model = 'MODEL',
  RawInteriorColor = 'RAW_INTERIOR_COLOR',
  RoofColor = 'ROOF_COLOR',
  Stealth = 'STEALTH',
  Trimline = 'TRIMLINE',
}

/** Links to some additional information data for tyre features */
export type ConfiguredCarFeatureTyreLabel = {
  __typename?: 'ConfiguredCarFeatureTyreLabel';
  /** Link to a product information sheet pdf */
  productSheetUrl?: Maybe<Scalars['String']['output']>;
  /** Link to an image with environmental informations for the tyre */
  tyreLabelUrl?: Maybe<Scalars['String']['output']>;
};

/** Shows who the vendor of the feature is */
export enum ConfiguredCarFeatureVendor {
  Audi = 'AUDI',
  AudiSport = 'AUDI_SPORT',
}

/**
 * Defines video types.
 *
 * Currently, all fields correspond to VSAPI video types. It is planned to replace them with more generic fields like
 * `mp4XL` instead of `bigVideoMp4`.
 */
export type ConfiguredCarFeatureVideo = CarFeatureVideo & {
  __typename?: 'ConfiguredCarFeatureVideo';
  /** Size - width: 888, height: 500 */
  bigVideoMp4?: Maybe<VideoSource>;
  /** Size - width: 704, height: 396 */
  videoMp4?: Maybe<VideoSource>;
  /** Size - width: 888, height: 500 */
  videoThumb?: Maybe<VideoSource>;
};

export type ConfiguredCarFeatures = {
  __typename?: 'ConfiguredCarFeatures';
  data: Array<ConfiguredCarFeature>;
};

export type ConfiguredCarFeaturesDataArgs = {
  featuresFilterInput?: InputMaybe<FeaturesFilterInput>;
  financingInput?: InputMaybe<FinancingInput>;
};

/** Identifier to query a configured car */
export type ConfiguredCarIdentifier = {
  __typename?: 'ConfiguredCarIdentifier';
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /** 3-digit pr numbers */
  equipmentOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: Maybe<Scalars['String']['output']>;
  /** 2-digit pr number of the interior color */
  interiorColor?: Maybe<Scalars['String']['output']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  model: ConfiguredCarModelIdentifier;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
};

/**
 * Object representation of a prString
 *
 * MBV prString:
 *
 * 51901\\2021|GENBAE\\1|F14 S1S1|F56     FZ
 * -----       ------        ----
 * |      ---- |       -     |            --
 * |      |    |       |     |            |
 * |      |    |       |     |            interiorColor
 * |      |    |       |     |
 * |      |    |       |     exteriorColor
 * |      |    |       |
 * |      |    |       modelVersion
 * |      |    |
 * |      |    modelCode
 * |      |
 * |      modelYear
 * |
 * salesgroup
 *
 * NEMO prString:
 *
 * GENBAE1_2021|S1S1|FZ
 * ------  ----      --
 * |     - |    ---- |
 * |     | |    |    |
 * |     | |    |    interiorColor
 * |     | |    |
 * |     | |    exteriorColor
 * |     | |
 * |     | modelYear
 * |     |
 * |     modelVersion
 * |
 * modelCode
 */
export type ConfiguredCarInput = {
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /**
   * TODO: Combine equipmentOptions, exteriorColor, interiorColor in a separate input???
   * 7-digit pr numbers
   */
  equipmentOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: InputMaybe<Scalars['String']['input']>;
  /** 2-digit pr number of the interior color */
  interiorColor?: InputMaybe<Scalars['String']['input']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
  /** the model input separte in an own input */
  model: ConfiguredCarModelInput;
  /**
   * 5-digit sales group
   * TODO: for now, we use string because of leading zeros.
   */
  salesGroup?: InputMaybe<Scalars['String']['input']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfiguredCarMedia =
  | ConfiguredCarFeatureImage
  | ConfiguredCarFeatureText
  | ConfiguredCarFeatureVideo;

/** All available media resources for a configured car */
export type ConfiguredCarMediaResources = {
  __typename?: 'ConfiguredCarMediaResources';
  ave?: Maybe<ConfiguredCarAve>;
  /** (PDF-) documents related to the configured car. */
  documents?: Maybe<ConfiguredCarDocuments>;
  /** Contains media urls related to EEC labels. */
  eec?: Maybe<ConfiguredCarEecLabelImageUrls>;
  /**
   * Images related to the configured car.
   *
   * Provide either "width" or "resolution". Providing nothing will fallback to providing the default resolution of the image. Providing both will ignore the width parameter.
   * Providing width only will always result in 4:3 images
   */
  images: Array<RenderedCarImage>;
  /** @deprecated use `images` */
  renderImages?: Maybe<ConfiguredCarRenderImages>;
};

/** All available media resources for a configured car */
export type ConfiguredCarMediaResourcesImagesArgs = {
  client?: InputMaybe<Scalars['String']['input']>;
  convertibleRoof?: InputMaybe<RenderedImagesConvertibleRoof>;
  deviceType?: InputMaybe<RenderedImagesDeviceType>;
  filterInput?: InputMaybe<RenderedCarImagesFilterInput>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  resolution?: InputMaybe<Array<InputImageResolution>>;
  width?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ConfiguredCarModelBase = CarModelBase & {
  __typename?: 'ConfiguredCarModelBase';
  id?: Maybe<ConfiguredCarModelIdentifier>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Model identifier */
export type ConfiguredCarModelIdentifier = CarModelIdentifier & {
  __typename?: 'ConfiguredCarModelIdentifier';
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. 8WCAYG */
  code: Scalars['String']['output'];
  /** 3-digit pr numbers */
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * 7-digit pr numbers
   * The PrNumbers in this field are identical to the ones in the `extensions` field but in PR7 instead of PR3 format
   * @deprecated Use extensions together with a header 'x-feature-flag: pr7-extensions#on'
   */
  extensionsPR7?: Maybe<Array<Scalars['String']['output']>>;
  /** 1-digit model version */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release */
  year: Scalars['Int']['output'];
};

export type ConfiguredCarModelInput = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars['String']['input'];
  /** 3-digit pr numbers */
  extensions?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * 7-digit pr numbers
   * @deprecated Use extensions and just give pr7 identifiers
   */
  extensionsPR7?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 1-digit model version */
  version: Scalars['Int']['input'];
  /** The year of the catalogue release/models release */
  year: Scalars['Int']['input'];
};

export type ConfiguredCarPackage = ConfiguredCarFeatureInterface & {
  __typename?: 'ConfiguredCarPackage';
  /** List of attributes, with key and value */
  attributes?: Maybe<Array<ConfiguredCarFeatureAttribute>>;
  /** All items which are part of this package */
  content?: Maybe<Array<ConfiguredCarPackageFeature>>;
  /**
   * Family of the package
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  family: CarFeatureFamily;
  /**
   * Feature group mapping
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  featureGroup?: Maybe<ConfiguredCarFeatureGroup>;
  /**
   * The type of the feature
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  featureType: ConfiguredCarFeatureType;
  /**
   * Features contained in the package
   * @deprecated Use content instead
   */
  features: ConfiguredCarFeatures;
  financingParameters?: Maybe<FinancingParameters>;
  /**
   * The feature group of the package
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  group: CarFeatureGroup;
  /** All available images for this package */
  imageSets?: Maybe<Array<TypedConfiguredCarCatalogImages>>;
  /** @deprecated Use imageSets instead */
  images?: Maybe<ConfiguredCarFeatureImage>;
  /**
   * List of feature ids in which the current feature is contained
   * @deprecated A feature can only be contained in a package. Use the ConfiguredCarCatalog.packagesDetails resolver if you would like to acccess package's features.
   */
  isContainedInFeatures: Array<Scalars['String']['output']>;
  /** Will be true if it is a stealth item and should be hidden from the user and only visable in debugg mode. */
  isStealth?: Maybe<Scalars['Boolean']['output']>;
  /** e.g. 'Comfort & Sound pack' */
  name: Scalars['String']['output'];
  /** e.g. 'PG2' */
  pr3: Scalars['ID']['output'];
  /** e.g. 'GPG2PG2' */
  pr7: Scalars['ID']['output'];
  /**
   * Price for this feature
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  price?: Maybe<Price>;
  rate?: Maybe<PartRateResult>;
  /** Status flags of the package */
  status: Status;
  /**
   * Additional categorization. Optional. (e.g. family: 'Sitze.Sitze', subFamily: 'sport')
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  subFamily?: Maybe<CarFeatureFamily>;
  /** Marketing text blocks */
  texts?: Maybe<ConfiguredCarFeatureText>;
  /**
   * Links to Labels and PDF infos with data if the feature is from type tyre. Only available for feature group 'RIMS'.
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  tyreLabels?: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  videos?: Maybe<ConfiguredCarFeatureVideo>;
};

export type ConfiguredCarPackageImageSetsArgs = {
  filter?: InputMaybe<TypedConfiguredCarCatalogImageFilterInput>;
};

export type ConfiguredCarPackageFeature = {
  __typename?: 'ConfiguredCarPackageFeature';
  /** This is the id of the item which is part of the package. This is not necessarily the pr7 number - e.g. '6I6_in_WPX' */
  contentId: Scalars['String']['output'];
  images?: Maybe<ConfiguredCarFeatureImage>;
  /** e.g. 'Audi active lane assist' */
  name: Scalars['String']['output'];
  /** e.g. '6I6' */
  pr3: Scalars['ID']['output'];
  /** The status of the feature */
  status: Status;
  /** Marketing text blocks */
  texts?: Maybe<ConfiguredCarFeatureText>;
};

export type ConfiguredCarPackages = {
  __typename?: 'ConfiguredCarPackages';
  data?: Maybe<Array<ConfiguredCarPackage>>;
};

export type ConfiguredCarPackagesDataArgs = {
  featuresFilterInput?: InputMaybe<FeaturesFilterInput>;
  financingInput?: InputMaybe<FinancingInput>;
};

export type ConfiguredCarPrices = CarPrices & {
  __typename?: 'ConfiguredCarPrices';
  /**
   * special taxes (Canada market only)
   * @deprecated Use priceParts instead
   */
  airConditioningLevy?: Maybe<CarPrice>;
  /**
   * bpm (first-time-registration) tax (Belastingdienst - NL only)
   * @deprecated Use priceParts instead
   */
  bpm?: Maybe<CarPrice>;
  /**
   * lowest price of the configured car´s carline
   * @deprecated Use priceParts instead
   */
  carlineBase?: Maybe<CarPrice>;
  /**
   * delivery cost excluded from total
   * @deprecated Use priceParts instead
   */
  charges?: Maybe<CarPrice>;
  /**
   * special fee (Canada market only)
   * @deprecated Use priceParts instead
   */
  dealerAdminFee?: Maybe<CarPrice>;
  /**
   * special fee (Canada market only)
   * @deprecated Use priceParts instead
   */
  estimatedMotorVehicleCounselFee?: Maybe<CarPrice>;
  /**
   * special taxes (Canada market only)
   * @deprecated Use priceParts instead
   */
  estimatedTireLevy?: Maybe<CarPrice>;
  /**
   * total price excluding options which are not financeable
   * @deprecated Use priceParts instead
   */
  financeableTotal?: Maybe<CarPrice>;
  financingParameters?: Maybe<FinancingParameters>;
  /**
   * special taxes (Canada market only)
   * @deprecated Use priceParts instead
   */
  greenLevy?: Maybe<CarPrice>;
  /**
   * special taxes (Canada market only)
   * @deprecated Use priceParts instead
   */
  luxuryTax?: Maybe<CarPrice>;
  /**
   * base price of model without extra options
   * @deprecated Use priceParts instead
   */
  model?: Maybe<CarPrice>;
  monthlyRate?: Maybe<MonthlyRateResult>;
  /**
   * netto price of the model (FI only)
   * @deprecated Use priceParts instead
   */
  nettoBase?: Maybe<CarPrice>;
  /**
   * netto price of the model´s options (FI only)
   * @deprecated Use priceParts instead
   */
  nettoOptions?: Maybe<CarPrice>;
  /**
   * total price excluding financeable total
   * @deprecated Use priceParts instead
   */
  nonFinanceableTotal?: Maybe<CarPrice>;
  /**
   * prices of all options contained in the configured car
   * @deprecated Use priceParts instead
   */
  options?: Maybe<CarPrice>;
  /** creation date for the delivered prices */
  priceDate?: Maybe<PriceDate>;
  /** this array contains all available price parts of this configured car for this market */
  priceParts: Array<TypedPrice>;
  /**
   * On The Road Price (UK market), without additional Servicepackgages (= total + otrOffset)
   * @deprecated Use priceParts instead
   */
  rotr?: Maybe<CarPrice>;
  /**
   * On The Road Price offset (UK market)
   * @deprecated Use priceParts instead
   */
  rotrRate?: Maybe<CarPrice>;
  /**
   * price for selected service packages
   * @deprecated Use priceParts instead
   */
  servicePackage?: Maybe<CarPrice>;
  /**
   * total tax (FI and NO markets)
   * @deprecated Use priceParts instead
   */
  taxTotal?: Maybe<CarPrice>;
  /**
   * total tax depending on CO2 consumptions (NO market only)
   * @deprecated Use priceParts instead
   */
  taxTotalCO2?: Maybe<CarPrice>;
  /**
   * total tax price excluding  scrap fee (NO market only)
   * @deprecated Use priceParts instead
   */
  taxTotalExclScrapFree?: Maybe<CarPrice>;
  /**
   * total tax depending on NO2 consumptions (NO market only)
   * @deprecated Use priceParts instead
   */
  taxTotalNOx?: Maybe<CarPrice>;
  /**
   * total tax depending on weight (NO market only)
   * @deprecated Use priceParts instead
   */
  taxTotalWeight?: Maybe<CarPrice>;
  /**
   * taxable benefits (FI only)
   * @deprecated Use priceParts instead
   */
  taxableBenefit1?: Maybe<CarPrice>;
  /**
   * taxable benefits (FI only)
   * @deprecated Use priceParts instead
   */
  taxableBenefit2?: Maybe<CarPrice>;
  /**
   * total price of the configured car
   * @deprecated Use priceParts instead
   */
  total?: Maybe<CarPrice>;
  /**
   * total price with additional charges
   * @deprecated Use priceParts instead
   */
  totalWithCharges?: Maybe<CarPrice>;
  /**
   * value added taxes (vat) for this model
   * @deprecated Use priceParts instead
   */
  vat?: Maybe<CarPrice>;
};

/**
 * Render images for a configured car
 *
 * mapping for dpu based render images
 * n2c01: ["exterior_front", "layerstage.new"],
 * n2c02: ["closeup_lights", "layerstage.new"],
 * n2c12: ["exterior_back", "layerstage.new"],
 * n2c14: ["exterior", "layerstage.new"],
 * n2c15: ["closeup_wheels", "layerstage.new"],
 * n2n05: ["interior", "layerstage.new"],
 * n2n06: ["dashboard", "layerstage.new"],
 * n2n10: ["rearseat", "layerstage.new"],
 * n3c01: ["exterior_front", "stage.new"],
 * n3c02: ["closeup_lights", "stage.new"],
 * n3c12: ["exterior_back", "stage.new"],
 * n3c14: ["exterior", "stage.new"],
 * n3c15: ["closeup_wheels", "stage.new"],
 * n3n05: ["interior", "stage.new"],
 * n3n06: ["dashboard", "stage.new"],
 * n3n10: ["rearseat", "stage.new"],
 * n4c01: ["exterior_front", "fullscreen.new"],
 * n4c02: ["closeup_lights", "fullscreen.new"],
 * n4c12: ["exterior_back", "fullscreen.new"],
 * n4c14: ["exterior", "fullscreen.new"],
 * n4c15: ["closeup_wheels", "fullscreen.new"],
 * n4n05: ["interior", "fullscreen.new"],
 * n4n06: ["parent.dashboard", "fullscreen.new"],
 * n4n10: ["rearseat", "fullscreen.new"],
 * n5c01: ["exterior_front", "carstore.new"],
 * n8c01: ["exterior_front", "compare.new"],
 * n9c14: ["exterior", "modelfinder.new"],
 * nmfc: ["exterior", "flyout"],
 */
export type ConfiguredCarRenderImages = {
  __typename?: 'ConfiguredCarRenderImages';
  n2c01?: Maybe<Scalars['String']['output']>;
  n2c02?: Maybe<Scalars['String']['output']>;
  n2c12?: Maybe<Scalars['String']['output']>;
  n2c14?: Maybe<Scalars['String']['output']>;
  n2c15?: Maybe<Scalars['String']['output']>;
  n2n05?: Maybe<Scalars['String']['output']>;
  n2n06?: Maybe<Scalars['String']['output']>;
  n2n10?: Maybe<Scalars['String']['output']>;
  n3c01?: Maybe<Scalars['String']['output']>;
  n3c02?: Maybe<Scalars['String']['output']>;
  n3c12?: Maybe<Scalars['String']['output']>;
  n3c14?: Maybe<Scalars['String']['output']>;
  n3c15?: Maybe<Scalars['String']['output']>;
  n3n05?: Maybe<Scalars['String']['output']>;
  n3n06?: Maybe<Scalars['String']['output']>;
  n3n10?: Maybe<Scalars['String']['output']>;
  n4c01?: Maybe<Scalars['String']['output']>;
  n4c02?: Maybe<Scalars['String']['output']>;
  n4c12?: Maybe<Scalars['String']['output']>;
  n4c14?: Maybe<Scalars['String']['output']>;
  n4c15?: Maybe<Scalars['String']['output']>;
  n4n05?: Maybe<Scalars['String']['output']>;
  n4n06?: Maybe<Scalars['String']['output']>;
  n4n10?: Maybe<Scalars['String']['output']>;
  n5c01?: Maybe<Scalars['String']['output']>;
  n8c01?: Maybe<Scalars['String']['output']>;
  n9c14?: Maybe<Scalars['String']['output']>;
  nmfc?: Maybe<Scalars['String']['output']>;
};

/** Static part of a feature/package of a ConfiguredCar Catalog */
export type ConfiguredCarStaticFeature = ConfiguredCarStaticFeatureBase & {
  __typename?: 'ConfiguredCarStaticFeature';
  /** List of attributes, with key and value */
  attributes: Array<KeyValueString>;
  /** Benefits texts */
  benefits: Array<Scalars['String']['output']>;
  /** Family of the package */
  family?: Maybe<CarFeatureFamily>;
  /** The feature group of the package */
  group?: Maybe<CarFeatureGroup>;
  /**
   * Identifier
   * May have a counterpart in ConfiguredCarStaticFeature
   * e.g. 'MIRS4L2' (format may change, but is guaranteed to match the format in ConfiguredCarStaticFeature)
   */
  id: Scalars['ID']['output'];
  /** All available media sets for this feature. */
  mediaSets: Array<TypedConfiguredCarCatalogImages>;
  /**
   * Name of the option.
   * May be replaced by text key "ak_headline" for features that are part of packages.
   * e.g. 'Innenspiegel abblendbar', 'Räder Audi Sport, 5-Arm-Aero-Struktur, neodymgold matt, 9,5Jx21, Reifen 265/45 R21 (enthalten in Exterieur advanced plus)'
   */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Identifier in pr3 format
   * e.g. '4L2', 'Q2J_in_PS8'
   */
  pr3?: Maybe<Scalars['String']['output']>;
  /**
   * Identifier in pr7 format
   * e.g. 'MIRS4L2', 'MVOSQ2J-in-GPS8PS8'
   */
  pr7?: Maybe<Scalars['String']['output']>;
  /** Marketing text blocks */
  texts: Array<KeyValueString>;
  /** The type of the feature */
  type?: Maybe<ConfiguredCarStaticFeatureKind>;
};

/** Static part of a feature/package of a ConfiguredCar Catalog */
export type ConfiguredCarStaticFeatureBase = {
  /** List of attributes, with key and value */
  attributes: Array<KeyValueString>;
  /** Benefits texts */
  benefits: Array<Scalars['String']['output']>;
  /** Family of the package */
  family?: Maybe<CarFeatureFamily>;
  /** The feature group of the package */
  group?: Maybe<CarFeatureGroup>;
  /**
   * Identifier
   * May have a counterpart in ConfiguredCarStaticFeature
   * e.g. 'MIRS4L2' (format may change, but is guaranteed to match the format in ConfiguredCarStaticFeature)
   */
  id: Scalars['ID']['output'];
  /** All available media sets for this feature. */
  mediaSets: Array<TypedConfiguredCarCatalogImages>;
  /**
   * Name of the option.
   * May be replaced by text key "ak_headline" for features that are part of packages.
   * e.g. 'Innenspiegel abblendbar', 'Räder Audi Sport, 5-Arm-Aero-Struktur, neodymgold matt, 9,5Jx21, Reifen 265/45 R21 (enthalten in Exterieur advanced plus)'
   */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Identifier in pr3 format
   * e.g. '4L2', 'Q2J_in_PS8'
   */
  pr3?: Maybe<Scalars['String']['output']>;
  /**
   * Identifier in pr7 format
   * e.g. 'MIRS4L2', 'MVOSQ2J-in-GPS8PS8'
   */
  pr7?: Maybe<Scalars['String']['output']>;
  /** Marketing text blocks */
  texts: Array<KeyValueString>;
  /** The type of the feature */
  type?: Maybe<ConfiguredCarStaticFeatureKind>;
};

/** Type of a static feature */
export enum ConfiguredCarStaticFeatureKind {
  /** Exclusive exterior color */
  ExclusivExteriorColor = 'EXCLUSIV_EXTERIOR_COLOR',
  /** Exterior color */
  ExteriorColor = 'EXTERIOR_COLOR',
  /** Interior color */
  InteriorColor = 'INTERIOR_COLOR',
  /** Option */
  Option = 'OPTION',
  /** Standard */
  Standard = 'STANDARD',
}

/**
 * Feature of type Package
 * Static part of a feature/package of a ConfiguredCar Catalog.
 */
export type ConfiguredCarStaticFeaturePackage = ConfiguredCarStaticFeatureBase & {
  __typename?: 'ConfiguredCarStaticFeaturePackage';
  /** List of attributes, with key and value */
  attributes: Array<KeyValueString>;
  /** Benefits texts */
  benefits: Array<Scalars['String']['output']>;
  /** All items which are part of this package */
  content: Array<ConfiguredCarStaticFeaturePackageContent>;
  /** Family of the package */
  family?: Maybe<CarFeatureFamily>;
  /** The feature group of the package */
  group?: Maybe<CarFeatureGroup>;
  /**
   * Identifier
   * May have a counterpart in ConfiguredCarStaticFeature
   * e.g. 'MIRS4L2' (format may change, but is guaranteed to match the format in ConfiguredCarStaticFeature)
   */
  id: Scalars['ID']['output'];
  /** All available media sets for this feature. */
  mediaSets: Array<TypedConfiguredCarCatalogImages>;
  /** Name of the option. */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Identifier in pr3 format
   * e.g. '4L2', 'Q2J_in_PS8'
   */
  pr3?: Maybe<Scalars['String']['output']>;
  /**
   * Identifier in pr7 format
   * e.g. 'MIRS4L2', 'MVOSQ2J-in-GPS8PS8'
   */
  pr7?: Maybe<Scalars['String']['output']>;
  /** Marketing text blocks */
  texts: Array<KeyValueString>;
  /** The type of the feature */
  type?: Maybe<ConfiguredCarStaticFeatureKind>;
};

/**
 * Content of a Package in Static Feature Catalog
 *
 * Static part of a feature/package of a ConfiguredCar Catalog.
 */
export type ConfiguredCarStaticFeaturePackageContent = {
  __typename?: 'ConfiguredCarStaticFeaturePackageContent';
  /** This is the id of the item which is part of the package. This is not necessarily the pr7 number - e.g. '6I6_in_WPX' */
  contentId?: Maybe<Scalars['String']['output']>;
  /**
   * Identifier
   * May have a counterpart in ConfiguredCarStaticFeature
   * e.g. 'MIRS4L2' (format may change, but is guaranteed to match the format in ConfiguredCarStaticFeature)
   */
  id: Scalars['ID']['output'];
  /** All available media sets for this feature. */
  mediaSets: Array<TypedConfiguredCarCatalogImages>;
  /**
   * Name of the contained feature
   * e.g. 'Audi active lane assist'
   */
  name?: Maybe<Scalars['String']['output']>;
  /** e.g. '6I6' */
  pr3?: Maybe<Scalars['String']['output']>;
  /** Marketing text blocks */
  texts: Array<KeyValueString>;
};

/** TechnicalData for configurations. */
export type ConfiguredCarTechnicalData = {
  __typename?: 'ConfiguredCarTechnicalData';
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity.
   * (https://auto.schema.org/accelerationTime)
   * f.ex.: 9,5 s
   * TODO: question: How to handle the name: Beschleunigung 0-100 km/
   */
  accelerationTime?: Maybe<TechnicalDataFloatItem>;
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity when using launch control.
   * For example: 5.9 s
   */
  accelerationTimeLaunchControl?: Maybe<TechnicalDataFloatItem>;
  /**
   * Indicates the design and body style of the vehicle (e.g. station wagon, hatchback, etc.).
   * (https://auto.schema.org/bodyType)
   */
  bodyType?: Maybe<TechnicalDataTextItem>;
  /**
   * Brakes og the vehicle
   * f.ex. "Diagonal-2-Kreis Bremssystem mit ESC/ABS/EBV, Bremskraftverstärker, hydraulischer Bremsassistent; vorn: Faustsattel, Bremsscheibe innenbelüftet"
   */
  brakes?: Maybe<TechnicalDataTextItem>;
  /**
   * The available volume for cargo or luggage. For automobiles, this is usually the trunk volume.
   * https://schema.org/cargoVolume
   */
  cargoVolume?: Maybe<TechnicalDataRangeItem>;
  /**
   * Clutch of the vehicle
   * f.ex.: Doppelkupplung
   */
  clutch?: Maybe<TechnicalDataTextItem>;
  /** Consumptions and emissions. */
  consumptionAndEmission?: Maybe<TechnicalDataConsumptionAndEmission>;
  /**
   * Energy Efficiency Certificate.
   * @deprecated use envkv.eec instead
   */
  eec?: Maybe<TechnicalDataTextItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * f.ex. 284,0 - 341,0 km
   * Deprecated: --> engine.electricRangeCombined
   * @deprecated No longer supported
   */
  electricRangeCombined?: Maybe<TechnicalDataRangeItem>;
  /**
   * Array of the engines for this vehicle. Minimal one entry
   * (https://auto.schema.org/EngineSpecification)
   */
  engine: TechnicalDataEngineBase;
  /** Energieverbrauchskennzeichnungsverordnung (Energy consumption labeling regulation) properties */
  envkv?: Maybe<TechnicalDataEnvkv>;
  /** A list of additional technical data fields that are not part of the schema due to being market specific. */
  extendedTechnicalData?: Maybe<Array<TechnicalDataExtendedField>>;
  /**
   * Front axle of the vehicle
   * f.ex. "McPherson-Federbeinachse vorne"
   */
  frontAxle?: Maybe<TechnicalDataTextItem>;
  /**
   * Gearbox of the vehicle
   * f.ex.: 7-Gang S tronic
   */
  gearbox?: Maybe<TechnicalDataTextItem>;
  /** Class of several insurance related attributes */
  insuranceClass?: Maybe<TechnicalDataInsuranceClass>;
  /**
   * Indicates that the vehicle meets the respective emission standard.
   * https://schema.org/meetsEmissionStandard
   * f.ex. Euro 6d-TEMP
   */
  meetsEmissionStandard?: Maybe<TechnicalDataTextItem>;
  /** Contains basic model information to classify a vehicle. */
  modelAttributes?: Maybe<ModelAttributes>;
  /** Noise level of the vehicle while driving. Example: 69 dB(A) */
  noiseLevelDriving?: Maybe<TechnicalDataTextItem>;
  /**
   * Rear axle of the vehicle
   * f.ex. "Verbundlenker-Hinterachse "
   */
  rearAxle?: Maybe<TechnicalDataTextItem>;
  /**
   * Rims of the vehicle
   * f.ex. "Räder, 5-Arm-Design, 6 J x 15, Reifen 185/65 R 15"
   */
  rims?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of cargo and installations (e.g. a roof rack) on top of the vehicle.
   * https://auto.schema.org/roofLoad
   */
  roofLoad?: Maybe<TechnicalDataFloatItem>;
  /**
   * The speed range of the vehicle. If the vehicle is powered by an engine, the upper limit of the speed range (indicated by maxValue should be the maximum speed achievable under regular conditions.
   * (https://auto.schema.org/speed)
   * f.ex. 250 km/h
   */
  speed?: Maybe<TechnicalDataFloatItem>;
  /**
   * Steering system of the vehicle
   * f.ex. "Elektromechanische Lenkung mit geschwindigkeitsabhängiger Servounterstützung; Wendekreis ca. 10,6 m"
   */
  steeringSystem?: Maybe<TechnicalDataTextItem>;
  /** Debug Value */
  tenant?: Maybe<Scalars['String']['output']>;
  /**
   * The permitted vertical load (TWR) of a trailer attached to the vehicle. Also referred to as Tongue Load Rating (TLR) or Vertical Load Rating (VLR)
   * https://auto.schema.org/tongueWeight
   */
  tongueWeight?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 8 percent.
   * f.ex.: 2700 kg
   */
  trailerWeight8?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 12 percent.
   * f.ex. 2800 kg / in Verbindung mit adaptive air suspension oder adaptive air suspension sport 3500
   */
  trailerWeight12?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight for trailers without separate brakes.
   * f.ex.: 750 kg
   */
  trailerWeightUnbraked?: Maybe<TechnicalDataFloatItem>;
  /**
   * Tyres of the vehicle
   * f.ex. "Reifen 185/65 R15 92 V xl"
   */
  tyres?: Maybe<TechnicalDataTextItem>;
  /**
   * Vehicle weight without load.
   * f.ex.: 2505 kg
   */
  unladenWeight?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of component used for transmitting the power from a rotating power source to the wheels or other relevant component(s) ("gearbox" for cars)
   * (https://schema.org/vehicleTransmission)
   * f.ex.: Allradantrieb quattro
   */
  vehicleTransmission?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of the loaded vehicle, including passengers and cargo and the weight of the empty vehicle.
   * https://auto.schema.org/weightTotal
   * f.ex.: 3045 kg
   */
  weightTotal?: Maybe<TechnicalDataFloatItem>;
};

/** Input type for the ConfiguredCarsFromNaturalLanguage query specifying the scope of AI-generated configurations. */
export type ConfiguredCarsFromNaturalLanguageConfigurationScopeInput = {
  /**
   * A list of IDs related to the specified scope type.
   * All generated configurations will use these IDs as a configuration base. For example if two carline ids are given all configurations will be based on these two carlines and no other carlines.
   * e.g. (in case of carlines): ["q8etron", "sq8etron"]
   */
  ids: Array<Scalars['ID']['input']>;
  /** The scope for which the AI will generate configurations. */
  scopeType?: InputMaybe<ConfiguredCarsFromNaturalLanguageConfigurationScopeTypesInput>;
};

/** Enum for specifying the scope type for AI-generated configurations. */
export enum ConfiguredCarsFromNaturalLanguageConfigurationScopeTypesInput {
  /** Type Carline, used if provided ids are carline ids */
  Carline = 'CARLINE',
}

/** Input for ConfiguredCarsFromNaturalLanguage */
export type ConfiguredCarsFromNaturalLanguageInput = {
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /**
   * Scope for which configurations will be provided
   *
   * If a carline ID is provided all genreated configurations will be of this carline.
   */
  configurationScope?: InputMaybe<ConfiguredCarsFromNaturalLanguageConfigurationScopeInput>;
  /**
   * Country code
   * Mostly following the ISO-3166-1 alpha-2 standard with exceptions.
   * e.g.: "de"
   */
  country: Scalars['String']['input'];
  /**
   * Language code
   * Mostly  following the ISO-639-1 standard with exceptions.
   * e.g.: "de"
   */
  language: Scalars['String']['input'];
  /**
   * Maximum number of results to return
   * Value must be between (inclusive) 1 and 5
   * e.g.: 2
   */
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  /**
   * User provided Input
   * This input is limited to 200 characters
   * e.g. "A red SUV with panorama roof"
   */
  queryString: Scalars['String']['input'];
};

/**
 * Results of the ConfiguredCarsFromNaturalLanguage query
 * This is either be a successful response including configurations or a softly handled error response.
 */
export type ConfiguredCarsFromNaturalLanguageResponse =
  | ConfiguredCarsFromNaturalLanguageResponseError
  | ConfiguredCarsFromNaturalLanguageResponseResults;

/**
 * Error response of the ConfiguredCarsFromNaturalLanguage query
 * These errors are handled softly
 */
export type ConfiguredCarsFromNaturalLanguageResponseError = {
  __typename?: 'ConfiguredCarsFromNaturalLanguageResponseError';
  /** Error code, not to be shown to the user directly */
  code?: Maybe<ConfiguredCarsFromNaturalLanguageResponseErrorCodes>;
};

/** Softly handled error codes */
export enum ConfiguredCarsFromNaturalLanguageResponseErrorCodes {
  /** User input not allowed (containing profanity, is off topic, etc.) */
  BadUserInput = 'BAD_USER_INPUT',
  /** No configurations could be built for the provided user input */
  NoConfigurationsFound = 'NO_CONFIGURATIONS_FOUND',
  /**
   * Triggered when the search query appears to request unattractive or undesirable cars.
   * This is a lighthearted, non-critical error intended for marketing purposes.
   * Unless your application logic needs to address this specific scenario, this error can be treated as either BAD_USER_INPUT.
   */
  NoUglyCars = 'NO_UGLY_CARS',
}

/** Successful AI generated configuration including highlights */
export type ConfiguredCarsFromNaturalLanguageResponseResult = {
  __typename?: 'ConfiguredCarsFromNaturalLanguageResponseResult';
  /** Buildable configuration the AI came up with */
  configuration: ConfiguredCar;
  /**
   * Highlighted Equipment Options that the AI chose
   * Options can either be default equipment or additional equipment
   * Ordered descending by relevance determined by AI response
   */
  highlightedEquipmentOptions: Array<ConfiguredCarsFromNaturalLanguageResponseResultHighlightedEquipmentOptions>;
  /**
   * Highlighted Specifications that the AI chose
   * Specifications can relate to e.g. speed, accelerationTime, etc.
   */
  highlightedSpecifications: Array<ConfiguredCarsFromNaturalLanguageResponseResultHighlightedSpecifications>;
};

/** Highlighted Equipment Option */
export type ConfiguredCarsFromNaturalLanguageResponseResultHighlightedEquipmentOptions = {
  __typename?: 'ConfiguredCarsFromNaturalLanguageResponseResultHighlightedEquipmentOptions';
  /**
   * ID of the equipment option in pr7 format
   * Matches a equipment option in the configured car's catalog
   * e.g.: "MASR5RQ"
   */
  id: Scalars['String']['output'];
};

/** Highlighted Specification */
export type ConfiguredCarsFromNaturalLanguageResponseResultHighlightedSpecifications = {
  __typename?: 'ConfiguredCarsFromNaturalLanguageResponseResultHighlightedSpecifications';
  /**
   * Name of the specification
   * e.g.: "accelerationTime"
   */
  name: Scalars['String']['output'];
};

/** Successful response of the ConfiguredCarsFromNaturalLanguage query containing one or many valid configurations */
export type ConfiguredCarsFromNaturalLanguageResponseResults = {
  __typename?: 'ConfiguredCarsFromNaturalLanguageResponseResults';
  /** AI generated configuration results */
  results: Array<ConfiguredCarsFromNaturalLanguageResponseResult>;
};

/** Identifier which is return from a change configured car conflict. Should be used in the accept solution query as input */
export type ConflictConfiguredCarIdentifier = {
  __typename?: 'ConflictConfiguredCarIdentifier';
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /** 3-digit pr numbers */
  equipmentOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: Maybe<Scalars['String']['output']>;
  /** 2-digit pr number of the interior color */
  interiorColor?: Maybe<Scalars['String']['output']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  model: ConflictConfiguredCarModelIdentifier;
  /** 5-digit sales group */
  salesGroup?: Maybe<Scalars['String']['output']>;
};

/** Conflict Model identifier */
export type ConflictConfiguredCarModelIdentifier = CarModelIdentifier & {
  __typename?: 'ConflictConfiguredCarModelIdentifier';
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. 8WCAYG */
  code: Scalars['String']['output'];
  /** 3-digit pr numbers */
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  /** 1-digit model version */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release */
  year: Scalars['Int']['output'];
};

/** Summary of consumption and emission ranges formatted as string according to country and language. */
export type ConsumptionAndEmission = {
  __typename?: 'ConsumptionAndEmission';
  /**
   * Array of disctinct consumption and emission values for fuelType
   * @deprecated use measurements instead
   */
  consumptionAndEmissionPerFuel: ConsumptionAndEmissionPerFuel;
  /** Array containing consumption and emission values for each fuel measured by WLTP/NEDC */
  measurements: Array<Maybe<ConsumptionAndEmissionPerFuel>>;
  /**
   * Summary values for footnotes etc.
   * @deprecated use the summary on configured car level instead
   */
  summary: ConsumptionAndEmissionSummary;
};

/** Per fuel consumption and emission values; separated by testing method */
export type ConsumptionAndEmissionPerFuel = {
  __typename?: 'ConsumptionAndEmissionPerFuel';
  fuelName?: Maybe<Scalars['String']['output']>;
  fuelType: FuelType;
  /** Consumption and emission ranges per fuel; entries for different energy management testing cycles */
  testMethods: ConsumptionAndEmissionTestMethods;
};

/** Summary values for footnotes etc. */
export type ConsumptionAndEmissionSummary = {
  __typename?: 'ConsumptionAndEmissionSummary';
  /**
   * Summary consumption text for footnotes etc.
   * @deprecated use consumptionSummary instead
   */
  consumption: Scalars['String']['output'];
  /**
   * Array containing consumption values for each fuel
   * @deprecated use consumptionsSummary instead
   */
  consumptionSummary: Array<Maybe<FuelFloatValue>>;
  /** Array containing consumption values for each fuel and energy management */
  consumptionsSummary?: Maybe<Array<FuelValues>>;
  /** Array containing energy efficicency classes for each energy management */
  eecsSummary?: Maybe<Array<EecValuePerEnergyManagementWltp>>;
  /**
   * Summary emission text for footnotes etc.
   * @deprecated use emissionCO2Summary instead
   */
  emissionCO2: Scalars['String']['output'];
  /**
   * Object containing information about the emission values
   * @deprecated use emissionsCO2Summary instead
   */
  emissionCO2Summary: FuelFloatValue;
  /** Summary Nitrogen Dioxide text */
  emissionN02?: Maybe<Scalars['String']['output']>;
  /** Object containing information about the emission values for each fuel and energy management */
  emissionsCO2Summary?: Maybe<Array<FuelValues>>;
  /** Array of footnote references */
  footnoteReferences: Array<Maybe<Scalars['String']['output']>>;
};

export type ConsumptionAndEmissionTestMethods = {
  __typename?: 'ConsumptionAndEmissionTestMethods';
  nedc?: Maybe<NedcTestMethod>;
  nonWltp?: Maybe<NonWltpTestMethod>;
  /** @deprecated use wltpPerEnergyManagement */
  wltp?: Maybe<WltpTestMethod>;
  /** Consumption and emission ranges per fuel; entries for different energy management testing cycles */
  wltpPerEnergyManagement?: Maybe<WltpTestMethodsPerEnergyManagement>;
};

/** Node containing consumption and emission values */
export type ConsumptionAndEmissionValuePerEnergyManagementWltp = {
  __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
  /** we have different values for different energy managements, example: pure or sustaining for hybrids */
  energyManagement?: Maybe<WltpEnergyManagement>;
  /** formatted value */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** may or may not be present depending on a factual decision; true if preferred to be shown */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** label, if present */
  label?: Maybe<Scalars['String']['output']>;
  /** remarks, if present */
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** unit */
  unit?: Maybe<Scalars['String']['output']>;
  /** raw value */
  value?: Maybe<Scalars['Float']['output']>;
};

/** An indication about if a color should be chosen from a brighter or darker color scheme */
export enum ContrastThemeHint {
  Dark = 'DARK',
  Light = 'LIGHT',
}

/** Currency information */
export type Currency = {
  __typename?: 'Currency';
  /** ISO 4217 currency code. For example, "USD" */
  code: Scalars['ID']['output'];
  /** Symbol of the currency. For example, "$" */
  symbol?: Maybe<Scalars['String']['output']>;
};

/** All possible customer group classifications */
export enum CustomerGroupClassification {
  Private = 'PRIVATE',
  SmallCommercial = 'SMALL_COMMERCIAL',
}

/** @deprecated data moved into `ConfiguredCar` */
export type DataEntry = {
  __typename?: 'DataEntry';
  disclaimer?: Maybe<Scalars['String']['output']>;
  formattedValue: Scalars['String']['output'];
  id: Scalars['String']['output'];
  rawValue?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

/** information about a dealer of a car */
export type DealerInfo = {
  __typename?: 'DealerInfo';
  /** city of dealer address */
  city: Scalars['String']['output'];
  /** ISO Alpha3 country code / KVPS country */
  country: Scalars['String']['output'];
  /** e mail address of dealer */
  email?: Maybe<Scalars['String']['output']>;
  /** fax number of dealer */
  fax?: Maybe<Scalars['String']['output']>;
  /** KVPS number of dealer */
  hid: Scalars['String']['output'];
  /** unique id of a dealer in a market */
  id: Scalars['String']['output'];
  /** imprint of dealer */
  imprint?: Maybe<Scalars['String']['output']>;
  /** name of dealer */
  name: Scalars['String']['output'];
  /** phone number of dealer */
  phone?: Maybe<Scalars['String']['output']>;
  /** geo location of dealer */
  position?: Maybe<GeoLocation>;
  /** region of dealer address (e.g. state) */
  region?: Maybe<Scalars['String']['output']>;
  /** street of dealer address */
  street?: Maybe<Scalars['String']['output']>;
  /** link to dealers page */
  website?: Maybe<Scalars['String']['output']>;
  /** zip of dealer address */
  zip: Scalars['String']['output'];
};

/** result of a dealer search */
export type DealerSearch = {
  __typename?: 'DealerSearch';
  /** number of found dealers */
  resultNumber: Scalars['Int']['output'];
  /** subset of found dealers */
  results: DealerSearchResults;
};

/**
 * "new input"
 *
 * parameter for geo search of dealers
 */
export type DealerSearchParameterGeoCriterionInput = {
  /** latitude of geo position (center of search circle) */
  latitude: Scalars['Float']['input'];
  /** longitude of geo position (center of search circle) */
  longitude: Scalars['Float']['input'];
  /** maximum distance between passed geo position (latitude and longitude) and geo position of a found dealer (radius of search circle) */
  maxDistance: Scalars['Float']['input'];
};

/**
 * "new input"
 *
 * additional parameter for dealer search: search criteria, sorting and paging
 */
export type DealerSearchParameterInput = {
  /** parameter for geographic proximity search */
  geo?: InputMaybe<DealerSearchParameterGeoCriterionInput>;
  /**
   * parameter for search by dealer name
   * the search value could be only a part the name
   */
  name?: InputMaybe<Scalars['String']['input']>;
  /** parameter for paging */
  paging?: InputMaybe<DealerSearchParameterPagingInput>;
  /** parameter for sorting */
  sort?: InputMaybe<DealerSearchParameterSortInput>;
};

/** new input */
export type DealerSearchParameterPagingInput = {
  /** limitation of results */
  limit: Scalars['Int']['input'];
  /** offset set (first element) */
  offset: Scalars['Int']['input'];
};

/** new input */
export type DealerSearchParameterSortInput = {
  /** selected sort direction */
  direction: DealerSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['DealerSearchSortId']['input'];
};

/** list of found dealers */
export type DealerSearchResults = {
  __typename?: 'DealerSearchResults';
  /** found dealers */
  dealers: Array<DealerSearchResultsDealer>;
  /** applied paging parameter */
  paging?: Maybe<DealerSearchResultsPaging>;
  /** applied sort parameter */
  sort?: Maybe<DealerSearchResultsSort>;
};

/** data of a single found dealer */
export type DealerSearchResultsDealer = {
  __typename?: 'DealerSearchResultsDealer';
  /** common dealer data */
  dealer: DealerInfo;
};

/** Information for dealer search results paging */
export type DealerSearchResultsPaging = {
  __typename?: 'DealerSearchResultsPaging';
  /** limit set */
  limit: Scalars['Int']['output'];
  /** offset set (first element) */
  offset: Scalars['Int']['output'];
};

/** Input parameter that was handed over for dealer search */
export type DealerSearchResultsSort = {
  __typename?: 'DealerSearchResultsSort';
  /** selected sort direction */
  direction: DealerSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['DealerSearchSortId']['output'];
};

/** new enum */
export enum DealerSearchSortDirection {
  /** ascending sort direction */
  Asc = 'ASC',
  /** descending sort direction */
  Desc = 'DESC',
}

export type DetailModelRequest = {
  additionalCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  detailCombinationCodes: Array<Array<Scalars['String']['input']>>;
  equipmentCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  interiorColorCode?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  modelCode: Scalars['String']['input'];
  modelExtensions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modelRevision?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['Int']['input']>;
  modelYear: Scalars['Int']['input'];
};

/** Determines, whether the engine sends power to all wheels, the front wheels or the rear wheels. */
export enum Drive {
  /** Front-wheel drive */
  FrontWheelDrive = 'FRONT_WHEEL_DRIVE',
  /** All-wheel drive */
  Quattro = 'QUATTRO',
  /** Rear-wheel drive */
  RearWheelDrive = 'REAR_WHEEL_DRIVE',
}

/** Classifies the vehicle's drive train. */
export enum DriveTrain {
  /** Battery electric vehicle */
  Bev = 'BEV',
  /** Internal combustion engine */
  Ice = 'ICE',
  /** Mild hybrid electric vehicle */
  Mhev = 'MHEV',
  /** Plug-in hybrid electric vehicle */
  Phev = 'PHEV',
}

/**
 * Filter for dynamic catalog of a ConfiguredCar
 *
 * example:
 *   {
 *     "not": [
 *       {
 *         "status": "inConflict"
 *       }
 *     ],
 *     "and": [
 *       {
 *         "status": "changeable"
 *       },
 *     ],
 *     "or": []
 *   }
 *
 * This will give you all changeable excluding those  inConflict.
 */
export type DynamicCatalogFilterInput = {
  /** Include items only if all specified conditions are met (logical AND). */
  and?: InputMaybe<Array<DynamicCatalogFilterOptionInput>>;
  /** Exclude items that match the specified conditions (logical NOT). */
  not?: InputMaybe<Array<DynamicCatalogFilterOptionInput>>;
  /** Include items if any specified condition is met (logical OR). */
  or?: InputMaybe<Array<DynamicCatalogFilterOptionInput>>;
};

/** Filter for dynamic catalog of a ConfiguredCar */
export type DynamicCatalogFilterOptionInput = {
  /** A status to be filtered by */
  status?: InputMaybe<ConfiguredCarFeatureState>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacConsolidated = {
  __typename?: 'EACConsolidated';
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<EacValue>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacConsumption = {
  __typename?: 'EACConsumption';
  consolidated?: Maybe<EacConsolidated>;
  nedc?: Maybe<EacEntry>;
  wltp?: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacDataSet = {
  __typename?: 'EACDataSet';
  em?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  values?: Maybe<EacDataSetValues>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacDataSetValues = {
  __typename?: 'EACDataSetValues';
  combined?: Maybe<EacValue>;
  extraHigh?: Maybe<EacValue>;
  high?: Maybe<EacValue>;
  low?: Maybe<EacValue>;
  medium?: Maybe<EacValue>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEmission = {
  __typename?: 'EACEmission';
  co2?: Maybe<EacEmissionType>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEmissionType = {
  __typename?: 'EACEmissionType';
  consolidated?: Maybe<EacConsolidated>;
  nedc?: Maybe<EacEntry>;
  wltp?: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEntry = {
  __typename?: 'EACEntry';
  consolidated?: Maybe<EacConsolidated>;
  dataSets?: Maybe<Array<Maybe<EacDataSet>>>;
  /** "datasets" is deprecated. Use "dataSets" (mixedCase) instead. */
  datasets?: Maybe<Array<Maybe<EacDataSet>>>;
  unit?: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacFootnote = {
  __typename?: 'EACFootnote';
  reference?: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacFuel = {
  __typename?: 'EACFuel';
  consumption?: Maybe<EacConsumption>;
  emission?: Maybe<EacEmission>;
  fuel?: Maybe<Scalars['String']['output']>;
  range?: Maybe<EacRange>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacRange = {
  __typename?: 'EACRange';
  consolidated?: Maybe<EacConsolidated>;
  nedc?: Maybe<EacEntry>;
  wltp?: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacValue = {
  __typename?: 'EACValue';
  formatted?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

/** Contains EEC range data. */
export type EecRange = {
  __typename?: 'EECRange';
  /** The worst EEC value, e.g. 'F' */
  max: Scalars['String']['output'];
  /** The best EEC value, e.g. 'A' */
  min: Scalars['String']['output'];
};

export enum EcoLabel {
  Eco = 'ECO',
  EcoIt = 'ECO_IT',
  NoEco = 'NO_ECO',
  Zero = 'ZERO',
}

/** Node for energy efficiency class */
export type EecPerEnergyManagementWltp = {
  __typename?: 'EecPerEnergyManagementWltp';
  /** Energy Efficiency Class. */
  eec?: Maybe<TechnicalDataTextItem>;
  /** applied measurement cycle */
  energyManagement?: Maybe<WltpEnergyManagement>;
};

/** summary node for eec (energy efficiency classes) */
export type EecValuePerEnergyManagementWltp = {
  __typename?: 'EecValuePerEnergyManagementWltp';
  /** we have different values for different energy management types, example: pure or sustaining for hybrids */
  energyManagement?: Maybe<WltpEnergyManagement>;
  /** formatted value with explanatory text translated to the language of the market */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** very broad description translated to the language of the market */
  label?: Maybe<Scalars['String']['output']>;
  /** manually added remarks, if any needed */
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** raw value, represented via string, very often not just one letter */
  value?: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EmissionAndConsumption = {
  __typename?: 'EmissionAndConsumption';
  eec?: Maybe<Scalars['String']['output']>;
  emissionClass?: Maybe<Scalars['String']['output']>;
  footnote?: Maybe<EacFootnote>;
  formattedConsumption?: Maybe<Scalars['String']['output']>;
  formattedEmission?: Maybe<Scalars['String']['output']>;
  formattedFuels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fuels?: Maybe<Array<Maybe<EacFuel>>>;
  hasNedc?: Maybe<Scalars['Boolean']['output']>;
  hasWltp?: Maybe<Scalars['Boolean']['output']>;
  vehicleType?: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type Engine = {
  __typename?: 'Engine';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** The power of the vehicle's engine in different formats: PS, KW or combined */
export type EnginePower = {
  __typename?: 'EnginePower';
  /**
   * The combine max power of the vehicle's engine.
   * f.ex. 250 kW (340 PS) bei 5000-6400 min -1
   */
  maxPower?: Maybe<TechnicalDataFloatItem>;
  /** The power of the vehicle's engine in KW. */
  maxPowerKW?: Maybe<TechnicalDataFloatItem>;
  /** The power of the vehicle's engine in KW when using launch control. */
  maxPowerLaunchControlKW?: Maybe<TechnicalDataFloatItem>;
  /** The max power of the vehicle's engine in PS. */
  maxPowerPS?: Maybe<TechnicalDataFloatItem>;
};

/** Technology that is used by an engine. */
export enum EngineTechnology {
  /** Diesel Direct Injection Engine */
  Tdi = 'TDI',
  /** Turbocharged Fuel Stratified Injection */
  Tfsi = 'TFSI',
  /** Turbocharged Fuel Stratified Injection with electric engine (hybrid) */
  Tfsie = 'TFSIE',
}

/** Classifies the vehicle's engine. */
export enum EngineType {
  /** Combustion engine */
  Combustion = 'COMBUSTION',
  /** Electric engine */
  Electrical = 'ELECTRICAL',
  /** Hybrid engine */
  Hybrid = 'HYBRID',
}

/** Collection of named exterior views. Each key maps to a render image URL. */
export type ExteriorViews = {
  __typename?: 'ExteriorViews';
  /** The front view, slightly angled. */
  beautyFront: Scalars['String']['output'];
  /** The rear view, slightly angled. */
  beautyRear: Scalars['String']['output'];
  /** The front view. */
  front: Scalars['String']['output'];
  /** The side view. */
  side: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Family = {
  __typename?: 'Family';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** filter features by one or multiple family IDs (family IDs are market specific and can differ for each market!)(multiple family IDs in the array behave like a logic OR) */
export type FamilyIdsFilterInput = {
  familyIds: Array<Scalars['String']['input']>;
  mode?: InputMaybe<FilterMode>;
};

/**
 * Feature change request input required to modify a given ConfiguredCar. If autoResolveConflicts is enabled
 * eventual conflicting results will be solved automatically with the best effort approach.
 */
export type FeatureChange = {
  /** action to be performed with this feature on the ConfiguredCar: "ADD", "REMOVE", etc. */
  action: FeatureChangeAction;
  /** indicates if an eventual conflicting configuration result after adding/removing this feature should be automatically resolved */
  autoResolveConflicts: Scalars['Boolean']['input'];
  /** ConfiguredCarFeature's pr3 or pr7 value */
  id: Scalars['ID']['input'];
};

/** Possible actions that can be taken with a feature in relation to a ConfiguredCar */
export enum FeatureChangeAction {
  Add = 'ADD',
  Remove = 'REMOVE',
}

/** Contains a list of features as Strings for this specific Trimline Model */
export type FeatureGroup = {
  __typename?: 'FeatureGroup';
  features: Array<Scalars['String']['output']>;
  headline: Scalars['String']['output'];
};

/** filter features by one or more feature groups (feature groups are derived from groupIds and familyIds and not market dependent)(feature groups in the array behave like a logic OR) */
export type FeatureGroupsFilterInput = {
  featureGroups: Array<ConfiguredCarFeatureGroup>;
  mode?: InputMaybe<FilterMode>;
};

/** filter features by one or multiple feature types (multiple feature types in the array behave like a logic OR) */
export type FeatureTypesFilterInput = {
  featureTypes: Array<Scalars['String']['input']>;
  mode?: InputMaybe<FilterMode>;
};

/** features can be filtered by their attributes (e.g. configuration state, family id, group id or feature group) */
export type FeaturesFilterInput = {
  /**
   * filter features by family ID (family IDs are market specific and can differ for each market!)
   * @deprecated Use `filterByFamilyIds`.
   */
  filterByFamilyId?: InputMaybe<Scalars['String']['input']>;
  /** filter features by family Ids (when providing multiple filters (filterByFamilyIds, filterByGroupIds or filterByFeatureGroups) they get applied one after the other in a logical AND manner) */
  filterByFamilyIds?: InputMaybe<FamilyIdsFilterInput>;
  /**
   * filter features by one or more feature groups (feature groups in the array behave like a logic OR)
   * @deprecated Use `filterByFeatureGroups`.
   */
  filterByFeatureGroup?: InputMaybe<Array<ConfiguredCarFeatureGroup>>;
  /** filter feature by feature groups (when providing multiple filters (filterByFamilyIds, filterByGroupIds or filterByFeatureGroups) they get applied one after the other in a logical AND manner) */
  filterByFeatureGroups?: InputMaybe<FeatureGroupsFilterInput>;
  /**
   * filter features by one ore more conditions (array) based on their configuration state (available, changeable,
   * inConflict, selected,  standard)
   */
  filterByFeatureState?: InputMaybe<Array<ConfiguredCarFeatureState>>;
  /** filter features by feature types (when providing multiple filters they get applied one after the other in a logical AND manner) */
  filterByFeatureTypes?: InputMaybe<FeatureTypesFilterInput>;
  /**
   * filter features by group ID (groupIDs are market specific and can differ for each market!)
   * @deprecated Use `filterByGroupIds`.
   */
  filterByGroupId?: InputMaybe<Scalars['String']['input']>;
  /** filter feature by group Ids (when providing multiple filters (filterByFamilyIds, filterByGroupIds or filterByFeatureGroups) they get applied one after the other in a logical AND manner) */
  filterByGroupIds?: InputMaybe<GroupIdsFilterInput>;
};

/** options on how to apply filters (e.g. in FeaturesFilterInput) */
export enum FilterMode {
  Exclusive = 'EXCLUSIVE',
  Inclusive = 'INCLUSIVE',
}

/** An configuration option for which a rate should be calculated by Financing-GraphQl. */
export type FinanceablePart = {
  __typename?: 'FinanceablePart';
  pr: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
};

export type FinanceablePartRate = {
  __typename?: 'FinanceablePartRate';
  currency?: Maybe<FinancingCurrency>;
  formattedValue?: Maybe<Scalars['String']['output']>;
  rawValue?: Maybe<Scalars['Float']['output']>;
  valueAsText?: Maybe<Scalars['String']['output']>;
};

export type FinanceableTypedPrice = TypedPrice & {
  __typename?: 'FinanceableTypedPrice';
  monthlyRate?: Maybe<MonthlyRateResult>;
  price: Price;
  type: Scalars['String']['output'];
};

/** @deprecated calculation moved into financing-graphql subgraph */
export type Financing = {
  __typename?: 'Financing';
  globalDisclaimer?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  productDisclaimer?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Price>;
  summaryDisclaimer?: Maybe<Scalars['String']['output']>;
};

export type FinancingCalculationParameters = {
  __typename?: 'FinancingCalculationParameters';
  country: Scalars['String']['output'];
  language: Scalars['String']['output'];
  model: FinancingModelParameters;
  modelPrice?: Maybe<Scalars['Float']['output']>;
  /** Okapi data as described in webcalc */
  okapiData?: Maybe<OkapiData>;
  part?: Maybe<Array<Maybe<FinanceablePart>>>;
  /** array of price details objects. */
  priceDetails?: Maybe<Array<KeyValueString>>;
  product?: Maybe<FinancingProduct>;
  salesGroup: Scalars['String']['output'];
  /** Selected not default options for configuration */
  selectedOptions?: Maybe<Array<SelectedFinanceablePart>>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
};

export type FinancingCurrency = {
  __typename?: 'FinancingCurrency';
  code?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
};

export type FinancingDisclaimers = {
  __typename?: 'FinancingDisclaimers';
  calculationDisclaimer?: Maybe<Scalars['String']['output']>;
  globalDisclaimer?: Maybe<Scalars['String']['output']>;
  productDisclaimer?: Maybe<Scalars['String']['output']>;
  rateDetails?: Maybe<Scalars['String']['output']>;
};

export type FinancingError = {
  __typename?: 'FinancingError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FinancingGraphqlInfo = OneGraphSubServiceInfo & {
  __typename?: 'FinancingGraphqlInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

/** Financing Product information */
export type FinancingInput = {
  /** e.g. VC for VarioCredit */
  product: Scalars['String']['input'];
  productParameters?: InputMaybe<Array<InputMaybe<FinancingProductParameterInput>>>;
};

/** Model information used for financing calculations */
export type FinancingModelParameters = {
  __typename?: 'FinancingModelParameters';
  /**
   * Model code
   *
   * example: "GEGBUB"
   */
  code: Scalars['String']['output'];
  /**
   * Model extensions in pr3 format
   *
   * example: ["GA2", "GB1", "IU1"]
   */
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Model extensions in pr7 format
   *
   * example: ["MVOKGA2", "MKOMGB1", "MCPBIU1"]
   */
  extensionsPR7?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Model version
   *
   * example: 2
   */
  version: Scalars['Int']['output'];
  /**
   * Model year
   *
   * example: 2024
   */
  year: Scalars['Int']['output'];
};

/** All required data for financing calculation. Additional data is provided by Financing-GraphQl through federation. */
export type FinancingParameters = {
  __typename?: 'FinancingParameters';
  appliedProduct?: Maybe<FinancingProduct>;
  /** @deprecated Use appliedProduct.financingParameters instead. Will be populated with the financing product that was used for the rate calculation. If no financing input was specified in the query variables, this will be a default product. In contrast, financingParameters.product will only contain the product that was specified in the query. */
  calculationParameters?: Maybe<Array<Maybe<FinancingProductParameter>>>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['output'];
  disclaimers?: Maybe<FinancingDisclaimers>;
  /** Total sum of all selected incentives */
  discountPrice?: Maybe<Scalars['Float']['output']>;
  /** Request parameters for calculate rate in the format expected by WebCalc. */
  externalCalculateRateRequest?: Maybe<Scalars['JSONObject']['output']>;
  /** Request parameters for defaults in the format expected by WebCalc. */
  externalDefaultsRequest?: Maybe<Scalars['JSONObject']['output']>;
  /** Request parameters for products in the format expected by WebCalc. */
  externalProductsRequest?: Maybe<Scalars['JSONObject']['output']>;
  /**
   * Request parameters in the format expected by WebCalc.
   * @deprecated use externalDefaultsRequest
   */
  externalRequestOptions?: Maybe<Scalars['JSONObject']['output']>;
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['output'];
  model: FinancingModelParameters;
  modelPrice?: Maybe<Scalars['Float']['output']>;
  /** Okapi data as described in webcalc */
  okapiData?: Maybe<OkapiData>;
  /** Total price without any incentives selected */
  originalPrice?: Maybe<Scalars['Float']['output']>;
  part?: Maybe<FinanceablePart>;
  product?: Maybe<FinancingProduct>;
  productName?: Maybe<Scalars['String']['output']>;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
  totalPrice?: Maybe<Scalars['Float']['output']>;
};

/** Financing Product information */
export type FinancingProduct = {
  __typename?: 'FinancingProduct';
  id: Scalars['String']['output'];
  productParameters?: Maybe<Array<Maybe<FinancingProductParameter>>>;
};

/** Financing Product parameter e.g. DownPayment */
export type FinancingProductParameter = {
  __typename?: 'FinancingProductParameter';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Financing Product parameter e.g. DownPayment */
export type FinancingProductParameterInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type FuelFloatValue = {
  __typename?: 'FuelFloatValue';
  formattedValue?: Maybe<Scalars['String']['output']>;
  fuelName?: Maybe<Scalars['String']['output']>;
  fuelType?: Maybe<FuelType>;
  label?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Array<Maybe<Remark>>>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum FuelType {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electrical = 'ELECTRICAL',
  /** @deprecated use: ELECTRICAL */
  Electricity = 'ELECTRICITY',
  Hybrid = 'HYBRID',
  Petrol = 'PETROL',
}

/** node containing consumption and emission values for fuel type */
export type FuelValues = {
  __typename?: 'FuelValues';
  /** Localized name of the fuel type, i.e., 'Diesel' or 'Benzin' */
  fuelName?: Maybe<Scalars['String']['output']>;
  /** Type of fuel, i.e., 'DIESEL' or 'PETROL' */
  fuelType?: Maybe<FuelType>;
  /** multiple values, one per Energy Management */
  values?: Maybe<Array<ConsumptionAndEmissionValuePerEnergyManagementWltp>>;
};

/** The specific transmission types of a vehicle. */
export enum Gear {
  /** Automatic transmission */
  Automatic = 'AUTOMATIC',
  /** Five-speed manual transmission */
  FiveSpeed = 'FIVE_SPEED',
  /** Six-speed manual transmission */
  SixSpeed = 'SIX_SPEED',
  /** S tronic dual-clutch transmission */
  Stronic = 'STRONIC',
  /** Torque-converter transmission */
  Tiptronic = 'TIPTRONIC',
}

/** The general transmission type of a vehicle (automatic or manual). */
export enum GearType {
  /** Automatic transmission */
  Automatic = 'AUTOMATIC',
  /** Manual transmission */
  Manual = 'MANUAL',
}

/** geo location with latitude and longitude */
export type GeoLocation = {
  __typename?: 'GeoLocation';
  /** latitude of geo location */
  latitude: Scalars['Float']['output'];
  /** longitude of geo location */
  longitude: Scalars['Float']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Group = {
  __typename?: 'Group';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** filter features by one or multiple group IDs (group IDs are market specific and can differ for each market!)(multiple group IDs in the array behave like a logic OR) */
export type GroupIdsFilterInput = {
  groupIds: Array<Scalars['String']['input']>;
  mode?: InputMaybe<FilterMode>;
};

/** @deprecated queries deprecated */
export enum IdentifierType {
  Audicode = 'AUDICODE',
  Carline = 'CARLINE',
  Prstring = 'PRSTRING',
}

/** @deprecated data moved into `ConfiguredCar` */
export type Image = {
  __typename?: 'Image';
  url?: Maybe<Scalars['String']['output']>;
};

export type ImageResolution = {
  __typename?: 'ImageResolution';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

/** Defines an image by size, MIME type and source URL. */
export type ImageSource = {
  __typename?: 'ImageSource';
  /** The height of the image in pixels, e.g. 600. */
  height: Scalars['Int']['output'];
  /** The MIME type of the image, e.g. "image/png". */
  mimeType: Scalars['String']['output'];
  /** The source of the image, e.g. "https://www.mydomain.com/myimage.png" */
  url: Scalars['String']['output'];
  /** The width of the image in pixels, e.g. 800. */
  width: Scalars['Int']['output'];
};

/**
 * Incentive for certain customers or groups
 * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
 */
export type Incentive = {
  __typename?: 'Incentive';
  /** What this incentive is for */
  action: IncentiveAction;
  /** Which customer groups this incentive is available for and descriptions */
  customerGroup: IncentiveCustomerGroup;
  id: Scalars['String']['output'];
  /** Disclaimer or legal text */
  limitation?: Maybe<Scalars['String']['output']>;
  /**
   * How much this incentive is worth
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  price: Price;
  /** The status of the incentive */
  status: Status;
  /** When the incentive starts and ends */
  validityPeriod: IncentiveValidityPeriod;
  /** Requirements to qualify for the incentive */
  vehicleEligibilityCheck?: Maybe<Scalars['String']['output']>;
};

/** The incentive action */
export type IncentiveAction = {
  __typename?: 'IncentiveAction';
  /** A description of what the incentive is for */
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** A title for the incentive */
  title?: Maybe<Scalars['String']['output']>;
};

/** The incentive action */
export type IncentiveActionData = {
  __typename?: 'IncentiveActionData';
  /** A description of what the incentive is for */
  description?: Maybe<Scalars['String']['output']>;
  /** The incentive action id */
  id?: Maybe<Scalars['String']['output']>;
  /** A title for the incentive */
  title?: Maybe<Scalars['String']['output']>;
};

/** The incentive customer group */
export type IncentiveCustomerGroup = {
  __typename?: 'IncentiveCustomerGroup';
  /** An array of which customer groups that may use this incentive */
  classification: Array<CustomerGroupClassification>;
  /** A text description of who the incentive is for */
  description?: Maybe<Scalars['String']['output']>;
  /** What is required to qualify for the incentive */
  eligibility?: Maybe<Scalars['String']['output']>;
};

/** The incentive customer group */
export type IncentiveCustomerGroupData = {
  __typename?: 'IncentiveCustomerGroupData';
  /** An array of which customer groups that may use this incentive */
  classification?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** A text description of who the incentive is for */
  description?: Maybe<Scalars['String']['output']>;
  /** What is required to qualify for the incentive */
  eligibility?: Maybe<Scalars['String']['output']>;
};

/**
 * Incentive for certain customers or groups
 * data source: https://[country]-[environment]-dpu.cocos-eu-[environment].webapps.audi.io/ak4/vsapi3-services/v1/nemo-[country]-incentive/[language]/ModelOverviewWithInfo?apikey=[key]&carline=[carlineId]&model=[modelId]
 */
export type IncentiveData = {
  __typename?: 'IncentiveData';
  /** What this incentive is for */
  action?: Maybe<IncentiveActionData>;
  /** Which customer groups this incentive is available for and descriptions */
  customerGroup?: Maybe<IncentiveCustomerGroupData>;
  /** The incentive id */
  id?: Maybe<Scalars['String']['output']>;
  /** Disclaimer or legal text */
  limitation?: Maybe<Scalars['String']['output']>;
  /** How much this incentive is worth */
  price?: Maybe<Price>;
  /** When the incentive starts and ends */
  validityPeriod?: Maybe<IncentiveValidityPeriodData>;
  /** Requirements to qualify for the incentive */
  vehicleEligibilityCheck?: Maybe<Scalars['String']['output']>;
};

/** Incentive validity period */
export type IncentiveValidityPeriod = {
  __typename?: 'IncentiveValidityPeriod';
  /** Incentive starting date */
  from?: Maybe<Scalars['String']['output']>;
  /** Incentive end date */
  to?: Maybe<Scalars['String']['output']>;
};

/** Incentive validity period */
export type IncentiveValidityPeriodData = {
  __typename?: 'IncentiveValidityPeriodData';
  /** Incentive starting date */
  from?: Maybe<Scalars['String']['output']>;
  /** Incentive end date */
  to?: Maybe<Scalars['String']['output']>;
};

export type InputImageResolution = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

/** @deprecated renamed to `ConfiguredCarFeatureGroup` */
export enum ItemGroup {
  Accessories = 'ACCESSORIES',
  ExclusiveExteriorColors = 'EXCLUSIVE_EXTERIOR_COLORS',
  ExteriorColors = 'EXTERIOR_COLORS',
  InteriorTiles = 'INTERIOR_TILES',
  Lights = 'LIGHTS',
  Rims = 'RIMS',
  Seats = 'SEATS',
  SteeringWheels = 'STEERING_WHEELS',
}

/** Optional details regarding the Price */
export type KeyValueString = {
  __typename?: 'KeyValueString';
  /** The records identifier */
  key: Scalars['String']['output'];
  /** The records payload */
  value: Scalars['String']['output'];
};

/** Used as a container to provide price information. */
export type LabeledTypedPrice = {
  __typename?: 'LabeledTypedPrice';
  /** Disclaimers for price items. At the moment only for financing rate. */
  disclaimers?: Maybe<Array<Maybe<StockCarPriceDisclaimer>>>;
  /** text information about the price, like 'Price' */
  label?: Maybe<Scalars['String']['output']>;
  /** price information with values and currency details */
  price: Price;
  /** type of price, like 'Sale', 'List' */
  type: Scalars['String']['output'];
};

/** @deprecated queries deprecated */
export type MarketContext = {
  country: Scalars['String']['input'];
  language: Scalars['String']['input'];
  previewContext?: InputMaybe<Scalars['String']['input']>;
};

export type MarketIdentifier = {
  __typename?: 'MarketIdentifier';
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['output'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['output'];
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
};

/** information to specify a market */
export type MarketIdentifierInput = {
  /** KVPS brand id. For example `A` for Audi. */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
};

/** Trimline Model Data */
export type Model = {
  __typename?: 'Model';
  /**
   * Default configuration of the model
   *
   * Disclaimer:
   * Including this in your query can have a great impact on latency!
   * This field will only be populated for markets also supporting the "configuredCarByCarline" query
   */
  defaultConfiguredCar?: Maybe<ConfiguredCar>;
  driveName: Scalars['String']['output'];
  ecoLabel?: Maybe<EcoLabel>;
  engineName: Scalars['String']['output'];
  featureGroups: Array<FeatureGroup>;
  gearType: Scalars['String']['output'];
  /** Shows if a model is hidden or not */
  hidden?: Maybe<Scalars['Boolean']['output']>;
  /** A list of incentives */
  incentives?: Maybe<Array<Maybe<IncentiveData>>>;
  marketId: MarketIdentifier;
  modelId: ModelIdentifier;
  name: Scalars['String']['output'];
  /** @deprecated use prices.minPrice instead */
  price: Price;
  prices: ModelFinanceablePrices;
  technicalData: ModelTechnicalData;
};

/** Trimline Model Data */
export type ModelPricesArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** Contains basic model information to classify a vehicle. */
export type ModelAttributes = {
  __typename?: 'ModelAttributes';
  /** Body type. Example: SUV, AVANT, SPORTBACK, LIMOUSINE, COUPE, COMPACT, ALLROAD, ALLSTREET, CABRIOLET */
  bodyType?: Maybe<VehicleBodyType>;
  /** Determines this vehicle's amount of doors. Example: "5" */
  doors?: Maybe<Scalars['String']['output']>;
  /** Drive type. Example: FOUR_WHEEL_DRIVE, FRONT_WHEEL_DRIVE, REAR_WHEEL_DRIVE */
  drive?: Maybe<Drive>;
  /** Drive train. Example: BEV, MHEV, PHEV, ICE */
  driveTrain?: Maybe<DriveTrain>;
  /** Engine technology. Example: tfsi, tdi, tfsie */
  engineTechnology?: Maybe<EngineTechnology>;
  /** Engine type. Example: ELECTRICAL, COMBUSTION, HYBRID */
  engineType?: Maybe<EngineType>;
  /** Determines the main fuel type of this engine. Example: PETROL, DIESEL, ELECTRICAL */
  fuelType?: Maybe<FuelType>;
  /** Gear. Example: AUTOMATIC, STRONIC, TIPTRONIC, SIX_SPEED, FIVE_SPEED */
  gear?: Maybe<Gear>;
  /** Determines, whether this engine uses a automatic or manual transmission. Example: AUTOMATIC, MANUAL */
  gearType?: Maybe<GearType>;
};

/** Input for the static model catalog */
export type ModelCatalogInput = {
  /**
   * Market's Brand
   * e.g. "A" for Audi
   */
  brand: Scalars['String']['input'];
  /**
   * Market's country
   * e.g. "de"
   */
  country: Scalars['String']['input'];
  /**
   * Market's language
   * e.g. "de"
   */
  language: Scalars['String']['input'];
  /**
   * Model Identifier
   * e.g. "GEGCUB3GA2GB1IU1KA2N4MPCCUE8YEC1D23S14L66E36XK9ZE_2024"
   */
  model: Scalars['String']['input'];
};

export type ModelDetailResult = {
  __typename?: 'ModelDetailResult';
  mappedPrString?: Maybe<Scalars['String']['output']>;
  urlResult: Array<Maybe<UrlDetailResult>>;
};

/** Node for energy efficiency class on model level */
export type ModelEecPerEnergyManagementWltp = {
  __typename?: 'ModelEecPerEnergyManagementWltp';
  /** Energy Efficiency Class. */
  eec?: Maybe<RangeLevelInfo>;
  /** applied measurement cycle */
  energyManagement?: Maybe<WltpEnergyManagement>;
};

export type ModelFinanceablePrices = {
  __typename?: 'ModelFinanceablePrices';
  financingParameters?: Maybe<FinancingParameters>;
  monthlyRate?: Maybe<MonthlyRateResult>;
  /** Model's base price */
  price?: Maybe<Price>;
  /** Model's base price with fees */
  priceWithFees?: Maybe<Price>;
};

/** This identifier contains all information required to identifier a Model */
export type ModelIdentifier = {
  __typename?: 'ModelIdentifier';
  code: Scalars['String']['output'];
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  extensionsPR7?: Maybe<Array<Scalars['String']['output']>>;
  version: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type ModelIdentifierInput = {
  code: Scalars['String']['input'];
  /**
   * 3-digit pr numbers (deprecated)
   * @deprecated use extensionsPR7
   */
  extensions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 7-digit pr numbers */
  extensionsPR7?: InputMaybe<Array<Scalars['String']['input']>>;
  version: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type ModelRequest = {
  additionalCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  equipmentCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  exteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  interiorColorCode?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  modelCode: Scalars['String']['input'];
  modelExtensions?: InputMaybe<Array<Scalars['String']['input']>>;
  modelRevision?: InputMaybe<Scalars['String']['input']>;
  modelVersion?: InputMaybe<Scalars['Int']['input']>;
  modelYear: Scalars['Int']['input'];
};

export type ModelResult = {
  __typename?: 'ModelResult';
  mappedPrString?: Maybe<Scalars['String']['output']>;
  urlResult: Array<Maybe<UrlResult>>;
};

export type ModelTechnicalData = {
  __typename?: 'ModelTechnicalData';
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity.
   * (https://auto.schema.org/accelerationTime)
   * f.ex.: 9,5 s
   * TODO: question: How to handle the name: Beschleunigung 0-100 km/
   */
  accelerationTime?: Maybe<TechnicalDataFloatItem>;
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity when using launch control.
   * For example: 5.9 s
   */
  accelerationTimeLaunchControl?: Maybe<TechnicalDataFloatItem>;
  /**
   * Indicates the design and body style of the vehicle (e.g. station wagon, hatchback, etc.).
   * (https://auto.schema.org/bodyType)
   */
  bodyType?: Maybe<TechnicalDataTextItem>;
  /**
   * Brakes og the vehicle
   * f.ex. "Diagonal-2-Kreis Bremssystem mit ESC/ABS/EBV, Bremskraftverstärker, hydraulischer Bremsassistent; vorn: Faustsattel, Bremsscheibe innenbelüftet"
   */
  brakes?: Maybe<TechnicalDataTextItem>;
  /**
   * The available volume for cargo or luggage. For automobiles, this is usually the trunk volume.
   * https://schema.org/cargoVolume
   */
  cargoVolume?: Maybe<TechnicalDataRangeItem>;
  /**
   * Clutch of the vehicle
   * f.ex.: Doppelkupplung
   */
  clutch?: Maybe<TechnicalDataTextItem>;
  /** Consumptions and emissions. */
  consumptionAndEmission?: Maybe<ConsumptionAndEmission>;
  /**
   * Energy Efficiency Certificate.
   * @deprecated use envkv.eecModelPerEnergyManagementWltp instead
   */
  eec?: Maybe<TechnicalDataTextItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * f.ex. 284,0 - 341,0 km
   * Deprecated: --> engine.electricRangeCombined
   * @deprecated No longer supported
   */
  electricRangeCombined?: Maybe<TechnicalDataRangeItem>;
  /**
   * Array of the engines for this vehicle. Minimal one entry
   * (https://auto.schema.org/EngineSpecification)
   */
  engine?: Maybe<TechnicalDataEngineBase>;
  /** Energieverbrauchskennzeichnungsverordnung (Energy consumption labeling regulation) properties for models */
  envkv?: Maybe<ModelTechnicalDataEnvkv>;
  /** A list of additional technical data fields that are not part of the schema due to being market specific. */
  extendedTechnicalData?: Maybe<Array<TechnicalDataExtendedField>>;
  /**
   * Front axle of the vehicle
   * f.ex. "McPherson-Federbeinachse vorne"
   */
  frontAxle?: Maybe<TechnicalDataTextItem>;
  /**
   * Gearbox of the vehicle
   * f.ex.: 7-Gang S tronic
   */
  gearbox?: Maybe<TechnicalDataTextItem>;
  /** Class of several insurance related attributes */
  insuranceClass?: Maybe<TechnicalDataInsuranceClass>;
  /**
   * Indicates that the vehicle meets the respective emission standard.
   * https://schema.org/meetsEmissionStandard
   * f.ex. Euro 6d-TEMP
   */
  meetsEmissionStandard?: Maybe<TechnicalDataTextItem>;
  /** Contains basic model information to classify a vehicle. */
  modelAttributes?: Maybe<ModelAttributes>;
  /** Noise level of the vehicle while driving. Example: 69 dB(A) */
  noiseLevelDriving?: Maybe<TechnicalDataTextItem>;
  /**
   * Rear axle of the vehicle
   * f.ex. "Verbundlenker-Hinterachse "
   */
  rearAxle?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of cargo and installations (e.g. a roof rack) on top of the vehicle.
   * https://auto.schema.org/roofLoad
   */
  roofLoad?: Maybe<TechnicalDataFloatItem>;
  /**
   * The speed range of the vehicle. If the vehicle is powered by an engine, the upper limit of the speed range (indicated by maxValue should be the maximum speed achievable under regular conditions.
   * (https://auto.schema.org/speed)
   * f.ex. 250 km/h
   */
  speed?: Maybe<TechnicalDataFloatItem>;
  /**
   * Steering system of the vehicle
   * f.ex. "Elektromechanische Lenkung mit geschwindigkeitsabhängiger Servounterstützung; Wendekreis ca. 10,6 m"
   */
  steeringSystem?: Maybe<TechnicalDataTextItem>;
  /** Debug Value */
  tenant?: Maybe<Scalars['String']['output']>;
  /**
   * The permitted vertical load (TWR) of a trailer attached to the vehicle. Also referred to as Tongue Load Rating (TLR) or Vertical Load Rating (VLR)
   * https://auto.schema.org/tongueWeight
   */
  tongueWeight?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 8 percent.
   * f.ex.: 2700 kg
   */
  trailerWeight8?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 12 percent.
   * f.ex. 2800 kg / in Verbindung mit adaptive air suspension oder adaptive air suspension sport 3500
   */
  trailerWeight12?: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight for trailers without separate brakes.
   * f.ex.: 750 kg
   */
  trailerWeightUnbraked?: Maybe<TechnicalDataFloatItem>;
  /**
   * Vehicle weight without load.
   * f.ex.: 2505 kg
   */
  unladenWeight?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of component used for transmitting the power from a rotating power source to the wheels or other relevant component(s) ("gearbox" for cars)
   * (https://schema.org/vehicleTransmission)
   * f.ex.: Allradantrieb quattro
   */
  vehicleTransmission?: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of the loaded vehicle, including passengers and cargo and the weight of the empty vehicle.
   * https://auto.schema.org/weightTotal
   * f.ex.: 3045 kg
   */
  weightTotal?: Maybe<TechnicalDataFloatItem>;
};

/** Node for all data regarding energy consumption labeling regulation on model level */
export type ModelTechnicalDataEnvkv = {
  __typename?: 'ModelTechnicalDataEnvkv';
  /** Energy Efficiency Class per wltp phase method on model level */
  eecModelPerEnergyManagementWltp?: Maybe<Array<ModelEecPerEnergyManagementWltp>>;
};

export type MonthlyRate = {
  __typename?: 'MonthlyRate';
  currency?: Maybe<FinancingCurrency>;
  formattedValue?: Maybe<Scalars['String']['output']>;
  rawValue?: Maybe<Scalars['Float']['output']>;
  valueAsText?: Maybe<Scalars['String']['output']>;
};

export type MonthlyRateResult = FinancingError | MonthlyRate;

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated not implemented */
  configurationChange: Configuration;
};

export type MutationConfigurationChangeArgs = {
  change: ChangeAction;
  configIdentifier: ConfigurationIdentifier;
  marketContext: MarketContext;
};

/** Node containing consumption and emission values for NEDC testing method */
export type NedcTestMethod = {
  __typename?: 'NedcTestMethod';
  /** Set of values for fuel consumption */
  consumption?: Maybe<NedcValues>;
  /** Set of values for CO2 emissions */
  emissionCO2?: Maybe<NedcValues>;
  /** Value for NO2 emissions */
  emissionNO2?: Maybe<TechnicalDataFloatItem>;
};

/** consumption or emission values for NEDC testing method */
export type NedcValues = {
  __typename?: 'NedcValues';
  /** combined range value for consumption or emission profile */
  combined?: Maybe<RangeInfo>;
  /** consolidated range value may or may not be present depending on a factual decision about value to be shown */
  consolidated?: Maybe<RangeInfo>;
  /** consumption or emission range value for extraUrban profile */
  extraUrban?: Maybe<RangeInfo>;
  /** consumption or emission range value for urban profile */
  urban?: Maybe<RangeInfo>;
};

export type NonFinanceableTypedPrice = TypedPrice & {
  __typename?: 'NonFinanceableTypedPrice';
  price: Price;
  type: Scalars['String']['output'];
};

/** consumption or emission values for non WLTP Markets (Canada, US, Singapore) */
export type NonWltpTestMethod = {
  __typename?: 'NonWltpTestMethod';
  consumption: NonWltpValues;
  emissionCO2?: Maybe<TechnicalDataFloatItem>;
  emissionNO2?: Maybe<TechnicalDataFloatItem>;
};

/** consumption or emission values for non WLTP Markets (Canada, US, Singapore) */
export type NonWltpValues = {
  __typename?: 'NonWltpValues';
  /** combined value for consumption or emission profile */
  combined?: Maybe<TechnicalDataFloatItem>;
  /** consolidated value may or may not be present depending on a factual decision about value to be shown */
  consolidated?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for extraUrban profile */
  extraUrban?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for urban profile */
  urban?: Maybe<TechnicalDataFloatItem>;
};

/** Describes the optional data sent to Okapi */
export type OkapiData = {
  __typename?: 'OkapiData';
  /**
   * code for the vehicles base type
   * example: BASETYPE:8WDA7G
   */
  baseTypeCode?: Maybe<Scalars['String']['output']>;
  /**
   * Brand identifier
   * example: 9a98a6cc-090e-5737-bf07-97faf72f12c6
   */
  brandId?: Maybe<Scalars['String']['output']>;
  /**
   * code for the entity
   * example: TYPE:8WDA7G
   */
  code?: Maybe<Scalars['String']['output']>;
  /** free text describing the vehicle */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Identifier
   * example: 9a98a6cc-090e-5737-bf07-97faf72f12c6
   */
  id?: Maybe<Scalars['String']['output']>;
  /**
   * Model identifier
   * example: 9a98a6cc-090e-5737-bf07-97faf72f12c6
   */
  modelId?: Maybe<Scalars['String']['output']>;
  /**
   * The model year
   * example: MODELYEAR:2024
   */
  modelYearCode?: Maybe<Scalars['String']['output']>;
  /**
   * Shortcode
   * example: SHORTENER:E00
   */
  shortCode?: Maybe<Scalars['String']['output']>;
  /**
   * model version
   * example: 2
   */
  version?: Maybe<Scalars['String']['output']>;
};

export type OneGraphInfo = {
  __typename?: 'OneGraphInfo';
  audicodeGraphqlInfo: AudicodeGraphqlInfo;
  carlineInfoServiceInfo: CarlineInfoServiceInfo;
  colaServiceInfo: ColaServiceInfo;
  financingGraphqlInfo: FinancingGraphqlInfo;
  tedasServiceInfo: TedasServiceInfo;
  /** Version of the OneGraph */
  version: Scalars['String']['output'];
};

/**
 * MetaInfo of the service
 * The service might be the OneGraph itself, the sub services or the downstream service
 */
export type OneGraphServiceContactInfo = {
  __typename?: 'OneGraphServiceContactInfo';
  /** Email of the contact */
  email?: Maybe<Scalars['String']['output']>;
  /**
   * Name of the contact.
   * Provide title, fistname and lastname in one field
   */
  name: Scalars['String']['output'];
  /** Role of the contact */
  role: OneGraphServiceContactRoleEnum;
};

/**
 * TODO; Verify the roles (Complete?)
 * The role of a contact
 */
export enum OneGraphServiceContactRoleEnum {
  /** Role Developer */
  Developer = 'DEVELOPER',
  /**
   * Role Product Owner
   * In an Audi Context an Audi reponsibility
   */
  ProductOwner = 'PRODUCT_OWNER',
  /** Role Proxy Product Owner */
  ProxyProductOwner = 'PROXY_PRODUCT_OWNER',
  /**
   * Support
   * Might be a support contact whithin the service or an exteranl support supplier
   */
  Support = 'SUPPORT',
}

/**
 * There are currently two locations in the info graph where service health might be indicated
 * - OneGraphSubService: Should indicate health of one (or more) downstreamservice
 * - OneGraphDownStreamService: Should health of downstream service. This should be requested by the SubService
 */
export type OneGraphServiceHealth = {
  __typename?: 'OneGraphServiceHealth';
  /**
   * URL of the check endpoint (might be important for troubleshooting and support)
   * @deprecated Removed without replacement, so that check urls are not exposed.
   */
  checkEndpoint?: Maybe<Scalars['String']['output']>;
  /** Code (might be an error code of the Sub/DownStream-Service or http error code) */
  code?: Maybe<Scalars['String']['output']>;
  /** Message related to the code */
  message?: Maybe<Scalars['String']['output']>;
  /** Status of the Service */
  status: OneGraphServiceStatusEnum;
};

/**
 * Meta information about the Service
 * (Might be the OneGraph, the Subservices or the DownstreamServices)
 */
export type OneGraphServiceMetaInfo = {
  __typename?: 'OneGraphServiceMetaInfo';
  /**
   * URL of an direct communication channel.
   * In an Audi context a link to a rocket chat channel
   */
  channel?: Maybe<Scalars['String']['output']>;
  /** Contact info for the service. */
  contact?: Maybe<Array<OneGraphServiceContactInfo>>;
  /**
   * Homepage of the service to get more detailled information.
   * In an Audi context a link to a confluence page.
   * Might be also an link to the specification/support of this service
   */
  homepage: Scalars['String']['output'];
  /**
   * URL to the issue tracker.
   * In an Audi context a link to a jira project
   */
  issueTracker: Scalars['String']['output'];
  /** Repository for the source code of the service */
  repo?: Maybe<Scalars['String']['output']>;
  /** Name of the team which is responsible for the service */
  team: Scalars['String']['output'];
};

/**
 * Indicates the service status
 * Derived from https://datatracker.ietf.org/doc/html/draft-inadarei-api-health-check-05#section-3.1
 */
export enum OneGraphServiceStatusEnum {
  /** Failure in Service */
  Fail = 'FAIL',
  /** Service is ok */
  Pass = 'PASS',
  /** Warning in Service */
  Warn = 'WARN',
}

/**
 * TODO: Separate types for different kinds of downstream services?
 * Info about a downstream service.
 * A donwstream service is an external ressource which will be used by a aub service.
 * This can be a Rest/GraphQL/SOAP-Endppoint or a database or something else
 */
export type OneGraphSubServiceDownStreamInfo = {
  __typename?: 'OneGraphSubServiceDownStreamInfo';
  /** Endpoint of the downstream service */
  endpoint?: Maybe<Scalars['String']['output']>;
  /** Healthinfo about the downstream service */
  health?: Maybe<OneGraphServiceHealth>;
  /**
   * ID of the downstream service.
   * Fill something that makews sense in the context of the downstream service
   * f.ex. a REST-ressource might deliver the version of the API
   */
  id: Scalars['ID']['output'];
  /** MetaInfo about the downstream service */
  meta?: Maybe<OneGraphServiceMetaInfo>;
  /** Name of the downstream service */
  name: Scalars['String']['output'];
};

/**
 * Interface thas has to implemented by each subservice opf the OneGraph
 * TODO: Information about the running plattform (f.ex AWS, SPR, WebCentzer, Cocos,...)?
 * TODO: Programming language and frameworks?
 */
export type OneGraphSubServiceInfo = {
  /** Endpoint of the sub service */
  endpoint: Scalars['String']['output'];
  /** Health of the sub service */
  health: OneGraphServiceHealth;
  /**
   * Identifier of the sub service
   * Might be the version, git commit hash, ....
   * Should be human readable
   */
  id: Scalars['ID']['output'];
  /** MetaInfo of the sub service */
  meta: OneGraphServiceMetaInfo;
  /** Name of the sub service */
  name: Scalars['String']['output'];
  /** External downstream services of the sub service */
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
};

export type OneGraphSubServiceStatus = {
  __typename?: 'OneGraphSubServiceStatus';
  /** Health status of the Subgraph service */
  health: OneGraphServiceHealth;
  /** The Name of the Subgraph service */
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar.catalog` */
export type Option = {
  __typename?: 'Option';
  containsOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  family: Family;
  financingParameters?: Maybe<FinancingParameters>;
  group: Group;
  hasDetails?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  images?: Maybe<Array<Image>>;
  isContainedInOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  itemGroup?: Maybe<ItemGroup>;
  itemType: Scalars['String']['output'];
  mbvId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<OptionPrice>;
  rate?: Maybe<PartRateResult>;
  status: Status;
  tyreLabels?: Maybe<Array<Maybe<TyreLabel>>>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type OptionPrice = {
  __typename?: 'OptionPrice';
  currency?: Maybe<Scalars['String']['output']>;
  formattedValue?: Maybe<Scalars['String']['output']>;
  priceMode?: Maybe<PriceMode>;
  rawValue?: Maybe<Scalars['Float']['output']>;
};

/**
 * Pagination - we're 'blocking' this type to make sure that it isn't be used outside core definition.
 * TODO: check original type
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether there's a next page */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether there's a previous page */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type PartRateResult = FinanceablePartRate | FinancingError;

/** information to specify a dealer */
export type PartnerInfo = {
  /** use data of the dealer group instead of a single dealer */
  includeGroupStock?: InputMaybe<Scalars['Boolean']['input']>;
  /** KVPS id of the dealer */
  partnerId: Scalars['String']['input'];
};

/**
 * A type to represent a price and currency.
 * Add @shareable directive when carline-info
 * service has become Fed 2 compliant.
 */
export type Price = CarPrice & {
  __typename?: 'Price';
  /** @deprecated No longer supported */
  currency?: Maybe<Scalars['String']['output']>;
  /** Currency code ("EUR", "USD") & symbol ("€", "$") */
  currencyDetails?: Maybe<Currency>;
  /** Formatted display value. Example: "9,99 EUR" */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  rawValue?: Maybe<Scalars['String']['output']>;
  /** The actual price as a number. Example: 9,99 */
  value: Scalars['Float']['output'];
  /** Only the number as text. Example: "9,99" */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

export type PriceDate = {
  __typename?: 'PriceDate';
  date?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** @deprecated queries deprecated */
export enum PriceMode {
  Price = 'PRICE',
  Rate = 'RATE',
}

/** @deprecated data moved into `ConfiguredCar` */
export type Prices = {
  __typename?: 'Prices';
  base?: Maybe<Price>;
  bpm?: Maybe<Price>;
  carlineBase?: Maybe<Price>;
  charges?: Maybe<Price>;
  financeableTotal?: Maybe<Price>;
  /** @deprecated financing information moved into financingParameters */
  financing?: Maybe<Financing>;
  financingParameters?: Maybe<FinancingParameters>;
  monthlyRate?: Maybe<MonthlyRateResult>;
  nettoBase?: Maybe<Price>;
  nettoOptions?: Maybe<Price>;
  nonFinanceableTotal?: Maybe<Price>;
  options?: Maybe<Price>;
  rotr?: Maybe<Price>;
  rotrRate?: Maybe<Price>;
  servicePackage?: Maybe<Price>;
  taxTotal?: Maybe<Price>;
  taxTotalCO2?: Maybe<Price>;
  taxTotalExclScrapFree?: Maybe<Price>;
  taxTotalNOx?: Maybe<Price>;
  taxTotalWeight?: Maybe<Price>;
  taxableBenefit1?: Maybe<Price>;
  taxableBenefit2?: Maybe<Price>;
  total?: Maybe<Price>;
  /** @deprecated No longer supported */
  totalRate?: Maybe<Price>;
  totalWithCharges?: Maybe<Price>;
  vat?: Maybe<Price>;
};

/** List of Query Operations */
export type Query = {
  __typename?: 'Query';
  /** A query to accept the current selected conflict solutions */
  acceptConflictSolutions: AcceptConflictSolutionsPayload;
  /** information about the stocks of a market */
  availableStock: AvailableStock;
  carByCode?: Maybe<AudiCodeCar>;
  carCodeTechnicalData: ConfiguredCarTechnicalData;
  carline: CarlineStructureCarline;
  carlineGroup: CarlineGroup;
  carlineStructure: CarlineStructure;
  carlineStructureForStockCars: CarlineStructure;
  /** A query to change a configured car by adding or removing a feature. */
  changeConfiguredCarFeature: ChangeConfiguredCarPayload;
  /** A query to change a configured car's model. */
  changeConfiguredCarModel: ChangeConfiguredCarPayload;
  /** A query to change a configured car's trimline. */
  changeConfiguredCarTrimline: ChangeConfiguredCarPayload;
  /** A query to change a conflict solution. */
  changeConflictSolutions: ChangeConflictSolutionsPayload;
  /** @deprecated Use query configuredCar. */
  configuration: Configuration;
  /** @deprecated Use query configuredCarByCarlineWithFeatures. */
  configurationInitWithItems: Configuration;
  /** Returns the configured car defined by the input object */
  configuredCar: ConfiguredCar;
  /** Resolve the default configuration for the given carline. */
  configuredCarByCarline: ConfiguredCar;
  /** Resolve the lowest priced ConfiguredCar for the given carline including the required features. */
  configuredCarByCarlineWithFeatures: ConfiguredCar;
  /**
   * Changes the given configuration by adding or removing a new feature. It additionally can auto-resolve buildability
   * conflicts and return status information about it.
   * @deprecated Please use the new query chnageConfigurationFeature
   */
  configuredCarChange: ChangedConfiguredCar;
  configuredCarPackages?: Maybe<Array<ConfigurationPackage>>;
  /**
   * Resolve the static catalog of a model.
   * The catalog can be matched with the dynamic catalog inside a configured car of the same model.
   */
  configuredCarStaticCatalog?: Maybe<Array<Maybe<ConfiguredCarStaticFeatureBase>>>;
  /** Query for technical data for configured car */
  configuredCarTechnicalData: ConfiguredCarTechnicalData;
  /** Generate a number of valid configurations based on a users natural language input */
  configuredCarsFromNaturalLanguage?: Maybe<ConfiguredCarsFromNaturalLanguageResponse>;
  /**
   * new query
   *
   * search dealers in a stock
   */
  dealerSearch: DealerSearch;
  /** @deprecated Use configuredCar instead */
  getConfiguredCar: ConfiguredCar;
  /** @deprecated Use configuredCarPackages instead */
  getConfiguredCarPackages?: Maybe<Array<ConfigurationPackage>>;
  /**
   * Query for technical data for configured car
   * @deprecated use `configuredCarTechnicalData` instead
   */
  getTechnicalDataForConfiguredCar: ConfiguredCarTechnicalData;
  getUrls: UrlResponse;
  getUrlsAve: UrlResponse;
  getUrlsDetails: UrlDetailResponse;
  /** @deprecated Use query configuredCar and corresponding catalog fields with optional filters. */
  itemsByGroup: Array<Option>;
  /** @deprecated use technicalDataByModel instead */
  modelTechnicalData?: Maybe<ModelTechnicalData>;
  /**
   * New query to get information for a single stock car
   * See RFC62 for more details
   */
  stockCar?: Maybe<StockCar>;
  /** query to get extended information for a single stock car */
  stockCarExtended?: Maybe<StockCarExtended>;
  /** new query */
  stockCarSearch: StockCarSearch;
  /** new query */
  stockCarSearchConfiguration: StockCarSearchConfiguration;
  /**
   * New query for checking the health status of the subgraph
   * See RFC005 for more details
   */
  stockcarServiceStatus: OneGraphSubServiceStatus;
  /** Query for retrieving technical data for a specific model */
  technicalDataByModel?: Maybe<ModelTechnicalData>;
};

/** List of Query Operations */
export type QueryAcceptConflictSolutionsArgs = {
  financingInput?: InputMaybe<FinancingInput>;
  input: AcceptConflictSolutionsInput;
};

/** List of Query Operations */
export type QueryAvailableStockArgs = {
  marketIdentifier: MarketIdentifierInput;
  partnerInfo?: InputMaybe<PartnerInfo>;
};

/** List of Query Operations */
export type QueryCarByCodeArgs = {
  code: Scalars['String']['input'];
};

/** List of Query Operations */
export type QueryCarCodeTechnicalDataArgs = {
  code: TedasCarCode;
};

/** List of Query Operations */
export type QueryCarlineArgs = {
  identifier: CarlineIdentifierInput;
};

/** List of Query Operations */
export type QueryCarlineGroupArgs = {
  identifier: CarlineGroupIdentifierInput;
};

/** List of Query Operations */
export type QueryCarlineStructureArgs = {
  identifier: CarlineStructureIdentifierInput;
};

/** List of Query Operations */
export type QueryCarlineStructureForStockCarsArgs = {
  filter?: InputMaybe<CarlineStructureForStockCarsFilterInput>;
  stockIdentifier: StockIdentifierInput;
};

/** List of Query Operations */
export type QueryChangeConfiguredCarFeatureArgs = {
  financingInput?: InputMaybe<FinancingInput>;
  input: ChangeConfiguredCarFeatureInput;
};

/** List of Query Operations */
export type QueryChangeConfiguredCarModelArgs = {
  financingInput?: InputMaybe<FinancingInput>;
  input: ChangeConfiguredCarModelInput;
};

/** List of Query Operations */
export type QueryChangeConfiguredCarTrimlineArgs = {
  financingInput?: InputMaybe<FinancingInput>;
  input: ChangeConfiguredCarTrimlineInput;
};

/** List of Query Operations */
export type QueryChangeConflictSolutionsArgs = {
  financingInput?: InputMaybe<FinancingInput>;
  input: ChangeConflictSolutionsInput;
};

/** List of Query Operations */
export type QueryConfigurationArgs = {
  configIdentifier: ConfigurationIdentifier;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/** List of Query Operations */
export type QueryConfigurationInitWithItemsArgs = {
  carline: Scalars['String']['input'];
  items: Array<Scalars['String']['input']>;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/** List of Query Operations */
export type QueryConfiguredCarArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  financingInput?: InputMaybe<FinancingInput>;
};

/** List of Query Operations */
export type QueryConfiguredCarByCarlineArgs = {
  carlineInput: ConfiguredCarByCarlineInput;
  financingInput?: InputMaybe<FinancingInput>;
};

/** List of Query Operations */
export type QueryConfiguredCarByCarlineWithFeaturesArgs = {
  carlineInput: ConfiguredCarByCarlineInput;
  features: Array<Scalars['String']['input']>;
  financingInput?: InputMaybe<FinancingInput>;
};

/** List of Query Operations */
export type QueryConfiguredCarChangeArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  featureChange: FeatureChange;
};

/** List of Query Operations */
export type QueryConfiguredCarPackagesArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  financingInput?: InputMaybe<FinancingInput>;
};

/** List of Query Operations */
export type QueryConfiguredCarStaticCatalogArgs = {
  input: ModelCatalogInput;
};

/** List of Query Operations */
export type QueryConfiguredCarTechnicalDataArgs = {
  identifier: TechnicalDataConfiguredCarInput;
};

/** List of Query Operations */
export type QueryConfiguredCarsFromNaturalLanguageArgs = {
  input: ConfiguredCarsFromNaturalLanguageInput;
};

/** List of Query Operations */
export type QueryDealerSearchArgs = {
  searchParameter?: InputMaybe<DealerSearchParameterInput>;
  stockIdentifier: StockIdentifierInput;
};

/** List of Query Operations */
export type QueryGetConfiguredCarArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
};

/** List of Query Operations */
export type QueryGetConfiguredCarPackagesArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  financingInput?: InputMaybe<FinancingInput>;
};

/** List of Query Operations */
export type QueryGetTechnicalDataForConfiguredCarArgs = {
  identifier: TechnicalDataConfiguredCarInput;
};

/** List of Query Operations */
export type QueryGetUrlsArgs = {
  urlRequest: UrlRequest;
};

/** List of Query Operations */
export type QueryGetUrlsAveArgs = {
  urlRequest: UrlRequestAve;
};

/** List of Query Operations */
export type QueryGetUrlsDetailsArgs = {
  urlRequest: UrlDetailRequest;
};

/** List of Query Operations */
export type QueryItemsByGroupArgs = {
  configIdentifier: ConfigurationIdentifier;
  itemGroup: ItemGroup;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/** List of Query Operations */
export type QueryModelTechnicalDataArgs = {
  marketId: MarketIdentifierInput;
  modelId: ModelIdentifierInput;
};

/** List of Query Operations */
export type QueryStockCarArgs = {
  stockCarIdentifier: StockCarIdentifierInput;
};

/** List of Query Operations */
export type QueryStockCarExtendedArgs = {
  stockCarIdentifier: StockCarIdentifierInput;
};

/** List of Query Operations */
export type QueryStockCarSearchArgs = {
  searchParameter?: InputMaybe<StockCarSearchParameterInput>;
  stockIdentifier: StockIdentifierInput;
};

/** List of Query Operations */
export type QueryStockCarSearchConfigurationArgs = {
  input: StockIdentifierInput;
};

/** List of Query Operations */
export type QueryTechnicalDataByModelArgs = {
  technicalDataByModelInput: TechnicalDataByModelInput;
};

export type RangeInfo = {
  __typename?: 'RangeInfo';
  label: Scalars['String']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
  remarks?: Maybe<Array<Maybe<Remark>>>;
  unit: Scalars['String']['output'];
};

/** ranges which have to be expressed via literals and not numbers */
export type RangeLevelInfo = {
  __typename?: 'RangeLevelInfo';
  /** extra info */
  label: Scalars['String']['output'];
  /** max of the range as it can literally be defined */
  max: Scalars['String']['output'];
  /** min of the range as it can literally be defined */
  min: Scalars['String']['output'];
  /** remarks, if present */
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source?: Maybe<Scalars['String']['output']>;
  /** unit of the range */
  unit: Scalars['String']['output'];
};

export type Remark = {
  __typename?: 'Remark';
  /** Unique ID of the remark */
  id: Scalars['String']['output'];
  /** Text of the remark */
  value?: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type RenderImages = {
  __typename?: 'RenderImages';
  closeupLightsFullscreen?: Maybe<Scalars['String']['output']>;
  closeupLightsLayerstage?: Maybe<Scalars['String']['output']>;
  closeupLightsStage?: Maybe<Scalars['String']['output']>;
  closeupWheelsFullscreen?: Maybe<Scalars['String']['output']>;
  closeupWheelsLayerstage?: Maybe<Scalars['String']['output']>;
  closeupWheelsStage?: Maybe<Scalars['String']['output']>;
  dashboardFullscreen?: Maybe<Scalars['String']['output']>;
  dashboardLayerstage?: Maybe<Scalars['String']['output']>;
  dashboardStage?: Maybe<Scalars['String']['output']>;
  exteriorBackFullscreen?: Maybe<Scalars['String']['output']>;
  exteriorBackLayerstage?: Maybe<Scalars['String']['output']>;
  exteriorBackStage?: Maybe<Scalars['String']['output']>;
  exteriorFrontCarstore?: Maybe<Scalars['String']['output']>;
  exteriorFrontCompare?: Maybe<Scalars['String']['output']>;
  exteriorFrontFullscreen?: Maybe<Scalars['String']['output']>;
  exteriorFrontLayerstage?: Maybe<Scalars['String']['output']>;
  exteriorFrontStage?: Maybe<Scalars['String']['output']>;
  exteriorFullscreen?: Maybe<Scalars['String']['output']>;
  exteriorLayerstage?: Maybe<Scalars['String']['output']>;
  exteriorModelfinder?: Maybe<Scalars['String']['output']>;
  exteriorStage?: Maybe<Scalars['String']['output']>;
  interiorFullscreen?: Maybe<Scalars['String']['output']>;
  interiorLayerstage?: Maybe<Scalars['String']['output']>;
  interiorStage?: Maybe<Scalars['String']['output']>;
  n2c01?: Maybe<Scalars['String']['output']>;
  n2c02?: Maybe<Scalars['String']['output']>;
  n2c12?: Maybe<Scalars['String']['output']>;
  n2c14?: Maybe<Scalars['String']['output']>;
  n2c15?: Maybe<Scalars['String']['output']>;
  n2n05?: Maybe<Scalars['String']['output']>;
  n2n06?: Maybe<Scalars['String']['output']>;
  n2n10?: Maybe<Scalars['String']['output']>;
  n3c01?: Maybe<Scalars['String']['output']>;
  n3c02?: Maybe<Scalars['String']['output']>;
  n3c12?: Maybe<Scalars['String']['output']>;
  n3c14?: Maybe<Scalars['String']['output']>;
  n3c15?: Maybe<Scalars['String']['output']>;
  n3n05?: Maybe<Scalars['String']['output']>;
  n3n06?: Maybe<Scalars['String']['output']>;
  n3n10?: Maybe<Scalars['String']['output']>;
  n4c01?: Maybe<Scalars['String']['output']>;
  n4c02?: Maybe<Scalars['String']['output']>;
  n4c12?: Maybe<Scalars['String']['output']>;
  n4c14?: Maybe<Scalars['String']['output']>;
  n4c15?: Maybe<Scalars['String']['output']>;
  n4n05?: Maybe<Scalars['String']['output']>;
  n4n06?: Maybe<Scalars['String']['output']>;
  n4n10?: Maybe<Scalars['String']['output']>;
  n5c01?: Maybe<Scalars['String']['output']>;
  n8c01?: Maybe<Scalars['String']['output']>;
  n9c14?: Maybe<Scalars['String']['output']>;
  rearseatFullscreen?: Maybe<Scalars['String']['output']>;
  rearseatLayerstage?: Maybe<Scalars['String']['output']>;
  rearseatStage?: Maybe<Scalars['String']['output']>;
};

/** Represents a rendered image of a car inclduing metadata */
export type RenderedCarImage = {
  __typename?: 'RenderedCarImage';
  /** Category, e.g. EXTERIOR. */
  category: RenderedImagesFilterCategory;
  /**
   * Convertible Roof: OPEN or CLOSED.
   * Will always be CLOSED
   * @deprecated No longer differentiated in backend.
   */
  convertibleRoof: RenderedImagesConvertibleRoof;
  /**
   * Device type, e.g. MOBILE or DESKTOP.
   * Will aways be DESKTOP
   * @deprecated No longer differentiated in backend.
   */
  deviceType: RenderedImagesDeviceType;
  /** True when the fallbackUrl was provided in the url field. This may be the case when RenderService cannot provide the real image. */
  fallbackUrlUsed: Scalars['Boolean']['output'];
  /** MIME type of the image. */
  mimeType: Scalars['String']['output'];
  /**
   * profileId identifies a profile which describes an associated image including its metadata like view, and category.
   * A profile may also have variants for different device types like MOBILE and DESKTOP as well as options for having a convertible's roof open or closed.
   * The profileId follows the format <CATEGORY>-<VIEW>-<human readable name of the image>
   * profileId, e.g. EXTERIOR-FRONT-3-4
   */
  profileId: Scalars['String']['output'];
  /** Id of the image known to renderservice, e.g. ngccfront34closedbg. */
  renderServiceId: Scalars['String']['output'];
  /** Resolution of the rendered image */
  resolution: ImageResolution;
  /**
   * URL for the image.
   * If the real url of the image cannot be provided the fallbackUrl will be provided here and `fallbackUrlUsed` is set to true. The fallback image will have the same geometry as the original image.
   */
  url: Scalars['String']['output'];
  /** View, e.g. FRONT. */
  view: RenderedImagesFilterView;
  /** Image width in pixels. */
  width: Scalars['Int']['output'];
};

/** Filter options for retrieval of rendered car images */
export type RenderedCarImagesFilterInput = {
  /**
   * Filter profiles by category, e.g. INTERIOR, LIGHTS, EXTERIOR.
   * Multiple filters in the array behave like a logic ORwhen filtering with filterByView and filterByCategory the filters behave like a logic AND.
   * If filterByProfileId is set, this filter is ignored.
   */
  filterByCategory?: InputMaybe<Array<RenderedImagesFilterCategory>>;
  /**
   * Select one or multiple profiles directly by their profileId e.g. "EXTERIOR-FRONT-3-4". When using this filter, filterByView and filterByCategory will be ignored, since their properties are already defined in the profile.
   * The order of porfileIds in the array will be reflected in the output.
   */
  filterByProfileId?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Filter profiles by view, e.g. FRONT, REAR, PARALLEL.
   * Multiple filters in the array behave like a logic OR, when filtering with filterByView and filterByCategory the filters behave like a logic AND.
   * If filterByProfileId is set, this filter is ignored.
   */
  filterByView?: InputMaybe<Array<RenderedImagesFilterView>>;
};

/** Indicates whether the rendered convertible's roof is open or closed */
export enum RenderedImagesConvertibleRoof {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

/** Device type used in RenderedCarImage */
export enum RenderedImagesDeviceType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
}

/** Used as filter option to retrieve rendered car images. Filters the iamge's category */
export enum RenderedImagesFilterCategory {
  Exterior = 'EXTERIOR',
  Interior = 'INTERIOR',
  Lights = 'LIGHTS',
  Unknown = 'UNKNOWN',
}

/** Used as filter option to retrieve rendered car iamges. Filters the view of the car */
export enum RenderedImagesFilterView {
  Dashboard = 'DASHBOARD',
  Front = 'FRONT',
  Front3_4 = 'FRONT3_4',
  Frontseats = 'FRONTSEATS',
  Headlight = 'HEADLIGHT',
  Lights = 'LIGHTS',
  Parallel = 'PARALLEL',
  Rear = 'REAR',
  Rear3_4 = 'REAR3_4',
  Rearseats = 'REARSEATS',
  Rim = 'RIM',
  Seats = 'SEATS',
  Side = 'SIDE',
  Unknown = 'UNKNOWN',
  Wheels = 'WHEELS',
}

/** enum describing all possible changes between two audi codes (history) */
export enum RepairCode {
  /** Accessory has a different id (but the same name) */
  AozChanged = 'AOZ_CHANGED',
  /** Accessory no longer found */
  AozDropped = 'AOZ_DROPPED',
  /** Accessory has a different name */
  AozNameChanged = 'AOZ_NAME_CHANGED',
  /** Accessory has a different price */
  AozPriceChanged = 'AOZ_PRICE_CHANGED',
  /** change of model 6-digit base code */
  BaseModelChange = 'BASE_MODEL_CHANGE',
  /** change of salesgroup/handbuch */
  ChangedSalesgroup = 'CHANGED_SALESGROUP',
  /** extcolor code has changed */
  ExtcolorChanged = 'EXTCOLOR_CHANGED',
  /** extcolor name has changed */
  ExtcolorChangedName = 'EXTCOLOR_CHANGED_NAME',
  /** extcolor price has changed */
  ExtcolorChangedPrice = 'EXTCOLOR_CHANGED_PRICE',
  /** Incentive no longer found */
  IncentiveDropped = 'INCENTIVE_DROPPED',
  /** intcolor code has changed */
  IntcolorChanged = 'INTCOLOR_CHANGED',
  /** intcolor name has changed */
  IntcolorChangedName = 'INTCOLOR_CHANGED_NAME',
  /** change of model price */
  ModelChangedPrice = 'MODEL_CHANGED_PRICE',
  /** version digit update */
  ModelChangedVersion = 'MODEL_CHANGED_VERSION',
  /** model is forbidden, e.g. by AGIS rule */
  ModelIsForbidden = 'MODEL_IS_FORBIDDEN',
  /** model is hidden, e.g. by visibility rule */
  ModelIsHidden = 'MODEL_IS_HIDDEN',
  /** change of model name */
  ModelNameChange = 'MODEL_NAME_CHANGE',
  /** change of modelyear (2019 -> 2020) */
  NewModelyear = 'NEW_MODELYEAR',
  /** added to fix the conflict */
  OptionAddedDueToConflict = 'OPTION_ADDED_DUE_TO_CONFLICT',
  /** option is still there, but with a changed price */
  OptionChangedPrice = 'OPTION_CHANGED_PRICE',
  /** option no longer there, not even as a standard */
  OptionDropped = 'OPTION_DROPPED',
  /** there was a remove-only conflict */
  OptionDroppedDueToConflict = 'OPTION_DROPPED_DUE_TO_CONFLICT',
  /** option is now standard */
  OptionNowStandard = 'OPTION_NOW_STANDARD',
  /** change of model extension */
  TrimlineChangedExtension = 'TRIMLINE_CHANGED_EXTENSION',
}

/** type describing all recorded changes between two audi codes (history) */
export type RescueInfo = {
  __typename?: 'RescueInfo';
  /** wether or not the audicode rescue info could be obtained. Example: "true" */
  hasRescueInfo: Scalars['Boolean']['output'];
  /** enumeration of known changes between two audi codes (history) */
  history?: Maybe<Array<RescueInfoEventLog>>;
  /** human readible status. Example: "Der Audi Code konnte nicht gefunden werden." */
  message: Scalars['String']['output'];
};

/** type describing a single recorded change between two audi codes (history) */
export type RescueInfoEventLog = {
  __typename?: 'RescueInfoEventLog';
  /** Localized human readible description - short. Example: "Ausstattungen" */
  label?: Maybe<Scalars['String']['output']>;
  /** Localized human readible description. Example: "Serienaustattungen und technische Daten können sich geändert haben." */
  message?: Maybe<Scalars['String']['output']>;
  /** PR String encoding current configuration. Example: "FYGAUY\\0" */
  newCode?: Maybe<Scalars['String']['output']>;
  /** Human readible description of the newCode, if present. */
  newName?: Maybe<Scalars['String']['output']>;
  /** Formatted price of the newCode, if present. */
  newPrice?: Maybe<AudiCodePrice>;
  /** PR String encoding previous configuration. Example: "FYGAUY\\2\\MSNRS18" */
  oldCode?: Maybe<Scalars['String']['output']>;
  /** Human readible description of the oldCode. Example: "Q5 40 TDI quattro S tronic"  */
  oldName?: Maybe<Scalars['String']['output']>;
  /** Formatted price of the oldCode, if present. Example: "52.450,00 EUR" */
  oldPrice?: Maybe<AudiCodePrice>;
  /** event type constrained to enum */
  repairCode?: Maybe<RepairCode>;
};

/** Information for searchgroup depending on passed parameter */
export type SearchGroupNode = {
  __typename?: 'SearchGroupNode';
  /** Nodes in tree */
  nodes: Array<SearchGroupNode>;
  /** selectable search criteria */
  selectable?: Maybe<Selectable>;
  /** Text for searchgroup depending on level in tree */
  text: Scalars['String']['output'];
};

/** Selectable search criteria */
export type Selectable = {
  __typename?: 'Selectable';
  /** search criterion depending on parameter for search group */
  criterion: Scalars['String']['output'];
  /** Item code */
  item: Scalars['String']['output'];
};

/** Describes a selected option of the current configuration for the finance parameters */
export type SelectedFinanceablePart = {
  __typename?: 'SelectedFinanceablePart';
  /** E.g. '4L2' */
  pr3: Scalars['ID']['output'];
  /** E.g. 'MIRS4L2' */
  pr7: Scalars['ID']['output'];
  /** Always contains a price >= 0.0 */
  price: Scalars['Float']['output'];
  /** Mapping of the featureType */
  type: SelectedFinanceablePartType;
};

/**
 * Possible type for a SelectedFinanceablePart based on enum from the finance API
 *
 * API support more values, but we only provide these
 */
export enum SelectedFinanceablePartType {
  Accessory = 'ACCESSORY',
  Equipment = 'EQUIPMENT',
  Exterior = 'EXTERIOR',
  Interior = 'INTERIOR',
  Package = 'PACKAGE',
}

/** single found similar car */
export type SimilarCar = {
  __typename?: 'SimilarCar';
  /**
   * "new field"
   *
   * differences between similar car and reference configured car
   */
  compareCriteria: Array<SimilarCarCompareCriterion>;
  /** distance between geo position of the car and passed geo position from geo search */
  geoDistance?: Maybe<StockCarFloatValueWithUnitItem>;
  /** score of matching of similar car with reference in percent */
  score: Scalars['Float']['output'];
  /** information about the similar car */
  stockCar: StockCar;
};

/** single found similar car */
export type SimilarCarCompareCriteriaArgs = {
  status?: InputMaybe<Array<SimilarCarCompareCriterionStatus>>;
};

/** A single difference between a similar car and a reference configured car */
export type SimilarCarCompareCriterion = {
  __typename?: 'SimilarCarCompareCriterion';
  /**
   * NOTE: This field will be optional in the future!
   * In the meantime, this field will return an empty string for its child fields if this field would be empty
   *
   * Category of the difference
   */
  category: SimilarCarCompareCriterionCategory;
  /** Language independent code of the difference */
  code: Scalars['String']['output'];
  /** Status of the difference in relation to the reference configured car */
  status: SimilarCarCompareCriterionStatus;
  /**
   * NOTE: This field will be optional in the future!
   * In the meantime, this field will return an empty string for missing values.
   *
   * Language dependent display text of the difference
   */
  text: Scalars['String']['output'];
  /** Criteria type */
  type: Scalars['String']['output'];
};

/** Category of the difference between a similar car and a reference configured car */
export type SimilarCarCompareCriterionCategory = {
  __typename?: 'SimilarCarCompareCriterionCategory';
  /**
   * NOTE: This field will return an empty string for missing values.
   *
   * Language independent code of category
   */
  code: Scalars['String']['output'];
  /**
   * NOTE: This field will be optional in the future!
   * In the meantime, this field will return an empty string for missing values.
   *
   * Language dependent display text of category
   */
  text: Scalars['String']['output'];
};

/** Only compare criteria which match the passed data will be returned. */
export type SimilarCarCompareCriterionEntryInput = {
  /**
   * Category codes
   *
   * If this field is set, all criteria with the passed categories will be returned.
   */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Criterion codes
   *
   * If this field is set, all criteria with the passed codes will be returned
   */
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Criteria type
   *
   * If only this field is set, all criteria with this type will be returned.
   */
  type: Scalars['String']['input'];
};

/**
 * This field defines which compare criteria should be returned.
 * If this field is not set, or set to null only compare criteria with type of \"FEATURE\" will be returned,
 * we do this to remain downward compatible. Older version of the SimilarCars service only ever returned compare criteria of the type \"FEATURE\"
 * If the subfields status and entries are either empty, not set or null then all compare criteria will be returned.
 * Otherwise the compare criteria will be filtered based on the contents of status and entries.
 */
export type SimilarCarCompareCriterionInput = {
  /** Only compare criteria which match the passed data will be returned. */
  entries?: InputMaybe<Array<SimilarCarCompareCriterionEntryInput>>;
  /** Only compare criteria with the passed status will be returned. */
  status?: InputMaybe<Array<SimilarCarCompareCriterionStatus>>;
};

/**
 * status of difference between similar car and reference configured car
 * (in relation to reference configured car)
 */
export enum SimilarCarCompareCriterionStatus {
  Additional = 'additional',
  Missing = 'missing',
}

/** result of similar cars search */
export type SimilarCars = {
  __typename?: 'SimilarCars';
  /** found similar cars */
  cars: Array<SimilarCar>;
};

/** settings for the precision of the model (code and year) for seeking similar cars */
export type SimilarCarsCarModelPrecisionInput = {
  /**
   * precision of the mbv model code of the reference configured car.
   * The value must be between 0 and 6. 6 is default.
   * It defines the number of the digits of the mbv model code of the reference configured car,
   * that must be matched in the similar cars.
   */
  codePrecision?: InputMaybe<Scalars['Int']['input']>;
  /**
   * range of model years for similar cars (based on the model year of the reference configured car).
   * By default the model year of the reference configured car is set.
   */
  yearsOffset?: InputMaybe<SimilarCarsCarModelYearRangeByOffsetInput>;
};

/**
 * range of model years for similar cars
 * the values are offsets based on the model year of the reference configured car
 * e.g. for the reference year 2023
 * 2 = 2025
 * 1 = 2024
 * 0 = 2023
 * -1 = 2022
 * -2 = 2021
 */
export type SimilarCarsCarModelYearRangeByOffsetInput = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

/**
 * A single set of custom weights for the similarity search, which can be based on
 *
 * * criteria type
 * * category code
 * * criterion code
 *
 * The customizing of weights of criteria based on multiple ordered levels. A higher order overrides a lesser order if the match the same criteria,
 * with criteria ordered from lesser to higher as follows:
 *
 * * defaultValue -> weight applies to all criteria
 * * only type set -> weight applies to all criteria of type 'type'
 * * type and category are set -> weight applies to all criteria of type 'type' and category 'category'
 * * type and code are set -> weight applies to all criteria of type 'type' and code 'code'. Category would be ignored, so an exception will be thrown by the subgraph.
 *
 * See concept: https://collaboration.msi.audi.com/confluence/x/oA96P for further explanations and examples.
 */
export type SimilarCarsCustomWeightInput = {
  /** Category code. Which category codes there are depends on the value of 'type'. Some types may not have categories. */
  category?: InputMaybe<Scalars['String']['input']>;
  /** Criterion code. Represents a single value that the criterion could assume. */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Criterion type, for example "FEATURE". */
  type: Scalars['String']['input'];
  /**
   *  Weight value.
   *
   * Should be a positive (or zero) integer.
   * The higher the weight is, the more important this criterion will be for the final score.
   * A weight of zero means that the criterion will not be relevant for the final score at all.
   */
  weight: Scalars['Int']['input'];
};

/**
 * Weights for calculate the score of a similar car.
 * Each weight is defined for one feature type (based on a KeyTool equipment category).
 * So each feature with this feature type gets the defined weight.
 * The value must be a positive integer. Default is 100.
 */
export type SimilarCarsFeatureTypeWeightsInput = {
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  engineAndTransmission?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  exterior?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  infotainment?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  interior?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  other?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  seats?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  steeringWheels?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  technologyAndSafety?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Use customValues instead, which allows you to generically define any weight you want. */
  wheelsAndTyres?: InputMaybe<Scalars['Int']['input']>;
};

/** Parameter for getting similar cars */
export type SimilarCarsInput = {
  /** Prefiltering criteria for similar cars */
  additionalParameters?: InputMaybe<Array<StockCarSearchParameterCriterionInput>>;
  /** Special prefiltering criteria for similar cars */
  additionalSpecialParameters?: InputMaybe<Array<StockCarSearchParameterSpecialCriterionInput>>;
  /**
   * This field defines which compare criteria should be returned.
   * If this field is not set, or set to null only compare criteria with type of \"FEATURE\" will be returned,
   * we do this to remain downward compatible. Older version of the SimilarCars service only ever returned compare criteria of the type \"FEATURE\"
   * If the subfields status and entries are either empty, not set or null then all compare criteria will be returned.
   * Otherwise the compare criteria will be filtered based on the contents of status and entries.
   */
  compareCriteria?: InputMaybe<SimilarCarCompareCriterionInput>;
  /** Parameter for geo search of stock cars */
  geoSearch?: InputMaybe<StockCarSearchParameterGeoCriterionInput>;
  /** Maximal number of returned similar cars. Default is 10. Maximum is 50. */
  maxNumberOfCars?: InputMaybe<Scalars['Int']['input']>;
  /**
   * By default the similar car should have the same model (code and year) like the reference configured car.
   * With this settings the precision of matching can be adjust.
   */
  modelPrecision?: InputMaybe<SimilarCarsCarModelPrecisionInput>;
  /**
   * Define the order of the returned similar cars
   * default is SCORE
   */
  sortCriterion?: InputMaybe<SimilarCarsSortCriterion>;
  /**
   * By default all parameter have the same relevance by calculate the score
   * with this settings the weights can be changed
   */
  weights?: InputMaybe<SimilarCarsWeightsInput>;
};

/**
 * "new enum"
 *
 * define the order of the returned similar cars
 */
export enum SimilarCarsSortCriterion {
  /**
   * Distance from closest.
   * If vehicles have same distance - first with highest score.
   * If score same - show cheapest.
   */
  Distance = 'DISTANCE',
  /**
   * Price from low to high.
   * If vehicles have same price - first with highest score.
   * If score same - first closest to user by distance.
   */
  Price = 'PRICE',
  /**
   * Score from high to low.
   * If vehicles have same score - first closest to user by distance.
   * If distance is same - show cheapest.
   */
  Score = 'SCORE',
}

/** similar cars in new car stock and used car stock */
export type SimilarCarsStock = {
  __typename?: 'SimilarCarsStock';
  /** similar cars in the new car stock */
  newCars?: Maybe<SimilarCars>;
  /** similar cars in the used car stock */
  usedCars?: Maybe<SimilarCars>;
};

/** similar cars in new car stock and used car stock */
export type SimilarCarsStockNewCarsArgs = {
  input?: InputMaybe<SimilarCarsInput>;
};

/** similar cars in new car stock and used car stock */
export type SimilarCarsStockUsedCarsArgs = {
  input?: InputMaybe<SimilarCarsInput>;
};

/** weights for calculate the score of a similar car */
export type SimilarCarsWeightsInput = {
  /**
   *  Custom weight values that can be set by
   * * criteria type
   * * category
   * * single criteria
   */
  customValues?: InputMaybe<Array<SimilarCarsCustomWeightInput>>;
  /** Default weight for all criteria without a custom weight. If not set, weights will default to 100. */
  defaultValue?: InputMaybe<Scalars['Int']['input']>;
  /**
   * weights by feature type (based on KeyTool equipment categories)
   * @deprecated Use customValues instead, which allows you to generically define any weight you want.
   */
  featureTypeWeights?: InputMaybe<SimilarCarsFeatureTypeWeightsInput>;
};

/** A Solution represents a solution or alternative to solve a conflict within one SolutionGroup. */
export type Solution = {
  __typename?: 'Solution';
  /** Defines whether the `feature` has to be added or removed to form a solution. */
  action: FeatureChangeAction;
  /** Defines which feature has to be added or removed to form a solution. */
  feature: ConfiguredCarFeature;
  /** the usage of the solution may come with additional side effects (e.g. would remove other features) */
  sideEffects: Array<SolutionSideEffect>;
};

/** A SolutionGroup represents a list of alternatives/solutions to solve a conflict. */
export type SolutionGroups = {
  __typename?: 'SolutionGroups';
  solutions: Array<Solution>;
};

/**
 * A Solution side effect represents a solution that
 * 1. will be automatically applied if the containing solution will be selected
 * OR
 * 2. has already occured while changing a configuration.
 */
export type SolutionSideEffect = {
  __typename?: 'SolutionSideEffect';
  /** Defines if the side effect is an addition or removal. */
  action: FeatureChangeAction;
  /** Defines the feature of the side effect. */
  feature: ConfiguredCarFeature;
};

/** The status of a configured model, package or feature */
export type Status = {
  __typename?: 'Status';
  available: Scalars['Boolean']['output'];
  changeable: Scalars['Boolean']['output'];
  inConflict: Scalars['Boolean']['output'];
  selected: Scalars['Boolean']['output'];
  standard: Scalars['Boolean']['output'];
};

/** information about one stock */
export type Stock = {
  __typename?: 'Stock';
  /** number of cars */
  amount: Scalars['Int']['output'];
  /** url for stock frontend */
  url: Scalars['String']['output'];
};

/** information about a Stock-Car */
export type StockCar = Car & {
  __typename?: 'StockCar';
  /** required information for Webstreaming */
  avpCode?: Maybe<Scalars['String']['output']>;
  /** field that delivers the businessModelCode for example "dealer_stock" */
  businessModelCode: Scalars['String']['output'];
  /** list of active campaigns which includes this stock car */
  campaigns: Array<StockCarCampaign>;
  /** Information about different prices types of a car */
  carPrices?: Maybe<Array<LabeledTypedPrice>>;
  /** Carline information of a car. */
  carline?: Maybe<StockCarCarline>;
  /** Kind of car. New car or used car. */
  cartypeText?: Maybe<Scalars['String']['output']>;
  /** information about the AudiCode of a car */
  code: StockCarCarCode;
  /**
   * "new field"
   *
   * Information about colors of a car, structured by interior-, exterior- and top color
   */
  colorInfo?: Maybe<StockCarColorInfo>;
  /** commission number of car(unique for a dealer in a stock) */
  commissionNumber: Scalars['String']['output'];
  /** information about a dealer of a car */
  dealer: DealerInfo;
  /** Information about the dealer group for this vehicle */
  dealerGroup?: Maybe<StockCarDealerGroup>;
  /** Free text provided by dealer, can contain HTML. */
  descriptionByDealer?: Maybe<Scalars['String']['output']>;
  /** Number of doors */
  doors?: Maybe<Scalars['Int']['output']>;
  /** Kind of drive. For example Frontwheeldrive. */
  driveText?: Maybe<Scalars['String']['output']>;
  /** Value of the selected dynamicAttribute queried by the id */
  dynamicAttributeValue?: Maybe<Scalars['String']['output']>;
  /** All dynamicAttributes for a car */
  dynamicAttributes: Array<StockCarStringItemWithId>;
  /** ELVIS key for emission decal */
  emissionDecalCode?: Maybe<Scalars['String']['output']>;
  /** Engine information of a car. */
  engineInfo?: Maybe<StockCarEngine>;
  /** EnVKV information */
  envkv?: Maybe<StockCarEnvkv>;
  /** StockCar equipment features provided from Carport/VehicleLifecycleService */
  features: Array<StockCarFeature>;
  /** all functions on demand (FoD) for the stock car */
  functionsOnDemand: Array<Maybe<StockCarFunctionOnDemand>>;
  /** Kind of emis badge like "D" */
  gearText?: Maybe<Scalars['String']['output']>;
  /** Information about general inspection date; like value:"neu", type:"text" */
  generalInspectionDateInfo?: Maybe<StockCarDateInfo>;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipment items mapped to features or assigned manually */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** unique id of a car in a stock */
  id: Scalars['String']['output'];
  /** images of car */
  images?: Maybe<Array<StockCarImage>>;
  /** Information about initial registration date; like value:"2023-06-24T16:11:17+02:00", type:"timestamp" */
  initialRegistrationDateInfo?: Maybe<StockCarDateInfo>;
  /** information to describe manufacturer specific attributes of a car */
  manufacturerSpecificItems: StockCarManufacturer;
  /** Contains meta data for this stock car */
  metaData?: Maybe<StockCarMetaData>;
  /** Mileage of a car described by unit, value and formatted value. */
  mileage?: Maybe<StockCarFloatValueWithUnitItem>;
  /** Model information of a car. */
  model?: Maybe<StockCarModel>;
  /** numberplate value for a single car */
  numberplate?: Maybe<Scalars['String']['output']>;
  /** numberplate indicator for a single car */
  numberplateIndicator?: Maybe<Scalars['String']['output']>;
  /** Kind of padding. For example "Leather seating surfaces". */
  paddingTypeText?: Maybe<Scalars['String']['output']>;
  /** Key and translated text for pre used information, like "School bus". */
  preUse?: Maybe<StockcarCodedTextItem>;
  /** Number of previous owners of a car. */
  preowners?: Maybe<Scalars['Int']['output']>;
  /**
   * Price information of a car.
   * @deprecated Use carPrices
   */
  prices?: Maybe<StockCarCarPrices>;
  /** StockCar quality labels, like 'GWPLUS' */
  qualityLabel: Array<StockCarQualityLabel>;
  /** Sales information of a car */
  salesInfo?: Maybe<StockCarSalesInfo>;
  /** field for UK market which delivers SKU */
  stockkeepingunit?: Maybe<Scalars['String']['output']>;
  /** Text used as subtitle. For example "2.0 TFSI quattro Progressiv 200 HP Tiptronic". */
  subtitleText?: Maybe<Scalars['String']['output']>;
  /** field for UK market which delivers tax band for Vehicle Excise Duty */
  taxBandVedCode?: Maybe<Scalars['String']['output']>;
  /** information to describe technical attributes of a car */
  techDataGroups: Array<StockCarTechDataGroup>;
  /** Text used as title. For example "2016 Audi Q3". */
  titleText?: Maybe<Scalars['String']['output']>;
  /** tyre label data */
  tyreLabels: Array<StockCarTyreLabelContainer>;
  /** field that delivers information if vat is reclaimable */
  vatReclaimable: Scalars['Boolean']['output'];
  /** Vehicle identification number of a car. */
  vin?: Maybe<Scalars['String']['output']>;
  /** StockCar warranties with conditions assigned to the car, like 'Neuwagengarantie' until '40000 km' */
  warranties: Array<StockCarWarranty>;
  /** link to the cars detail page */
  weblink?: Maybe<Scalars['String']['output']>;
};

/** information about a Stock-Car */
export type StockCarDynamicAttributeValueArgs = {
  id: Scalars['String']['input'];
};

/** information about a Stock-Car */
export type StockCarImagesArgs = {
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  imageIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Used as container to provide StockCarFeature elements grouped by assigned categories from CDB */
export type StockCarCdbCategory = {
  __typename?: 'StockCarCDBCategory';
  /** list of StockCarFeature elements assigned to this category */
  features: Array<StockCarFeature>;
  /** identifier of CDB category, like 'safety_security_shell.safety_security' */
  id: Scalars['String']['output'];
  /** description of category to be displayed in frontends, like 'Safety and Security' */
  label: Scalars['String']['output'];
};

/** Used as container to group StockCarCDBCategory elements by corresponding parent categories from CDB */
export type StockCarCdbCategoryGroup = {
  __typename?: 'StockCarCDBCategoryGroup';
  /** list of child elements represented as StockCarCDBCategory elements */
  categories: Array<StockCarCdbCategory>;
  /** identifier of CDB category, like 'safety_security_shell' */
  id: Scalars['String']['output'];
  /** description of category to be displayed in frontends, like 'Safety and Security' */
  label: Scalars['String']['output'];
};

/** Element containing an additional CDB attribute with corresponding texts */
export type StockCarCdbItem = {
  __typename?: 'StockCarCDBItem';
  /** identifier of data, like 'body-type' */
  id: Scalars['String']['output'];
  /** list of texts, identified by IDs */
  textInfos: Array<StockCarStringItemWithId>;
  /** corresponding text, like 'sportback' */
  value: Scalars['String']['output'];
};

/** information of a single campaign for stock cars */
export type StockCarCampaign = {
  __typename?: 'StockCarCampaign';
  benefit?: Maybe<Scalars['Float']['output']>;
  end?: Maybe<StockCarDateInfo>;
  frontends: Array<StockCarCampaignFrontend>;
  id: Scalars['String']['output'];
  kind?: Maybe<Scalars['String']['output']>;
  legalEntity?: Maybe<Scalars['String']['output']>;
  market?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  start?: Maybe<StockCarDateInfo>;
  type?: Maybe<Scalars['String']['output']>;
  typeDetail?: Maybe<Scalars['String']['output']>;
};

/** footer for campaign for stock cars */
export type StockCarCampaignFooter = {
  __typename?: 'StockCarCampaignFooter';
  footerText?: Maybe<Scalars['String']['output']>;
  index: Scalars['String']['output'];
};

/** localized frontend information of a single campaign for stock cars */
export type StockCarCampaignFrontend = {
  __typename?: 'StockCarCampaignFrontend';
  bannerBig?: Maybe<Scalars['String']['output']>;
  bannerSmall?: Maybe<Scalars['String']['output']>;
  benefitList?: Maybe<Scalars['String']['output']>;
  benefitText?: Maybe<Scalars['String']['output']>;
  buttonLabel?: Maybe<Scalars['String']['output']>;
  campaignDescription?: Maybe<Scalars['String']['output']>;
  campaignName?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  externalInfoLabel?: Maybe<Scalars['String']['output']>;
  externalInfoUrl?: Maybe<Scalars['String']['output']>;
  filterText?: Maybe<Scalars['String']['output']>;
  footers: Array<StockCarCampaignFooter>;
  headline?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  iconText?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  infoLayerIcon?: Maybe<Scalars['String']['output']>;
  infoLayerText?: Maybe<Scalars['String']['output']>;
  infoLayerUrl?: Maybe<Scalars['String']['output']>;
  infoLayerUrlText?: Maybe<Scalars['String']['output']>;
  infoText?: Maybe<Scalars['String']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  leadInfoTextCustomer?: Maybe<Scalars['String']['output']>;
  leadInfoTextDealer?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  micrositeUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** information about the AudiCode of a car */
export type StockCarCarCode = CarCode & {
  __typename?: 'StockCarCarCode';
  /** Country that the `id` is valid for. */
  country?: Maybe<Scalars['String']['output']>;
  /**
   * Unique identifier for the car.
   * Might be AudiCode, MarketingCode etc.
   */
  id: Scalars['String']['output'];
  /** Language that the `id` is valid for. */
  language?: Maybe<Scalars['String']['output']>;
  /** URL behind the Code (AudiCode). Can be used to open the car in a browser. */
  url: Scalars['String']['output'];
};

/** Identifier for a model of a car. Contains necessary Information about the car model */
export type StockCarCarModelIdentifier = CarModelIdentifier & {
  __typename?: 'StockCarCarModelIdentifier';
  /** model base code. Describes the model of the car. */
  code: Scalars['String']['output'];
  /** pr numbers of the car. Sometimes this PrNumbers are more like a marker. For example mark the trimline of the model. */
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  /** model version of the car. */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release. */
  year: Scalars['Int']['output'];
};

/** A specific price. */
export type StockCarCarPrice = CarPrice & {
  __typename?: 'StockCarCarPrice';
  /** Information about the currency */
  currencyDetails?: Maybe<Currency>;
  /** not supported yet */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** Price as float */
  value: Scalars['Float']['output'];
  /** price as string */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

/** All available prices for a car. */
export type StockCarCarPrices = CarPrices & {
  __typename?: 'StockCarCarPrices';
  /** The total price of the car. */
  total?: Maybe<StockCarCarPrice>;
};

/** Carline information */
export type StockCarCarline = CarlineBase & {
  __typename?: 'StockCarCarline';
  /** ID of the carline. F.ex. a4avant, a3sb, etc. */
  id: Scalars['String']['output'];
  /** Localized name of the carline. F.ex. A4 Avant, A3 Sportback, etc. */
  name: Scalars['String']['output'];
};

export type StockCarCo2Items = {
  __typename?: 'StockCarCo2Items';
  /** Code for combined class text */
  combinedClassText: Scalars['String']['output'];
  /** Code for empty battery class text */
  emptyBatteryClassText?: Maybe<Scalars['String']['output']>;
};

/** Color information for a single car */
export type StockCarColorInfo = {
  __typename?: 'StockCarColorInfo';
  /** exterior color information */
  exteriorColor?: Maybe<StockCarExteriorColor>;
  /** interior color information */
  interiorColor?: Maybe<StockCarInteriorColor>;
  /** top color information */
  topColor?: Maybe<StockCarTopColor>;
};

/** consumption or emission for single engine type */
export type StockCarConsumptionAndEmissionEngineType = {
  __typename?: 'StockCarConsumptionAndEmissionEngineType';
  /** identifier of engine type */
  id: Scalars['String']['output'];
  /** display text of engine type */
  label?: Maybe<Scalars['String']['output']>;
  /** NEDC data for engine type */
  nedc?: Maybe<StockCarConsumptionAndEmissionScope>;
  /** WLTP data for engine type */
  wltp?: Maybe<StockCarConsumptionAndEmissionScope>;
};

/** main structure for consumption and emission data for a stock car */
export type StockCarConsumptionAndEmissionGroup = {
  __typename?: 'StockCarConsumptionAndEmissionGroup';
  /** list of measured engine types */
  engineTypes: Array<StockCarConsumptionAndEmissionEngineType>;
  /** display text */
  label: Scalars['String']['output'];
};

/** visibility of a consumption and emission value */
export enum StockCarConsumptionAndEmissionHighlighting {
  /** showed on detail page */
  Detail = 'DETAIL',
  /** showed in extended view on detail page */
  Extended = 'EXTENDED',
  /** showed on result page */
  Result = 'RESULT',
}

/** information of a single driving cycle */
export type StockCarConsumptionAndEmissionScope = {
  __typename?: 'StockCarConsumptionAndEmissionScope';
  /**
   * name of driving cycle
   * @deprecated not available in most markets an not used in FE. Will be removed in a further step.
   */
  label: Scalars['String']['output'];
  /**
   * link with further information about the driving cycle
   * @deprecated not available in most markets an not used in FE. Will be removed in a further step.
   */
  link: StockCarLabeledUrlValueItem;
  /** list of measured fuels */
  types: Array<StockCarConsumptionAndEmissionType>;
};

/** kind of fuel */
export type StockCarConsumptionAndEmissionType = {
  __typename?: 'StockCarConsumptionAndEmissionType';
  /** identifier of type */
  id: Scalars['String']['output'];
  /** display text for type */
  label: Scalars['String']['output'];
  /** list of measured values */
  values: Array<StockCarConsumptionAndEmissionValue>;
};

/** kind of fuel */
export type StockCarConsumptionAndEmissionTypeValuesArgs = {
  highlighting?: InputMaybe<Array<StockCarConsumptionAndEmissionHighlighting>>;
};

/** single measurement value for consumption and emission */
export type StockCarConsumptionAndEmissionValue = {
  __typename?: 'StockCarConsumptionAndEmissionValue';
  /** visibility information */
  highlighting: Array<StockCarConsumptionAndEmissionHighlighting>;
  /** identifier of value */
  id: Scalars['String']['output'];
  /** label (as display text) */
  label: Scalars['String']['output'];
  /** unit (as display text) */
  unitText: Scalars['String']['output'];
  /** value (as display text) */
  valueText: Scalars['String']['output'];
};

/** Used as a container to provide parseable date information or plain texts, identified by type. */
export type StockCarDateInfo = {
  __typename?: 'StockCarDateInfo';
  /** type of value, can be timestamp or text */
  type?: Maybe<Scalars['String']['output']>;
  /** text representation of a value, outcome defined by type */
  value?: Maybe<Scalars['String']['output']>;
};

/** Information about a dealer group */
export type StockCarDealerGroup = {
  __typename?: 'StockCarDealerGroup';
  /** List of dealers in the group */
  dealers: Array<StockCarDealerGroupDealer>;
  /** group name */
  groupName?: Maybe<Scalars['String']['output']>;
};

/** Information about a dealer in a dealer group */
export type StockCarDealerGroupDealer = {
  __typename?: 'StockCarDealerGroupDealer';
  /** geolocation of the dealer */
  geoLocation?: Maybe<GeoLocation>;
  /** hid of the dealer */
  hid?: Maybe<Scalars['String']['output']>;
  /** id of the dealer */
  id?: Maybe<Scalars['String']['output']>;
  /** investor code of the dealer */
  investorCode?: Maybe<Scalars['String']['output']>;
  /** name of the dealer */
  name?: Maybe<Scalars['String']['output']>;
};

/** Used as a data structure to provide engine related information of a car. */
export type StockCarEngine = {
  __typename?: 'StockCarEngine';
  /** consumption data */
  consumption?: Maybe<StockCarConsumptionAndEmissionGroup>;
  /** emission data */
  emission?: Maybe<StockCarConsumptionAndEmissionGroup>;
  /** Use of unit, value, formattedValue. For example "ccm", "1994", "1994 ccm". */
  engineDisplacement?: Maybe<StockCarFloatValueWithUnitItem>;
  /** Use of code and text. For example "B" and "Benzin". */
  fuel?: Maybe<StockcarCodedTextItem>;
  /** provide engine max power in kW with formattedValue, unit and float value */
  maxPowerKW?: Maybe<StockCarFloatValueWithUnitItem>;
  /** provide engine max power in PS with formattedValue, unit and float value */
  maxPowerPS?: Maybe<StockCarFloatValueWithUnitItem>;
  /** range data (for electrical cars) */
  range?: Maybe<StockCarConsumptionAndEmissionGroup>;
};

/**
 * EnVKV information for a car
 * The EnVKV information must have a specific format based on the currently valid legal provisions. This can be changed and in this case there could be multiple formats available.
 */
export type StockCarEnvkv = StockCarEnvkv2024 | StockCarEnvkvExtended;

/** EnVKV information for a car in new format due to new legal requirements. For further information please see Second Ordinance amending the Car Energy Consumption Labeling Ordinance. Bundesgesetzblatt FNA: 754-17-2. */
export type StockCarEnvkv2024 = {
  __typename?: 'StockCarEnvkv2024';
  /** Information of co2 class for a car */
  co2Class: StockCarCo2Items;
  /** End date for calculation of co2 price */
  co2CostEndYearText: Scalars['String']['output'];
  /** Start date for calculation of co2 price */
  co2CostStartYearText: Scalars['String']['output'];
  /** Informations about the co2 costs for a single car */
  co2Costs: StockCarEnvkv2024Co2CostsItems;
  /** consumption tax */
  consumptionTax: StockCarTextValueWithUnitItem;
  /** creation date of this EnVKV information */
  creationDateText: Scalars['String']['output'];
  /** default mileage */
  defaultMileage: StockCarTextValueWithUnitItem;
  /** Information about the kind of drive, for example "Verbrennungsmotor" */
  driveText: Scalars['String']['output'];
  /** Information about the the energy costs for a single car, like "1.641,20" "EUR/Jahr" */
  energyCosts: StockCarTextValueWithUnitItem;
  /** Reference year for fuel tariff */
  fuelTariffReferenceYearText: Scalars['String']['output'];
  /** URL for consumption and emission label in PDF format */
  labelUrlPDF: Scalars['String']['output'];
  /** URL for consumption and emission label in SVG format */
  labelUrlSVG: Scalars['String']['output'];
  /** Code for cartype */
  legalCartypeCode: Scalars['String']['output'];
  /** information for primary fuel */
  primaryFuel: StockCarEnvkv2024Fuel;
  /** information for secondary fuel */
  secondaryFuel?: Maybe<StockCarEnvkv2024Fuel>;
};

/** Information about co2 costs and tariff for a single estimation */
export type StockCarEnvkv2024Co2Costs = {
  __typename?: 'StockCarEnvkv2024Co2Costs';
  /** total co2 costs for the next 10 years with 15.000 km each, like "975,00" "EUR" */
  costs: StockCarTextValueWithUnitItem;
  /** tariff for calculation of the total co2 costs, like "50" "EUR/t" */
  tariff: StockCarTextValueWithUnitItem;
};

/** Co2 costs infomormation for different price estimations */
export type StockCarEnvkv2024Co2CostsItems = {
  __typename?: 'StockCarEnvkv2024Co2CostsItems';
  /** co2 costs for high estimation */
  high: StockCarEnvkv2024Co2Costs;
  /** co2 costs for low estimation */
  low: StockCarEnvkv2024Co2Costs;
  /** co2 costs for medium estimation */
  medium: StockCarEnvkv2024Co2Costs;
};

/** fuel information for EnVKV2024 */
export type StockCarEnvkv2024Fuel = {
  __typename?: 'StockCarEnvkv2024Fuel';
  /** consumption unit as display text */
  consumptionUnitText: Scalars['String']['output'];
  /** identifier for fuel */
  id: Scalars['String']['output'];
  /** price value for one unit of the fuel as display text */
  tariffText: Scalars['String']['output'];
  /** information about the tariff and unit, like "EUR/l" */
  tariffUnitText: Scalars['String']['output'];
  /** name of fuel */
  typeText: Scalars['String']['output'];
  /** unit of fuel as display text */
  unitText: Scalars['String']['output'];
};

/**
 * Deprecated use StockCarEnvkv2024 instead
 *
 * EnVKV information for a car (format before the amendment in 2024)
 */
export type StockCarEnvkvExtended = {
  __typename?: 'StockCarEnvkvExtended';
  /**
   * consumption tax
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  consumptionTax: StockCarLabeledTextValueWithUnitItem;
  /**
   * creation date of this EnVKV information
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  creationDate: StockCarLabeledDateInfo;
  /**
   * default mileage
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  defaultMileage: StockCarLabeledTextValueWithUnitItem;
  /**
   * efficiency class as display text
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  efficiencyClassText?: Maybe<Scalars['String']['output']>;
  /**
   * information for primary fuel
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  primaryFuel: StockCarEnvkvExtendedFuel;
  /**
   * information for secondary fuel
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  secondaryFuel?: Maybe<StockCarEnvkvExtendedFuel>;
};

/**
 * Deprecated
 *
 * fuel information for EnVKV extended
 */
export type StockCarEnvkvExtendedFuel = {
  __typename?: 'StockCarEnvkvExtendedFuel';
  /**
   * consumption unit as display text
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  consumptionUnitText: Scalars['String']['output'];
  /**
   * fuel costs
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  costs: StockCarLabeledTextValueWithUnitItem;
  /**
   * identifier for fuel
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  id: Scalars['String']['output'];
  /**
   * price value for one unit of the fuel as display text
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  tariffText: Scalars['String']['output'];
  /**
   * name of fuel
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  typeText: Scalars['String']['output'];
  /**
   * unit of fuel as display text
   * @deprecated StockCarEnvkvExtended will be removed. Use StockCarEnvkv2024 instead
   */
  unitText: Scalars['String']['output'];
};

/** extended information about a stockcar */
export type StockCarExtended = {
  __typename?: 'StockCarExtended';
  /** similar cars in new car stock and used car stock with this stockcar as reference */
  similarCars?: Maybe<SimilarCarsStock>;
  /** basic data for a stockcar */
  stockCar: StockCar;
};

/** Information about exterior color for a single car */
export type StockCarExteriorColor = {
  __typename?: 'StockCarExteriorColor';
  /** generic color information for all cars, like code:"07", text:"Black" */
  baseColorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of specific color, like code:"A2", text:"Brilliant Black" */
  colorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Exterior' */
  label?: Maybe<Scalars['String']['output']>;
};

/** Interface describing equipment features in StockCar context. */
export type StockCarFeature = {
  /** enum of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Implementation of StockCarFeature. Used to describe PrNumbers which are not Packages. Extends interface by field 'packages'. */
export type StockCarFeatureEquipment = StockCarFeature & {
  __typename?: 'StockCarFeatureEquipment';
  /** type of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** list of StockCarFeaturePackage containing this feature */
  packages: Array<StockCarFeaturePackage>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Implementation of StockCarFeature. Used to describe PrNumbers which are Packages. Extends interface by field 'features'. */
export type StockCarFeaturePackage = StockCarFeature & {
  __typename?: 'StockCarFeaturePackage';
  /** list of StockCarFeatureEquipment which are part of this package */
  equipments: Array<StockCarFeatureEquipment>;
  /** type of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Used as a container to provide texts from different sources assigned to a feature, identified by their original IDs. */
export type StockCarFeatureTextInfo = {
  __typename?: 'StockCarFeatureTextInfo';
  /** list of benefit texts without order from source multimedia DB (only Audi) */
  benefits: Array<Scalars['String']['output']>;
  /** details text from source multimedia DB (only Audi) */
  details?: Maybe<Scalars['String']['output']>;
  /** intro text from source multimedia DB (only Audi) */
  intro?: Maybe<Scalars['String']['output']>;
  /** text from source multimedia DB (only Audi) */
  name?: Maybe<Scalars['String']['output']>;
  /** list of remark texts without order from source multimedia DB (only Audi) */
  remarks: Array<Scalars['String']['output']>;
  /** teaser text from source multimedia DB (only Audi) */
  teaser?: Maybe<Scalars['String']['output']>;
};

/** Enum describing type of equipment feature, like 'SPECIAL' or 'SERIES' */
export enum StockCarFeatureType {
  Series = 'SERIES',
  Special = 'SPECIAL',
}

/** Used as a container to provide data in float format as a raw and formatted value */
export type StockCarFloatValueItem = {
  __typename?: 'StockCarFloatValueItem';
  /** formatted value for display */
  formatted: Scalars['String']['output'];
  /** float value */
  number: Scalars['Float']['output'];
};

/** Used as a container to provide data in float format as a raw and formatted value with a unit. */
export type StockCarFloatValueWithUnitItem = {
  __typename?: 'StockCarFloatValueWithUnitItem';
  /** formatted unit display text */
  unitText?: Maybe<Scalars['String']['output']>;
  /** formatted value for display */
  value: StockCarFloatValueItem;
};

/** information of a single function on demand (FoD) (for stock cars) */
export type StockCarFunctionOnDemand = {
  __typename?: 'StockCarFunctionOnDemand';
  /** machine readable category of the FoD */
  categoryCode?: Maybe<Scalars['String']['output']>;
  /** expiration date of the FoD, after which it is no longer active */
  dateExpiration?: Maybe<StockCarDateInfo>;
  /** signing date of the FoD */
  dateSigning?: Maybe<StockCarDateInfo>;
  /** start date of the FoD - starting from which it becomes active */
  dateStart?: Maybe<StockCarDateInfo>;
  /** human readable description of the FoD */
  description?: Maybe<Scalars['String']['output']>;
  /** timespan between start and expiration date of the FoD */
  duration?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** ID of holder of FoD. */
  holderId?: Maybe<Scalars['String']['output']>;
  /** end mileage of FoD. defaults to 999999. */
  mileageEnd?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** max mileage of FoD. defaults to 0. */
  mileageMax?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** start mileage of FoD. defaults to 0. */
  mileageStart?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** total mileage of FoD. defaults to 0. */
  mileageTotal?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** origin system of the FoD */
  originCode?: Maybe<Scalars['String']['output']>;
  /** whether the FoD is preliminary */
  preliminary?: Maybe<Scalars['Boolean']['output']>;
  /** machine-readable indicator of whether the FoD is active or not */
  statusCode?: Maybe<Scalars['String']['output']>;
  /** type of the FoD */
  type: StockCarFunctionOnDemandType;
};

/** type of a function on demand (FoD) */
export enum StockCarFunctionOnDemandType {
  /** FoD type "FoDLifetime" */
  FodLifetime = 'FOD_LIFETIME',
  /** FoD type "FoDRental" */
  FodRental = 'FOD_RENTAL',
  /** FoD type "ServiceAndWearOEM" */
  ServiceAndWearOem = 'SERVICE_AND_WEAR_OEM',
  /** FoD type "ServiceAndWearVWFS" */
  ServiceAndWearVwfs = 'SERVICE_AND_WEAR_VWFS',
  /** FoD type "ServiceContract" */
  ServiceContract = 'SERVICE_CONTRACT',
}

/** Used as a container to provide generic equipment items. Can also be used for 3rd party vehicles. */
export type StockCarGenericEquipment = {
  __typename?: 'StockCarGenericEquipment';
  /** list of mapped generic equipment categories for grouping purposes */
  categories: Array<StockCarGenericEquipmentCategory>;
  /** coded identifier of an equipment item, like 'AL' */
  code: Scalars['String']['output'];
  /** list of mapped StockCarFeatures, extending this generic information */
  features: Array<StockCarFeature>;
  /** textual description of an equipment item, like 'Lederausstattung' */
  text: Scalars['String']['output'];
};

/** Used as a container to provide categories for generic equipment items. */
export type StockCarGenericEquipmentCategory = {
  __typename?: 'StockCarGenericEquipmentCategory';
  /** coded identifier of an equipment item, like '6' */
  code: Scalars['String']['output'];
  /** list of generic equipments belonging to this category */
  equipments: Array<StockCarGenericEquipment>;
  /** textual description of an equipment item, like 'Seats' */
  text: Scalars['String']['output'];
};

export type StockCarIdentifierInput = {
  /** specify the id of one single vehicle */
  id: Scalars['String']['input'];
  /** specify the stock which contains the vehicle */
  stockIdentifier: StockIdentifierInput;
};

/** single image of a stock car */
export type StockCarImage = {
  __typename?: 'StockCarImage';
  /** height of image in pixels */
  height?: Maybe<Scalars['Int']['output']>;
  /** unique id of image in a stock car */
  id: StockCarImageIdentifier;
  /** MIME type of image e.g. 'image/png' */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** kind of image: e.g. 'render', 'photo' */
  type?: Maybe<Scalars['String']['output']>;
  /** url of image in a stock car */
  url: Scalars['String']['output'];
  /** width of image in pixels */
  width?: Maybe<Scalars['Int']['output']>;
};

/** unique id of image in a stock car */
export type StockCarImageIdentifier = {
  __typename?: 'StockCarImageIdentifier';
  /** group id of image in a stock car */
  group: Scalars['String']['output'];
  /** image id inside a group in a stock car */
  image: Scalars['String']['output'];
};

/** Information about interior color for a single car */
export type StockCarInteriorColor = {
  __typename?: 'StockCarInteriorColor';
  /** generic color information for all cars, like code:"07", text:"Black" */
  baseColorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of specific color, like code:"YA", text:"Myth-Black" */
  colorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Innenfarbe' */
  label?: Maybe<Scalars['String']['output']>;
};

/** date value with label */
export type StockCarLabeledDateInfo = {
  __typename?: 'StockCarLabeledDateInfo';
  /** date value */
  dateInfo: StockCarDateInfo;
  /** label (as display text) */
  label: Scalars['String']['output'];
};

/** (display) text value with label und unit */
export type StockCarLabeledTextValueWithUnitItem = {
  __typename?: 'StockCarLabeledTextValueWithUnitItem';
  /** label (as display text) */
  label: Scalars['String']['output'];
  /** value (as display text) */
  text: Scalars['String']['output'];
  /** unit (as display text) */
  unitText: Scalars['String']['output'];
};

/** url with display text */
export type StockCarLabeledUrlValueItem = {
  __typename?: 'StockCarLabeledUrlValueItem';
  /** label (as display text) */
  label: Scalars['String']['output'];
  /** url */
  url: Scalars['String']['output'];
};

/** union of different implementations for manufacturer specific items */
export type StockCarManufacturer = StockCarManufacturer3rdParty | StockCarManufacturerAudi;

/** implementations of manufacturer specific for not group vehicles, like 'BMW', 'Ford' */
export type StockCarManufacturer3rdParty = {
  __typename?: 'StockCarManufacturer3rdParty';
  /** Name of 3rd party manufacturer */
  name: Scalars['String']['output'];
};

/** implementations of manufacturer specific items for brand Audi */
export type StockCarManufacturerAudi = {
  __typename?: 'StockCarManufacturerAudi';
  /** list of StockCarFeature elements, grouped by CDB information */
  cdbCategories: Array<StockCarCdbCategoryGroup>;
  /** list of additional attributes of a car provided by CDB */
  cdbItems: Array<StockCarCdbItem>;
};

/** implementations of manufacturer specific items for brand Audi */
export type StockCarManufacturerAudiCdbItemsArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Container for stock car meta data */
export type StockCarMetaData = {
  __typename?: 'StockCarMetaData';
  /** Represents the data aggregator for the stock car */
  statImport?: Maybe<Scalars['String']['output']>;
};

/** Model information */
export type StockCarModel = CarModelBase & {
  __typename?: 'StockCarModel';
  /** Identifier of the model. */
  id: StockCarCarModelIdentifier;
  /** Name of the model */
  name?: Maybe<Scalars['String']['output']>;
  /** year model actually sold */
  salesModelyear?: Maybe<Scalars['Int']['output']>;
};

/** Used as a container to describe the 7 digit PrCode known from Carport. */
export type StockCarPrNumber = {
  __typename?: 'StockCarPrNumber';
  /** 1digit equipment class origin code, like 'L' */
  class: Scalars['String']['output'];
  /** 3digit option code, like 'AV1' */
  code: Scalars['String']['output'];
  /** 3digit family code, like 'LRV', can be empty in case of package */
  family: Scalars['String']['output'];
};

/** Used as a container to describe a 6 digit PrCode */
export type StockCarPrNumberWithoutClass = {
  __typename?: 'StockCarPrNumberWithoutClass';
  /** 3 digit option code, like 'AV1' */
  code: Scalars['String']['output'];
  /** 3 digit family code, like 'LRV', can be empty in case of package */
  family: Scalars['String']['output'];
};

/** Type to describe disclaimer information for price items (atm only for financeRate). */
export type StockCarPriceDisclaimer = {
  __typename?: 'StockCarPriceDisclaimer';
  /** Disclaimer id like "Product". */
  id: Scalars['String']['output'];
  /** Disclaimer legal entity key like "companyLeasingProvider". */
  legalEntityKey: Scalars['String']['output'];
  /** Disclaimer text. */
  text: Scalars['String']['output'];
};

export type StockCarQualityLabel = {
  __typename?: 'StockCarQualityLabel';
  /** hyperlink to image resource, like 'https://vtpapi.audi.de/vtp_base/img/audi/highlights-plus-warranty_60x45px.png' */
  imageURL?: Maybe<Scalars['String']['output']>;
  /** corresponding display text, like 'Audi Gebrauchtwagen :plus' */
  label: Scalars['String']['output'];
  /** additional legal information, like 'Dealers' */
  legalEntity?: Maybe<Scalars['String']['output']>;
  /** shorthand identifier of quality label, like 'GWPLUS' */
  name: Scalars['String']['output'];
};

/** raw integer value (without formatted value) and the corresponding unit */
export type StockCarRawIntValueWithUnitItem = {
  __typename?: 'StockCarRawIntValueWithUnitItem';
  /** unit (as display text) */
  unitText: Scalars['String']['output'];
  /** raw integer value */
  value: Scalars['Int']['output'];
};

/** Used as a data structure to provide sales info for different markets at one place */
export type StockCarSalesInfo = {
  __typename?: 'StockCarSalesInfo';
  /** Information about available status of a car; like value:"Immediately", type:"text" */
  availableFromDateInfo?: Maybe<StockCarDateInfo>;
  /** Information about e-commerce status of a car. */
  isBuyOnline?: Maybe<Scalars['Boolean']['output']>;
  /** Information about offer status of a car; like value:"2023-06-24T16:11:17+02:00", type:"timestamp" */
  offerDateInfo?: Maybe<StockCarDateInfo>;
  /** Information about order status like "Intransit From Factory". */
  orderStatusText?: Maybe<Scalars['String']['output']>;
  /** Information about the order status like "30". */
  physicalOrderStatus?: Maybe<Scalars['String']['output']>;
  /** Information about order type like "SHIP - Deliverable Inventory Order". */
  saleOrderTypeText?: Maybe<Scalars['String']['output']>;
};

/** Response of stockcar search for passed search parameter */
export type StockCarSearch = {
  __typename?: 'StockCarSearch';
  /** number of found cars */
  resultNumber: Scalars['Int']['output'];
  /** found cars */
  results: StockCarSearchResults;
  /** data for further search actions */
  search: StockCarSearchSearch;
};

/** All possible stockcar search configurations for a specific market */
export type StockCarSearchConfiguration = {
  __typename?: 'StockCarSearchConfiguration';
  /** meta-data for all available search options */
  criteria: Array<StockCarSearchCriterionConfiguration>;
  /** search groups */
  searchGroup: SearchGroupNode;
  /** meta-data for all available sort options */
  sortableItems: Array<StockCarSearchSortableItemsConfiguration>;
  /** meta-data for all available special search options (with unrestricted 'values') */
  specialCriteria: Array<StockCarSearchSpecialCriterionConfiguration>;
};

/** All possible stockcar search configurations for a specific market */
export type StockCarSearchConfigurationSearchGroupArgs = {
  searchGroup: Scalars['String']['input'];
};

/** Possible and Selected search criteria for a specific market with id and count for matching vehicle */
export type StockCarSearchCriterion = {
  __typename?: 'StockCarSearchCriterion';
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['output'];
  /** still available items of search criteria */
  possibleItems: Array<StockCarSearchCriterionItem>;
  /** selected items of search criteria */
  selectedItems: Array<StockCarSearchCriterionItem>;
};

/** All possible search criteria for a specific market */
export type StockCarSearchCriterionConfiguration = {
  __typename?: 'StockCarSearchCriterionConfiguration';
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['output'];
  /** meta-data of possible items inside search criteria */
  items: Array<StockCarSearchCriterionItemConfiguration>;
  /** display text for search critera */
  text: Scalars['String']['output'];
  /** display text for unit of search criteria if set */
  unitText?: Maybe<Scalars['String']['output']>;
};

/** ID and number of matches for a search criterion item */
export type StockCarSearchCriterionItem = {
  __typename?: 'StockCarSearchCriterionItem';
  /** Unique identifier for an item in a search criteria */
  id: Scalars['StockCarSearchCriterionItemId']['output'];
  /** number of found cars matching item */
  number: Scalars['Int']['output'];
};

/** All items belonging to a specific search criteria */
export type StockCarSearchCriterionItemConfiguration = {
  __typename?: 'StockCarSearchCriterionItemConfiguration';
  /** Unique identifier for an item in a search criteria */
  id: Scalars['StockCarSearchCriterionItemId']['output'];
  /** display text of item */
  text: Scalars['String']['output'];
};

/** new input */
export type StockCarSearchParameterCriterionInput = {
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['input'];
  /** Unique identifier for an item in a search criteria */
  items: Array<Scalars['StockCarSearchCriterionItemId']['input']>;
};

/** parameter for geo search of stock cars */
export type StockCarSearchParameterGeoCriterionInput = {
  /** latitude of geo position (center of search circle) */
  latitude: Scalars['Float']['input'];
  /** longitude of geo position (center of search circle) */
  longitude: Scalars['Float']['input'];
  /** maximum distance between passed geo position (latitude and longitude) and geo position of a found car (radius of search circle) */
  maxDistance: Scalars['Float']['input'];
};

/** All input parameters that can be handed over for the car search */
export type StockCarSearchParameterInput = {
  /** selected search criteria */
  criteria: Array<StockCarSearchParameterCriterionInput>;
  /** parameter for geographic proximity search */
  geo?: InputMaybe<StockCarSearchParameterGeoCriterionInput>;
  /** parameter for paging */
  paging?: InputMaybe<StockCarSearchParameterPagingInput>;
  /** parameter for sorting */
  sort?: InputMaybe<StockCarSearchParameterSortInput>;
  /** selected special search criteria to filter the search by */
  specialCriteria?: InputMaybe<Array<StockCarSearchParameterSpecialCriterionInput>>;
};

/** Input parameter for the paging of the stockcar search results */
export type StockCarSearchParameterPagingInput = {
  /** limitation of results */
  limit: Scalars['Int']['input'];
  /** offset set (first element) */
  offset: Scalars['Int']['input'];
};

/** Input parameter to sort stockcar search */
export type StockCarSearchParameterSortInput = {
  /** selected sort direction */
  direction: StockCarSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['input'];
};

/** information of a single special search criterion for stock cars */
export type StockCarSearchParameterSpecialCriterionInput = {
  /**
   * ID of the special search criteria.
   *
   * Must match an ID returned from stockCarSearchConfiguration.specialCriteria
   */
  id: Scalars['StockCarSearchSpecialCriterionId']['input'];
  /**
   * Values of the search criteria.
   *
   * Any string is acceptable, unlike normal criteria
   */
  items: Array<Scalars['String']['input']>;
};

/** The results for a search with specific search parameters and market */
export type StockCarSearchResults = {
  __typename?: 'StockCarSearchResults';
  /** found cars matching configured search */
  cars: Array<StockCarSearchResultsCar>;
  /** applied paging parameter */
  paging: StockCarSearchResultsPaging;
  /** applied sort parameter */
  sort: StockCarSearchResultsSort;
};

/** Found cars as results of a car search with specific parameters and market */
export type StockCarSearchResultsCar = {
  __typename?: 'StockCarSearchResultsCar';
  /** used distance to location of car (if proximity search applied) */
  geoDistance?: Maybe<StockCarFloatValueWithUnitItem>;
  /** data of found car */
  stockCar: StockCar;
};

/** The paging options for the results of a car search that was handed over with the parameters and market */
export type StockCarSearchResultsPaging = {
  __typename?: 'StockCarSearchResultsPaging';
  /** limit set */
  limit: Scalars['Int']['output'];
  /** offset set (first element) */
  offset: Scalars['Int']['output'];
};

/** The sorting options for the results of a car search that was handed over with the parameters and market */
export type StockCarSearchResultsSort = {
  __typename?: 'StockCarSearchResultsSort';
  /** selected sort direction */
  direction: StockCarSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['output'];
};

/** List of all possible search criterias */
export type StockCarSearchSearch = {
  __typename?: 'StockCarSearchSearch';
  /** list of search criteria */
  criteria: Array<StockCarSearchCriterion>;
};

/** new enum */
export enum StockCarSearchSortDirection {
  /** ascending sort direction */
  Asc = 'ASC',
  /** descending sort direction */
  Desc = 'DESC',
}

/** All sortable Items for a market and the ascText and descText */
export type StockCarSearchSortableItemsConfiguration = {
  __typename?: 'StockCarSearchSortableItemsConfiguration';
  /** display text on ascending sort */
  ascText: Scalars['String']['output'];
  /** display text on descending sort */
  descText: Scalars['String']['output'];
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['output'];
};

/** information of a single special search criterion for stock cars */
export type StockCarSearchSpecialCriterionConfiguration = {
  __typename?: 'StockCarSearchSpecialCriterionConfiguration';
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchSpecialCriterionId']['output'];
};

/** Basic type to display corresponding id/value strings in StockCar */
export type StockCarStringItemWithId = {
  __typename?: 'StockCarStringItemWithId';
  /** identifier of data */
  id: Scalars['String']['output'];
  /** corresponding text */
  value: Scalars['String']['output'];
};

export type StockCarSubServiceStatusDependency = {
  __typename?: 'StockCarSubServiceStatusDependency';
  health: OneGraphServiceHealth;
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Used as a container to provide text based technical information identified by an ID. */
export type StockCarTechData = {
  __typename?: 'StockCarTechData';
  /** identifier of data, like 'acceleration' */
  id: Scalars['String']['output'];
  /** text to display as description, like 'Acceleration (0 - 60 mph)' */
  label: Scalars['String']['output'];
  /** text based information about technical data, like text:'6.6 sec seconds' */
  text: Scalars['String']['output'];
};

/** Used as a container to provide technical information as StockCarTechData grouped by IDs. */
export type StockCarTechDataGroup = {
  __typename?: 'StockCarTechDataGroup';
  /** identifier of data set, like 'engine' */
  id: Scalars['String']['output'];
  /** text to display as description of id, like 'Engineering | Performance' */
  label: Scalars['String']['output'];
  /** grouped StockCarTechData */
  techDataList: Array<StockCarTechData>;
};

/** (display) text value with unit */
export type StockCarTextValueWithUnitItem = {
  __typename?: 'StockCarTextValueWithUnitItem';
  /** value (as display text) */
  text: Scalars['String']['output'];
  /** unit (as display text) */
  unitText: Scalars['String']['output'];
};

/** Information about color for the top of a single car */
export type StockCarTopColor = {
  __typename?: 'StockCarTopColor';
  /** description of specific color, like code:"A2", text:"Brilliant Black" */
  colorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Contrasting (e.g. Roof)' */
  label?: Maybe<Scalars['String']['output']>;
};

/** Information about a single tyre label */
export type StockCarTyreLabel = {
  __typename?: 'StockCarTyreLabel';
  /** The eprel url */
  eprelUrl: Scalars['String']['output'];
  /** The id of the label */
  id: Scalars['String']['output'];
  /** The product sheet url */
  productSheetUrl: Scalars['String']['output'];
  /** Size designation information of a tyre label */
  sizeDesignation: StockCarTyreLabelSizeDesignation;
  /** The trade name */
  tradeName: Scalars['String']['output'];
  /** The tyre label url */
  tyreLabelUrl: Scalars['String']['output'];
  /** The tyre type identifier */
  tyreTypeIdentifier: Scalars['String']['output'];
};

/** Container for tyre label data */
export type StockCarTyreLabelContainer = {
  __typename?: 'StockCarTyreLabelContainer';
  /** tyre label Information */
  labels: Array<StockCarTyreLabel>;
  /** The pr number the tyre label applies to */
  prNumber: StockCarPrNumberWithoutClass;
};

/** Size designation information of a tyre label */
export type StockCarTyreLabelSizeDesignation = {
  __typename?: 'StockCarTyreLabelSizeDesignation';
  /** The load capacity index */
  loadCapacityIndex: Scalars['String']['output'];
  /** The speed category symbol */
  speedCategorySymbol: Scalars['String']['output'];
  /** The tyre size designation */
  tyreSizeDesignation: Scalars['String']['output'];
};

export type StockCarWarranty = {
  __typename?: 'StockCarWarranty';
  /** date string with pattern 'YYYY-MM-DD', like '2025-08-06' */
  dateEnd?: Maybe<Scalars['String']['output']>;
  /** date string with pattern 'YYYY-MM-DD', like '2022-08-07' */
  dateStart?: Maybe<Scalars['String']['output']>;
  /** additional information to be displayed, like 'Garantie vorhanden. Für weitere Informationen wenden Sie sich bitte an den Anbieter des Fahrzeugs' */
  dealerCondition?: Maybe<Scalars['String']['output']>;
  /** unit code for distanceValue, like 'Kilometer' */
  distanceUnitCode?: Maybe<Scalars['String']['output']>;
  /** unit display text for distanceValue, like 'km' */
  distanceUnitText?: Maybe<Scalars['String']['output']>;
  /** number, like '40000' */
  distanceValue?: Maybe<Scalars['Int']['output']>;
  /** hyperlink to document resource, like 'https://www.audi.de/dam/nemo/customer-area/warranty-guarantees/pdf/audi_garantie.pdf' */
  documentURL?: Maybe<Scalars['String']['output']>;
  /** unit code for durationValue, like 'Month' */
  durationUnitCode?: Maybe<Scalars['String']['output']>;
  /** unit display text for durationValue, like 'Monate' */
  durationUnitText?: Maybe<Scalars['String']['output']>;
  /** number, like '24' */
  durationValue?: Maybe<Scalars['Int']['output']>;
  /** corresponding display text, like 'Anschlussgarantie' */
  label: Scalars['String']['output'];
  /** additional legal information, like 'volkswagenAG' */
  legalEntity?: Maybe<Scalars['String']['output']>;
  /** shorthand identifier of quality label, like 'asg' */
  name: Scalars['String']['output'];
  /** free text, set by dealer, like 'Marderschutz' */
  productName?: Maybe<Scalars['String']['output']>;
  /** display order priority, like '15' */
  sortOrder: Scalars['Int']['output'];
};

export enum StockCarsType {
  /** Cars in stock that are new */
  New = 'NEW',
  /** Cars in stock that are used */
  Used = 'USED',
}

/** combinations for select "StockCarsType" */
export enum StockCarsTypeCombinationInput {
  /** only new cars stock is used / affected */
  New = 'NEW',
  /** new and used cars stock are used / affected */
  NewAndUsed = 'NEW_AND_USED',
  /** new or used cars stock are used / affected */
  NewOrUsed = 'NEW_OR_USED',
  /** only used cars stock is used / affected */
  Used = 'USED',
}

export type StockIdentifierInput = {
  /** specify the market of the stocks */
  marketIdentifier: MarketIdentifierInput;
  /** The stock type where the structure should be taken from */
  stockCarsType: StockCarsType;
};

/** Used as a container to provide coded data with a text and vise versa */
export type StockcarCodedTextItem = {
  __typename?: 'StockcarCodedTextItem';
  /** Code representation of a value, used and provided by different systems. */
  code: Scalars['String']['output'];
  /** Text value */
  text: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar.techData`. Data provided by techdata-graphql subgraph. */
export type TechData = {
  __typename?: 'TechData';
  acceleration?: Maybe<DataEntry>;
  displacement?: Maybe<DataEntry>;
  eec?: Maybe<Scalars['String']['output']>;
  electricRange?: Maybe<DataEntry>;
  fuelType?: Maybe<DataEntry>;
  gearbox?: Maybe<DataEntry>;
  hybridElectricalPower?: Maybe<DataEntry>;
  maxOutput?: Maybe<DataEntry>;
  maxTorque?: Maybe<DataEntry>;
  topSpeed?: Maybe<DataEntry>;
};

/** Information about the battery for electro vehicles (also for hybrid with electro) */
export type TechnicalDataBattery = {
  __typename?: 'TechnicalDataBattery';
  /** TODO name of battery capacity and its value need to be clearified */
  grossCapacity?: Maybe<TechnicalDataFloatItem>;
  /** TODO name of battery capacity and its value need to be clearified */
  netCapacity?: Maybe<TechnicalDataFloatItem>;
  /** TODO */
  type?: Maybe<TechnicalDataTextItem>;
};

/** Parameters for retrieving technical data for a specific model. */
export type TechnicalDataByModelInput = {
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  /** Model identifier */
  model: ModelIdentifierInput;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['input'];
};

/**
 * Object representation of a prString
 *
 * MBV prString:
 *
 * 51901\\2021|GENBAE\\1|F14 S1S1|F56     FZ
 * -----       ------        ----
 * |      ---- |       -     |            --
 * |      |    |       |     |            |
 * |      |    |       |     |            interiorColor
 * |      |    |       |     |
 * |      |    |       |     exteriorColor
 * |      |    |       |
 * |      |    |       modelVersion
 * |      |    |
 * |      |    modelCode
 * |      |
 * |      modelYear
 * |
 * salesgroup
 *
 * NEMO prString:
 *
 * GENBAE1_2021|S1S1|FZ
 * ------  ----      --
 * |     - |    ---- |
 * |     | |    |    |
 * |     | |    |    interiorColor
 * |     | |    |
 * |     | |    exteriorColor
 * |     | |
 * |     | modelYear
 * |     |
 * |     modelVersion
 * |
 * modelCode
 */
export type TechnicalDataConfiguredCarInput = {
  /**
   * KVPS ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /**
   * TODO: Combine equipmentOptions, exteriorColor, interiorColor in a separate input???
   * 7-digit pr numbers
   */
  equipmentOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: InputMaybe<Scalars['String']['input']>;
  /** 2-digit pr number of the interior color */
  interiorColor?: InputMaybe<Scalars['String']['input']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
  /** the model input separte in an own input */
  model: TechnicalDataConfiguredCarModelInput;
  /**
   * 5-digit sales group
   * TODO: for now, we use string because of leading zeros. Check!
   */
  salesGroup?: InputMaybe<Scalars['String']['input']>;
};

export type TechnicalDataConfiguredCarModelInput = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars['String']['input'];
  /**
   * 3-digit pr numbers (deprecated)
   * @deprecated use extensionsPR7
   */
  extensions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 7-digit pr numbers */
  extensionsPR7?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 1-digit model version */
  version: Scalars['Int']['input'];
  /** The year of the catalogue release */
  year: Scalars['Int']['input'];
};

/** Node for all data regarding consumption and emission values */
export type TechnicalDataConsumptionAndEmission = {
  __typename?: 'TechnicalDataConsumptionAndEmission';
  /**
   * Array of disctinct consumption and emission values for fuelType
   * @deprecated use measurements instead
   */
  consumptionAndEmissionPerFuel?: Maybe<Array<Maybe<TechnicalDataConsumptionAndEmissionPerFuel>>>;
  /** Array containing consumption and emission values for each fuel measured by WLTP/NEDC */
  measurements: Array<Maybe<TechnicalDataConsumptionAndEmissionPerFuel>>;
  /** e.g. 40.5 mg/km */
  noxCombined?: Maybe<TechnicalDataFloatItem>;
  /** Summary values for footnotes etc. */
  summary?: Maybe<TechnicalDataConsumptionAndEmissionSummary>;
};

/** Per fuel consumption and emission values; separated by testing method */
export type TechnicalDataConsumptionAndEmissionPerFuel = {
  __typename?: 'TechnicalDataConsumptionAndEmissionPerFuel';
  /** Localized name of the fuel type, i.e., 'Diesel' or 'Benzin' */
  fuelName?: Maybe<Scalars['String']['output']>;
  /** Type of fuel, i.e., 'DIESEL' or 'PETROL' */
  fuelType?: Maybe<Scalars['String']['output']>;
  /** Per fuel consumption and emission values for NEDC testing method */
  nedc?: Maybe<TechnicalDataConsumptionAndEmissionValuesNedc>;
  /**
   * Array of consumption and emission values per fuel for WLTP testing method; entries for different
   * energy management testing cycles
   */
  wltp?: Maybe<TechnicalDataConsumptionAndEmissionValuesWltp>;
};

/** Summary values for footnotes etc. */
export type TechnicalDataConsumptionAndEmissionSummary = {
  __typename?: 'TechnicalDataConsumptionAndEmissionSummary';
  /**
   * Summary consumption text for footnotes etc.
   * @deprecated use consumptionSummary instead
   */
  consumption?: Maybe<Scalars['String']['output']>;
  /**
   * Array containing consumption values for each fuel
   * @deprecated use consumptionsSummary instead
   */
  consumptionSummary?: Maybe<Array<Maybe<FuelFloatValue>>>;
  /** Array containing consumption values for each fuel and energy management */
  consumptionsSummary?: Maybe<Array<FuelValues>>;
  /** Array containing energy efficicency classes for each energy management */
  eecsSummary?: Maybe<Array<EecValuePerEnergyManagementWltp>>;
  /**
   * Summary emission text for footnotes etc.
   * @deprecated use emissionCO2Summary instead
   */
  emissionCO2?: Maybe<Scalars['String']['output']>;
  /**
   * Object containing information about the emission values
   * @deprecated use emissionsCO2Summary instead
   */
  emissionCO2Summary?: Maybe<FuelFloatValue>;
  /** Object containing information about the emission values for each fuel and energy management */
  emissionsCO2Summary?: Maybe<Array<FuelValues>>;
  /** Array of footnote references */
  footnoteReference?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Node containing consumption and emission values for NEDC testing method */
export type TechnicalDataConsumptionAndEmissionValuesNedc = {
  __typename?: 'TechnicalDataConsumptionAndEmissionValuesNedc';
  /** Set of values for fuel consumption */
  consumption?: Maybe<ValuesNedc>;
  /** Set of values for CO2 emissions */
  emissionCO2?: Maybe<ValuesNedc>;
};

export type TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp = {
  __typename?: 'TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp';
  /** Set of values for fuel consumption */
  consumption?: Maybe<ValuesWltp>;
  /** Set of values for CO2 emissions */
  emissionCO2?: Maybe<ValuesWltp>;
  /** applied measurement cycle */
  energyManagement?: Maybe<WltpEnergyManagement>;
  /** may or may not be present depending on a factual decision; true if preferred to be shown */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
};

export type TechnicalDataConsumptionAndEmissionValuesWltp = {
  __typename?: 'TechnicalDataConsumptionAndEmissionValuesWltp';
  consolidated?: Maybe<TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp>;
  perEnergyManagement?: Maybe<
    Array<Maybe<TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp>>
  >;
};

/** Electro engine */
export type TechnicalDataElectroEngine = TechnicalDataEngineBase & {
  __typename?: 'TechnicalDataElectroEngine';
  /** Information about the battery */
  battery?: Maybe<TechnicalDataBattery>;
  /**
   * Charging durance 0 - 100 % with maximal charging power (AC).
   * f.ex. 11 kW
   */
  chargingDuranceAc?: Maybe<TechnicalDataFloatItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * f.ex. 284,0 - 341,0 km
   * Deprecated: --> engine.electricRangeCombined
   */
  electricRangeCombined?: Maybe<TechnicalDataFloatItem>;
  /** electric range value for electro engine */
  electricRanges?: Maybe<RangeInfo>;
  /**
   * The power of the vehicle's engine.
   * https://auto.schema.org/enginePower
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * Maximal chargeable energy content.
   * f.ex. 19,7 kWh / XXX km
   */
  maxRangeChargingDc10?: Maybe<TechnicalDataFloatItem>;
  /** The maxinal hourly output of an electro engine */
  maximalHourlyPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * Maximal loading power AC.
   * f.ex. 11 kW
   */
  maximalLoadingPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * Minimal charging durance 10 - 80 % with maximal charging power (HPC).
   * f.ex. 36 min
   */
  minimalChargingDurance?: Maybe<TechnicalDataFloatItem>;
  /** The power of the vehicle's engine */
  power?: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   */
  torque?: Maybe<TechnicalDataFloatItem>;
  /** Max. Torque front back */
  torqueFrontBack?: Maybe<TechnicalDataFloatItem>;
};

/**
 * The engine(s) for this vehicle.
 * (https://auto.schema.org/EngineSpecification)
 */
export type TechnicalDataEngineBase = {
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /** The power of the vehicle's engine */
  power?: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   */
  torque?: Maybe<TechnicalDataFloatItem>;
  /** Max. Torque front back */
  torqueFrontBack?: Maybe<TechnicalDataFloatItem>;
};

/** Node for all data regarding energy consumption labeling regulation */
export type TechnicalDataEnvkv = {
  __typename?: 'TechnicalDataEnvkv';
  /** Assumed yearly distance */
  assumedYearlyDistance?: Maybe<TechnicalDataFloatItem>;
  /** CO2 Costs */
  co2costs?: Maybe<TechnicalDataFloatLevels>;
  /** Consumption Levels */
  consumptionLevels?: Maybe<TechnicalDataFloatLevels>;
  /**
   * Energy Efficiency Certificate.
   * @deprecated use eecPerEnergyManagementWltp instead
   */
  eec?: Maybe<TechnicalDataTextItem>;
  /** Energy Efficiency Class per wltp phase method */
  eecPerEnergyManagementWltp?: Maybe<Array<EecPerEnergyManagementWltp>>;
  /** Electricity cost per year */
  electricityCostPerYear?: Maybe<TechnicalDataFloatItem>;
  /** Electricity price */
  electricityPrice?: Maybe<TechnicalDataFloatItem>;
  /** Energy cost per year (combines fuelCostPerYear and electricityCostPerYear) */
  energyCostPerYear?: Maybe<TechnicalDataFloatItem>;
  /** Fuel cost per year */
  fuelCostPerYear?: Maybe<TechnicalDataFloatItem>;
  /** Fuel price */
  fuelPrice?: Maybe<TechnicalDataFloatItem>;
  /** Taxes per year */
  taxPerYear?: Maybe<TechnicalDataFloatItem>;
  /** Year used to determine average price levels. */
  year?: Maybe<Scalars['Int']['output']>;
};

/** Technical attribute that is not part of the schema due to being market specific. */
export type TechnicalDataExtendedField = {
  __typename?: 'TechnicalDataExtendedField';
  /** Unique identifier for this field. For example: gasoline-equivalent */
  id: Scalars['String']['output'];
  /** The value object for this field (union type). */
  item: TechnicalDataItem;
};

/**
 * Describes a technical data attribute with a single float `value`.
 * Remark: Due to missing data in the downstream services we'll define the field `value` as optional.
 * The data will currently be delivered as a preformatted locale string. Therefore we will use the field `valueAsText`
 */
export type TechnicalDataFloatItem = {
  __typename?: 'TechnicalDataFloatItem';
  /**
   * The formattedValue of the technical data to display.
   * In case of simple values it contains the value directly f.ex. "Verbundlenker-Hinterachse"
   * In case unit with values it contains the formatted value and the unit f.ex "193 km/h"
   */
  formattedValue: Scalars['String']['output'];
  /**
   * The label of the value
   * f.ex. "Höchstgeschwindigkeit"
   */
  label?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source?: Maybe<Scalars['String']['output']>;
  /**
   * Unit of the value
   * f.ex "km/h"
   */
  unit?: Maybe<Scalars['String']['output']>;
  /**
   * Value as float
   * f.ex. 22134.12
   */
  value?: Maybe<Scalars['Float']['output']>;
  /**
   * Value as text (Sometimes the value is only available as text - in respect to the language)
   * f.ex "22134,12"
   */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

/** Node for all data regarding co2costs */
export type TechnicalDataFloatLevels = {
  __typename?: 'TechnicalDataFloatLevels';
  /** high value */
  high?: Maybe<TechnicalDataFloatItem>;
  /** low value */
  low?: Maybe<TechnicalDataFloatItem>;
  /** medium value */
  medium?: Maybe<TechnicalDataFloatItem>;
};

/** type for engines with fuel: petrol or diesel */
export type TechnicalDataFuelEngine = TechnicalDataEngineBase & {
  __typename?: 'TechnicalDataFuelEngine';
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem?: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement?: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement?: Maybe<TechnicalDataTextItem>;
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * (https://auto.schema.org/engineType)
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType?: Maybe<TechnicalDataTextItem>;
  /** The power of the vehicle's engine */
  power?: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * (https://auto.schema.org/torque)
   */
  torque?: Maybe<TechnicalDataFloatItem>;
  /** Max. Torque front back */
  torqueFrontBack?: Maybe<TechnicalDataFloatItem>;
};

/** type for engines with gas */
export type TechnicalDataGasEngine = TechnicalDataEngineBase & {
  __typename?: 'TechnicalDataGasEngine';
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem?: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement?: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement?: Maybe<TechnicalDataTextItem>;
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * (https://auto.schema.org/engineType)
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * TODO: Would we handle the speed in rangesHow to handle mutliple fuelTypes
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType?: Maybe<TechnicalDataTextItem>;
  /** The power of the vehicle's engine */
  power?: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * (https://auto.schema.org/torque)
   */
  torque?: Maybe<TechnicalDataFloatItem>;
  /** Max. Torque front back */
  torqueFrontBack?: Maybe<TechnicalDataFloatItem>;
};

/** Electro engine */
export type TechnicalDataHybridEngine = TechnicalDataEngineBase & {
  __typename?: 'TechnicalDataHybridEngine';
  /** Information about the battery */
  battery?: Maybe<TechnicalDataBattery>;
  /**
   * Charging durance 0 - 100 % with maximal charging power (AC).
   * f.ex. 11 kW
   */
  chargingDuranceAc?: Maybe<TechnicalDataFloatItem>;
  /** City electric range */
  electricRangeCity?: Maybe<TechnicalDataFloatItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * f.ex. 284,0 - 341,0 km
   */
  electricRangeCombined?: Maybe<TechnicalDataFloatItem>;
  /** Node containing electric ranges values per energy management */
  electricRangesPerEnergyManagement?: Maybe<WltpHybridElectricRangesTestMethodsPerEnergyManagement>;
  /**
   * Electrical Power
   * f.ex 94 kW
   */
  electricalPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The electrical torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  electricalTorque?: Maybe<TechnicalDataFloatItem>;
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem?: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement?: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement?: Maybe<TechnicalDataTextItem>;
  /**
   * The fuel power of the vehicle's hybrid engine.
   * https://auto.schema.org/enginePower
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType?: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity?: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType?: Maybe<TechnicalDataTextItem>;
  /**
   * Maximal chargeable energy content.
   * f.ex. 19,7 kWh / XXX km
   */
  maxRangeChargingDc10?: Maybe<TechnicalDataFloatItem>;
  /** The maxinal hourly output of an electro engine */
  maximalHourlyPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * Maximal loading power AC.
   * f.ex. 11 kW
   */
  maximalLoadingPower?: Maybe<TechnicalDataFloatItem>;
  /**
   * Minimal charging durance 10 - 80 % with maximal charging power (HPC).
   * f.ex. 36 min
   */
  minimalChargingDurance?: Maybe<TechnicalDataFloatItem>;
  /**
   * Momentary overall power
   * f.ex 280 kW
   */
  momentaryOverallPower?: Maybe<TechnicalDataFloatItem>;
  /** The power of the vehicle's engine */
  power?: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  torque?: Maybe<TechnicalDataFloatItem>;
  /** Max. Torque front back */
  torqueFrontBack?: Maybe<TechnicalDataFloatItem>;
  /**
   * The total system torque (turning force) of the vehicle's engine (hybrid: fuel and electro combined)
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  totalSystemTorque?: Maybe<TechnicalDataFloatItem>;
};

/** All technical data belongs to insurance */
export type TechnicalDataInsuranceClass = {
  __typename?: 'TechnicalDataInsuranceClass';
  /** Insurance class for fully comprehensive coverage. */
  fullyComprehensive?: Maybe<TechnicalDataFloatItem>;
  /** Insurance class for basic liability. */
  liability?: Maybe<TechnicalDataFloatItem>;
  /** Insurance class for partially comprehensive coverage. */
  partiallyComprehensive?: Maybe<TechnicalDataFloatItem>;
};

/** Represents all possible types of a generic technical attribute. */
export type TechnicalDataItem =
  | TechnicalDataFloatItem
  | TechnicalDataRangeItem
  | TechnicalDataTextItem;

/**
 * Describes a technical data attribute with `min` and `max` value.
 * Remark: Due to missing data in the downstream services we'll define the fields `min/max` as optional.
 * The data will currently be delivered as a preformatted locale string. Therefore we"ll use the field `minAsText/maxAsText`
 */
export type TechnicalDataRangeItem = {
  __typename?: 'TechnicalDataRangeItem';
  /**
   * The formattedValue of the technical data to display.
   * In case of simple values it contains the value directly f.ex. "Verbundlenker-Hinterachse"
   * In case unit with values it contains the formatted value and the unit f.ex "335/1090 l"
   */
  formattedValue: Scalars['String']['output'];
  /**
   * The label of the value
   * f.ex. "Gepäckraumvolumen"
   */
  label?: Maybe<Scalars['String']['output']>;
  /**
   * Maximum value of the range
   * 120.44
   */
  max?: Maybe<Scalars['Float']['output']>;
  /**
   * Maximum value of the range as text.
   * f.ex. "120,44"
   */
  maxAsText?: Maybe<Scalars['String']['output']>;
  /**
   * Minimum value of the range
   * f.ex. 77.89
   */
  min?: Maybe<Scalars['Float']['output']>;
  /**
   * Minimum value of the range as text.
   * f.ex. "77,89"
   */
  minAsText?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source?: Maybe<Scalars['String']['output']>;
  /**
   * Unit of the value
   * f.ex. "l"
   */
  unit?: Maybe<Scalars['String']['output']>;
};

/** Describes a technical data attribute with a single `text`. */
export type TechnicalDataTextItem = {
  __typename?: 'TechnicalDataTextItem';
  /**
   * The formattedValue of the technical data to display.
   * It contains the value directly f.ex. "Hydraulisch betätigte Trockenkupplung, asbest-/bleifreie Beläge"
   */
  formattedValue: Scalars['String']['output'];
  /**
   * The label of the value
   * f.ex. "Kupplung"
   */
  label?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source?: Maybe<Scalars['String']['output']>;
};

/** TODO */
export type TedasCarCode = {
  country?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
};

export type TedasServiceInfo = OneGraphSubServiceInfo & {
  __typename?: 'TedasServiceInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services?: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

/** All Data regarding the trimline */
export type Trimline = {
  __typename?: 'Trimline';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images: Array<ImageSource>;
  models: Array<Model>;
  name: Scalars['String']['output'];
  prices: CarlineTrimlineFinanceablePrices;
  remarks?: Maybe<Scalars['String']['output']>;
  teaserText?: Maybe<Scalars['String']['output']>;
};

/** All Data regarding the trimline */
export type TrimlinePricesArgs = {
  financingInput?: InputMaybe<FinancingInput>;
};

/** Filter object if you want only some specific image types. For possible values please have a look at the TypedConfiguredCarCatalogImages type */
export type TypedConfiguredCarCatalogImageFilterInput = {
  /** Filter image sets by their type. See type TypedConfiguredCarCatalogImage for known image types */
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Set of images for a Equipment option. Each set will always contain at least one image buy may contain multiple images, for example for packages. */
export type TypedConfiguredCarCatalogImages = {
  __typename?: 'TypedConfiguredCarCatalogImages';
  /** List of available images in the same order they are maintained in MediaEdit */
  images: Array<Maybe<ConfiguredCarCatalogImage>>;
  /**
   * Image type, possible values at the moment
   *
   * NWBS_motiv: Size - width: 79, height: 47
   *
   * NWBaM_motiv: Size - width: 159, height: 74
   *
   * ak4_details_l: Size - width: 140, height: 63
   *
   * ak4_details_m: Size - width: 79, height: 35
   *
   * ak4_details_s: Size - width: 59, height: 26
   *
   * ak4_details_xl: Size - width: 159, height: 71
   *
   * ak4_folding_tile: Size - width: 20, height: 24
   *
   * ak4_inner_tile: Size - width: 40, height: 36
   *
   * ak4_outer_tile: Size - width: 39, height: 24
   *
   * ak_l_motiv: Size - width: 259, height: 120
   *
   * ak_m_motiv: Size - width: 119, height: 47
   *
   * ak_s_motiv: Size - width: 59, height: 23
   *
   * big_image: Size - width: 704, height: 396
   *
   * content_s: Size - width: 145, height: 80
   *
   * deco_tile_button: Size - width: 200, height: 200
   *
   * deco_tile_hd: Size - width: 1920, height: 1080
   *
   * deco_tile_hd_square: Size - width: 1080, height: 1080
   *
   * deco_tile_small_hd: Size - width: 720, height: 1280
   *
   * dimension_image_front_hd: Size - width: 1920, height: 814
   *
   * dimension_image_front_large: Size - width: 1300, height: 814
   *
   * dimension_image_side_hd: Size - width: 1920, height: 814
   *
   * dimension_image_side_large: Size - width: 1300, height: 814
   *
   * dimension_image_top_hd: Size - width: 1920, height: 814
   *
   * dimension_image_top_large: Size - width: 1300, height: 814
   *
   * folding_tile_button: Size - width: 200, height: 200
   *
   * folding_tile_hd: Size - width: 1920, height: 1080
   *
   * folding_tile_hd_square: Size - width: 1080, height: 1080
   *
   * folding_tile_small_hd: Size - width: 1280, height: 720
   *
   * front_view_image_34_hd: Size - width: 1920, height: 1080
   *
   * front_view_image_34_large: Size - width: 601, height: 1400
   *
   * front_view_image_34_medium: Size - width: 682, height: 512
   *
   * front_view_image_34_small: Size - width: 590, height: 332
   *
   * front_view_image_34_x_small: Size - width: 256, height: 144
   *
   * front_view_image_hd: Size - width: 1920, height: 1080
   *
   * front_view_image_large: Size - width: 1400, height: 601
   *
   * front_view_image_small: Size - width: 590, height: 332
   *
   * front_view_image_x_small: Size - width: 256, height: 144
   *
   * hd_image: Size - width: 1920, height: 1080
   *
   * hd_thumbnail: Size - width: 249, height: 140
   *
   * headlight_image: Size - width: 1920, height: 1080
   *
   * headlight_image_small: Size - width: 400, height: 225
   *
   * image_sequence_entry_hd: Size - width: 1920, height: 1080
   *
   * image_sequence_entry_small: Size - width: 704, height: 396
   *
   * inlay_tile_button: Size - width: 200, height: 200
   *
   * inlay_tile_hd: Size - width: 1920, height: 1080
   *
   * inlay_tile_hd_square: Size - width: 1080, height: 1080
   *
   * inlay_tile_small_hd: Size - width: 1280, height: 720
   *
   * inner_tile_hd: Size - width: 1920, height: 1080
   *
   * interior_tile_button: Size - width: 200, height: 200
   *
   * interior_tile_hd_square: Size - width: 1080, height: 1080
   *
   * interior_tile_large_square: Size - width: 2048, height: 2048
   *
   * interior_tile_small_hd: Size - width: 1280, height: 720
   *
   * outer_tile_button: Size - width: 200, height: 200
   *
   * outer_tile_hd: Size - width: 1920, height: 1080
   *
   * outer_tile_hd_square: Size - width: 1080, height: 1080
   *
   * outer_tile_small_hd: Size - width: 1280, height: 720
   *
   * seat_image: Size - width: 1920, height: 1080
   *
   * seat_image_small: Size - width: 190, height: 250
   *
   * side_view_image_hd: Size - width: 1920, height: 1080
   *
   * side_view_image_large: Size - width: 1400, height: 601
   *
   * side_view_image_medium: Size - width: 682, height: 512
   *
   * side_view_image_small: Size - width: 590, height: 332
   *
   * side_view_image_x_small: Size - width: 256, height: 144
   *
   * small_hd_image: Size - width: 1280, height: 720
   *
   * steering_wheel_image: Size - width: 1080, height: 1080
   *
   * steering_wheel_image_small: Size - width: 200, height: 200
   *
   * teaser_image: Size - width: 400, height: 225
   *
   * wheel_image: Size - width: 1080, height: 1080
   *
   * wheel_image_small: Size - width: 200, height: 200
   */
  type: Scalars['String']['output'];
};

/** A price of the type and its type, e.g. 'total', 'base', 'rotr', 'model'.... */
export type TypedPrice = {
  price: Price;
  type: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type TyreLabel = {
  __typename?: 'TyreLabel';
  productSheetUrl?: Maybe<Scalars['String']['output']>;
  tyreLabelUrl?: Maybe<Scalars['String']['output']>;
};

export type UrlDetailRequest = {
  brand?: InputMaybe<Scalars['String']['input']>;
  client: Scalars['String']['input'];
  configs?: InputMaybe<Array<InputMaybe<ConfigInput>>>;
  detailModelRequests: Array<DetailModelRequest>;
  indexed?: InputMaybe<Scalars['Boolean']['input']>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UrlDetailResponse = {
  __typename?: 'URLDetailResponse';
  modelDetailResults: Array<Maybe<ModelDetailResult>>;
};

export type UrlDetailResult = {
  __typename?: 'URLDetailResult';
  config: Config;
  error?: Maybe<Scalars['String']['output']>;
  fallbackUrl?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  url?: Maybe<Scalars['String']['output']>;
  urls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UrlRequest = {
  brand: Scalars['String']['input'];
  client: Scalars['String']['input'];
  configs?: InputMaybe<Array<InputMaybe<ConfigInput>>>;
  indexed?: InputMaybe<Scalars['Boolean']['input']>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  modelRequests: Array<ModelRequest>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UrlRequestAve = {
  aveRequests: Array<AveRequest>;
  brand: Scalars['String']['input'];
  client: Scalars['String']['input'];
  configs?: InputMaybe<Array<InputMaybe<ConfigInput>>>;
  indexed?: InputMaybe<Scalars['Boolean']['input']>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UrlResponse = {
  __typename?: 'URLResponse';
  modelResults: Array<Maybe<ModelResult>>;
};

export type UrlResult = {
  __typename?: 'URLResult';
  config: Config;
  error?: Maybe<Scalars['String']['output']>;
  fallbackUrl?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  url?: Maybe<Scalars['String']['output']>;
};

/** consumption or emission values for NEDC testing method */
export type ValuesNedc = {
  __typename?: 'ValuesNedc';
  /** combined value for consumption or emission profile */
  combined?: Maybe<TechnicalDataFloatItem>;
  /** consolidated value may or may not be present depending on a factual decision about value to be shown */
  consolidated?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for extraUrban profile */
  extraUrban?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for urban profile */
  urban?: Maybe<TechnicalDataFloatItem>;
};

/** consumption or emission values for WLTP testing method */
export type ValuesWltp = {
  __typename?: 'ValuesWltp';
  /** consumption or emission value for extraHigh profile */
  city?: Maybe<TechnicalDataFloatItem>;
  /** combined value for consumption or emission */
  combined?: Maybe<TechnicalDataFloatItem>;
  /** consolidated value may or may not be present depending on a factual decision about value to be shown */
  consolidated?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for extraHigh profile */
  extraHigh?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for high profile */
  high?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for low profile */
  low?: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for medium profile */
  medium?: Maybe<TechnicalDataFloatItem>;
};

/** Vehicle body type */
export enum VehicleBodyType {
  /** ALLROAD */
  Allroad = 'ALLROAD',
  /** ALLSTREET */
  Allstreet = 'ALLSTREET',
  /** AVANT */
  Avant = 'AVANT',
  /** CABRIOLET */
  Cabriolet = 'CABRIOLET',
  /** COMPACT */
  Compact = 'COMPACT',
  /** COUPE */
  Coupe = 'COUPE',
  /** LIMOUSINE */
  Limousine = 'LIMOUSINE',
  /** SPORTBACK */
  Sportback = 'SPORTBACK',
  /** SUV */
  Suv = 'SUV',
}

export enum VehicleType {
  Bev = 'BEV',
  Icev = 'ICEV',
  Phev = 'PHEV',
}

/** Defines a video by size, MIME type and source URL. */
export type VideoSource = {
  __typename?: 'VideoSource';
  /** The height of the video in pixels, e.g. 1920. */
  height: Scalars['Int']['output'];
  /** The MIME type of the video, e.g. "video/mp4". */
  mimeType: Scalars['String']['output'];
  /** The source of the video, e.g. "https://www.mydomain.com/myvideo.mp4". */
  url: Scalars['String']['output'];
  /** The width of the video in pixels, e.g. 1080. */
  width: Scalars['Int']['output'];
};

/** Energy Management for WLTP measurement cycle */
export enum WltpEnergyManagement {
  /** PHEV with with depleted battery measurement */
  Depleting = 'DEPLETING',
  /** Pure fuel measurement, applies for PEV or ICE */
  Pure = 'PURE',
  /** MHEV or PHEV with with full battery measurement */
  Sustaining = 'SUSTAINING',
  /** PHEV (unclear ?) measurement */
  Weighted = 'WEIGHTED',
}

/** Energy Management for electric ranges for hybrids */
export enum WltpHybridElectricRangesEnergyManagement {
  /** All */
  AllElectricRange = 'ALL_ELECTRIC_RANGE',
  /** Equivalent */
  EquivAllElRange = 'EQUIV_ALL_EL_RANGE',
}

/** Node containing hybrid electric ranges values for WLTP testing method */
export type WltpHybridElectricRangesTestMethodPerEnergyManagement = {
  __typename?: 'WltpHybridElectricRangesTestMethodPerEnergyManagement';
  /** Set of values for electric ranges */
  electricRange?: Maybe<WltpValues>;
  /** applied measurement cycle */
  energyManagement?: Maybe<WltpHybridElectricRangesEnergyManagement>;
  /** may or may not be present depending on a factual decision; true if preferred to be shown */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
};

/** Node containing hybrid electric ranges values per energy management for WLTP testing method */
export type WltpHybridElectricRangesTestMethodsPerEnergyManagement = {
  __typename?: 'WltpHybridElectricRangesTestMethodsPerEnergyManagement';
  /** consolidated values */
  consolidated?: Maybe<WltpHybridElectricRangesTestMethodPerEnergyManagement>;
  /** entries for different energy management testing cycles */
  perEnergyManagement?: Maybe<Array<WltpHybridElectricRangesTestMethodPerEnergyManagement>>;
};

export type WltpTestMethod = {
  __typename?: 'WltpTestMethod';
  consumption: WltpValues;
  emissionCO2: WltpValues;
  emissionNO2?: Maybe<TechnicalDataFloatItem>;
};

/** Node containing consumption and emission values for WLTP testing method */
export type WltpTestMethodPerEnergyManagement = {
  __typename?: 'WltpTestMethodPerEnergyManagement';
  /** Set of ranges for fuel consumption */
  consumption: WltpValues;
  /** Set of ranges for CO2 emissions */
  emissionCO2: WltpValues;
  /** Single value for NOX emissions */
  emissionNO2?: Maybe<TechnicalDataFloatItem>;
  /** applied measurement cycle */
  energyManagement: WltpEnergyManagement;
  /** may or may not be present depending on a factual decision; true if preferred to be shown */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
};

/** Node containing consumption and emission values per energy management for WLTP testing method */
export type WltpTestMethodsPerEnergyManagement = {
  __typename?: 'WltpTestMethodsPerEnergyManagement';
  /** consolidated values */
  consolidated?: Maybe<WltpTestMethodPerEnergyManagement>;
  /** entries for different energy management testing cycles */
  perEnergyManagement?: Maybe<Array<WltpTestMethodPerEnergyManagement>>;
};

/** values for WLTP testing method */
export type WltpValues = {
  __typename?: 'WltpValues';
  /** consumption or emission range value for extraHigh profile */
  city?: Maybe<RangeInfo>;
  /** combined range value */
  combined?: Maybe<RangeInfo>;
  /** consolidated range value may or may not be present depending on a factual decision about value to be shown */
  consolidated?: Maybe<RangeInfo>;
  /** consumption or emission range value for extraHigh profile */
  extraHigh?: Maybe<RangeInfo>;
  /** consumption or emission range value for high profile */
  high?: Maybe<RangeInfo>;
  /** consumption or emission range value for low profile */
  low?: Maybe<RangeInfo>;
  /** consumption or emission range value for medium profile */
  medium?: Maybe<RangeInfo>;
};

export type ChangeConfiguredCarFeatureQueryVariables = Types.Exact<{
  changeConfiguredCarFeatureInput: Types.ChangeConfiguredCarFeatureInput;
}>;

export type ChangeConfiguredCarFeatureQuery = { __typename?: 'Query' } & {
  changeConfiguredCarFeature: { __typename?: 'ChangeConfiguredCarPayload' } & {
    configuredCar?: Types.Maybe<
      { __typename?: 'ConfiguredCar' } & Pick<Types.ConfiguredCar, 'idString'> & {
          id: { __typename?: 'ConfiguredCarIdentifier' } & Pick<
            Types.ConfiguredCarIdentifier,
            'exteriorColor' | 'interiorColor' | 'salesGroup' | 'equipmentOptions'
          > & {
              model: { __typename?: 'ConfiguredCarModelIdentifier' } & Pick<
                Types.ConfiguredCarModelIdentifier,
                'code' | 'version' | 'year' | 'extensions'
              >;
            };
          media?: Types.Maybe<
            { __typename?: 'ConfiguredCarMediaResources' } & {
              images: Array<
                { __typename?: 'RenderedCarImage' } & Pick<
                  Types.RenderedCarImage,
                  'url' | 'profileId'
                >
              >;
            }
          >;
        }
    >;
  };
};

export const ChangeConfiguredCarFeatureDocument = gql`
  query ChangeConfiguredCarFeature(
    $changeConfiguredCarFeatureInput: ChangeConfiguredCarFeatureInput!
  ) {
    changeConfiguredCarFeature(input: $changeConfiguredCarFeatureInput) {
      configuredCar {
        idString
        id {
          model {
            code
            version
            year
            extensions
          }
          exteriorColor
          interiorColor
          salesGroup
          equipmentOptions
        }
        media {
          images(
            filterInput: {
              filterByProfileId: [
                "EXTERIOR-FRONT-3-4"
                "EXTERIOR-FRONT-3-4-ENV1"
                "EXTERIOR-FRONT-FRONT"
                "ECTERIOR-FRONT-FRONT-ENV1"
                "EXTERIOR-REAR-3-4"
                "EXTERIOR-REAR-3-4-ENV1"
                "EXTERIOR-PARALLEL-SIDE"
                "EXTERIOR-PARALLEL-SIDE-ENV1"
                "EXTERIOR-WHEELS-RIM"
                "EXTERIOR-WHEELS-RIM-ENV1"
                "INTERIOR-DASHBOARD-DASHBOARD"
                "INTERIOR-DASHBOARD-DASHBOARD-ENV1"
              ]
            }
          ) {
            url
            profileId
          }
        }
      }
    }
  }
`;
export type ChangeConfiguredCarFeatureQueryResult = Apollo.QueryResult<
  ChangeConfiguredCarFeatureQuery,
  ChangeConfiguredCarFeatureQueryVariables
>;
