import type { MappedCarIdentifier } from './mapCarline';

export interface ConcatPrStringOptions {
  readonly model: MappedCarIdentifier['model'];
  readonly color: string;
  readonly interiorColor: string;
  readonly rim: string;
}

export const concatPrString = ({
  model: { code, version, year },
  color,
  interiorColor,
  rim,
}: ConcatPrStringOptions) => `${code}${version}_${year}|${color}|${interiorColor}|${rim}`;
