import type { FC } from 'react';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { CarName } from './components/CarName';
import { ConfigureButton } from './components/ConfigureButton';
import { MoreOptionsDisclaimer } from './components/MoreOptionsDisclaimer';
import { PriceText } from './components/PriceText';
import { Slider } from './components/Slider';
import { ContentContext } from './context/ContentContext';
import { UniversalStateContext } from './context/UniversalStateContext';
import { fetchCarline } from './utils/fetchCarline';
import { useFeatureAppEnv } from './context/FeatureAppEnvContext';
import { FAStateContextProvider } from './context/FAStateContext';
import ConsumptionAndEmission from './components/ConsumptionAndEmission';

export const FeatureApp: FC = () => {
  const content = useContext(ContentContext);
  const universalState = useContext(UniversalStateContext);
  const { 'gfa:locale-service': locale } = useFeatureAppEnv().featureServices;

  const carline = fetchCarline(content.carlineId, locale.countryCode, locale.language);

  if (!universalState || !content || !carline) {
    return null;
  }

  return (
    <FAStateContextProvider carline={carline}>
      <Slider />
      <Container>
        <CarName />
        <PriceText />
        <MoreOptionsDisclaimer />
        <ConfigureButton className="configureButton" />
        <ConsumptionAndEmission />
      </Container>
    </FAStateContextProvider>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-inline: var(${(props) => props.theme.responsive.spacing.pageMargin});
  margin-block-start: var(${({ theme }) => theme.responsive.spacing.l});
  margin-block-end: var(${({ theme }) => theme.responsive.spacing.xxl});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    .configureButton {
      position: absolute;
      top: 0;
      right: var(${(props) => props.theme.responsive.spacing.pageMargin});
    }
  }
`;
