import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  createImageCacheHelper,
  preloadImage,
  preloadRemainingImages,
} from '../utils/imageCacheHelper';
import { getImageAssets, mapCarIdentifier, type MappedCar } from '../utils/mapCarline';
import { updateCarline } from '../utils/updateCarline';
import { useFeatureAppEnv } from './FeatureAppEnvContext';

export interface FAStateContextValue {
  readonly imageIndexState: [number, Dispatch<SetStateAction<number>>];
  readonly colorState: [string, Dispatch<SetStateAction<string>>];
  readonly rimState: [string, Dispatch<SetStateAction<string>>];
  readonly carline: MappedCar;
  readonly images: string[];
  readonly loading: boolean;
  readonly idString: string;
}

export const FAStateContext = createContext<FAStateContextValue>(
  null as unknown as FAStateContextValue,
);

export interface FAStateContextProviderProps {
  readonly children?: ReactNode;
  readonly carline: MappedCar;
}

export const FAStateContextProvider: FC<FAStateContextProviderProps> = ({ children, carline }) => {
  const {
    'onegraph-service': oneGraphService,
    's2:logger': logger,
    'gfa:locale-service': locale,
  } = useFeatureAppEnv().featureServices;
  const carlineIdentifier = useRef(carline.id);
  const imageIndexState = useState(0);
  const colorState = useState(carline.id.exteriorColor);
  const rimState = useState(carline.id.equipmentOptions?.[0] || carline.rims?.[0]?.pr7 || '');

  const [color] = colorState;
  const [rim] = rimState;
  const [images, setImages] = useState(carline.imageAssets);
  const [loading, setLoading] = useState(false);
  const { getCacheItem, storeCacheItem } = useMemo(() => createImageCacheHelper(carline.id), []);
  const [imageIndex] = imageIndexState;
  const [idString, setIdString] = useState(carline.idString);

  useEffect(() => {
    storeCacheItem(
      { color, rim },
      {
        images: carline.imageAssets,
        mappedCarIdentifier: carline.id,
        idString,
      },
    );
  }, []);

  useEffect(() => {
    let mounted = true;
    const hasColorChanged = carlineIdentifier.current.exteriorColor !== color;
    const hasRimChanged = carlineIdentifier.current.equipmentOptions?.indexOf(rim) === -1;

    if (hasColorChanged || hasRimChanged) {
      const cacheId = { color, rim };
      const cacheHit = getCacheItem(cacheId);
      if (cacheHit) {
        carlineIdentifier.current = cacheHit.mappedCarIdentifier;
        setImages(cacheHit.images);
        setIdString(cacheHit.idString);
      } else {
        setLoading(true);
        const changedFeature = hasColorChanged ? color : rim;

        updateCarline({
          oneGraphService,
          locale,
          changedFeature,
          id: carlineIdentifier.current,
        })
          .then(({ data }): void => {
            if (mounted) {
              const mappedCarIdentifier = mapCarIdentifier(
                data.changeConfiguredCarFeature.configuredCar?.id,
              );

              const newImageAssets = getImageAssets(
                data.changeConfiguredCarFeature.configuredCar?.media?.images,
              );
              const newImages = newImageAssets.url;
              const newIdString = data.changeConfiguredCarFeature.configuredCar?.idString ?? '';
              setIdString(newIdString);
              if (newImages.length > 0) {
                // Preload the current visible image first (imageIndex)
                // display as soon as first image is loaded
                // load other images in background
                preloadImage(newImages[imageIndex])
                  .then(() => {
                    if (mounted) {
                      setLoading(false);
                      setImages(newImages);
                      carlineIdentifier.current = mappedCarIdentifier;

                      storeCacheItem(
                        { color, rim },
                        {
                          images: newImages,
                          mappedCarIdentifier,
                          idString: newIdString,
                        },
                      );

                      preloadRemainingImages(newImages, imageIndex);
                    }
                  })
                  .catch((err) => {
                    logger.error('Failed to preload the image:', err);
                    setLoading(false);
                  });
              } else {
                setLoading(false);
              }
            }
          })
          .catch((err) => {
            logger.error(err);
            setLoading(false);
          });
      }
    }

    return () => {
      mounted = false;
    };
  }, [rim, color]);

  const contextValue: FAStateContextValue = useMemo(
    () => ({
      imageIndexState,
      colorState,
      rimState,
      carline,
      images,
      loading,
      idString,
    }),
    [imageIndexState, color, rim, images, loading, idString],
  );

  return <FAStateContext.Provider value={contextValue}>{children}</FAStateContext.Provider>;
};
