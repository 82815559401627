import { Text } from '@audi/audi-ui-react';
import type { FC } from 'react';
import React, { useContext } from 'react';
import { FAStateContext } from '../context/FAStateContext';

export const CarName: FC = () => {
  const {
    carline: { name },
  } = useContext(FAStateContext);
  return <Text variant="order2">{name}</Text>;
};
