import { useQuery } from '@oneaudi/onegraph-client';
import type { MappedCar } from './mapCarline';
import { mapConfiguredCar } from './mapCarline';
import type {
  CustomizerMainQuery,
  CustomizerMainQueryVariables,
} from '../../graphql/documents/customizer-main.generated';
import { CustomizerMainDocument } from '../../graphql/documents/customizer-main.generated';

export interface ConsumptionAndEmission {
  readonly consumption?: string;
}

export const fetchCarline = (
  carlineId: string,
  countryCode: string,
  language: string,
): MappedCar | undefined => {
  const { data } = useQuery<CustomizerMainQuery, CustomizerMainQueryVariables>(
    CustomizerMainDocument,
    {
      variables: {
        carlineInput: {
          brand: 'A',
          country: countryCode,
          language,
          carlineId,
        },
        identifier: {
          brand: 'A',
          id: carlineId,
          country: countryCode,
          language,
        },
      },
    },
  );
  if (!data) {
    return undefined;
  }
  return mapConfiguredCar(data);
};
