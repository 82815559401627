import React from 'react';
import { ArrowButton } from './ArrowButton';
import { SelectorContainer } from './SelectorContainer';
import { SelectorItem } from './SelectorItem';
import { InnerSelectorContainer, StyledArrowButtonContainer } from './FeatureSelector.styles';
import type { ConfiguredCarFeature } from '../../utils/mapCarline';
import { useIsMounted } from '../../hooks/isMounted';

interface SelectorProps {
  items: ConfiguredCarFeature[];
  selectedItem: string;
  onItemClick: (id: string) => void;
  open: boolean;
  containerWidth: number;
  showBackButton: boolean;
  showForwardButton: boolean;
  handleScroll: (isScrollForward: boolean) => void;
  toggleSelector: () => void;
  refContainer: React.RefObject<HTMLDivElement>;
  type?: 'color' | 'rim';
}

export const Selector: React.FC<SelectorProps> = ({
  items,
  selectedItem,
  onItemClick,
  open,
  containerWidth,
  showBackButton,
  showForwardButton,
  handleScroll,
  toggleSelector,
  refContainer,
  type = 'color',
}) => {
  const isMounted = useIsMounted();
  return (
    isMounted && (
      <SelectorContainer
        open={open}
        hasArrow={showBackButton || showForwardButton}
        onClick={!open ? toggleSelector : undefined}
        containerWidth={containerWidth}
      >
        {!open && (
          <SelectorItem
            name={items[0].name}
            key={items[0].pr3}
            imageUrl={items[0].imageUrl}
            equipmentType={type}
            showEditIcon
          />
        )}
        {open && showBackButton && (
          <StyledArrowButtonContainer visible={showBackButton} direction="back">
            <ArrowButton onClick={() => handleScroll(false)} direction="back" />
          </StyledArrowButtonContainer>
        )}
        {open && (
          <InnerSelectorContainer ref={refContainer}>
            {items.map(({ pr3, pr7, ...rest }: ConfiguredCarFeature) => (
              <SelectorItem
                key={pr3}
                {...rest}
                onClick={() => onItemClick(type === 'rim' ? pr7 || '' : pr3 || '')}
                active={open && selectedItem === (type === 'rim' ? pr7 : pr3)}
                // equipmentType={type}
              />
            ))}
          </InnerSelectorContainer>
        )}
        {open && showForwardButton && (
          <StyledArrowButtonContainer visible={showForwardButton} direction="forward">
            <ArrowButton onClick={() => handleScroll(true)} direction="forward" />
          </StyledArrowButtonContainer>
        )}
      </SelectorContainer>
    )
  );
};
